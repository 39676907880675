import React, { useState } from 'react';
import { BiChevronRight } from "react-icons/bi";
import { FaAngleDoubleRight } from "react-icons/fa";
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom";

import Header from '../../../components/header';
import EndAdsOrAnnouncModal from '../../../modals/endAdsOrAnnouncModal';

import { openEndAdvertsModal } from '../../../../redux/actions/advertisementsActions';
import './styles.css';

export default function AllAdvertisements() {
    const StandardPublications = useSelector(state => state.TextsReducer.StandardPublications);
    const MeetUps = useSelector(state => state.TextsReducer.MeetUps);
    const Actions = useSelector(state => state.TextsReducer.Actions);
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const item = location.state;

    const [canOpenURL, setcanOpenURL] = useState({});

    let status;

    if (item.creatorExtraData.status === 'PENDING_VALIDATION') {
        status = StandardPublications.PendingValidation;
    } else if (item.creatorExtraData.status === 'VALIDATED_NOT_PAID') {
        status = StandardPublications.ValidatedNotPaid;
    } else if (item.creatorExtraData.status === 'VALIDATED_PAID') {
        status = StandardPublications.ValidatedPaid;
    } else if (item.creatorExtraData.status === 'VALIDATED_NO_PAYMENT_REQUIRED') {
        status = StandardPublications.Validated;
    } else if (item.creatorExtraData.status === 'NOT_VALIDATED') {
        status = StandardPublications.NotValidated;
    } else if (item.creatorExtraData.status === 'TERMINATED') {
        status = StandardPublications.Terminated;
    } else if (item.creatorExtraData.status === 'TERMINATED_BY_USER') {
        status = StandardPublications.TerminatedByUser;
    } else if (item.creatorExtraData.status === 'VALIDATED_FREE_OF_CHARGE') {
        status = StandardPublications.Validated;
    } else if (item.creatorExtraData.status === 'AWAITING_BROADCAST') {
        status = StandardPublications.AwaitingBroadcast;
    } else if (item.creatorExtraData.status === 'BROADCASTING') {
        status = StandardPublications.Broadcasting;
    } else if (item.creatorExtraData.status === 'CONCLUDED') {
        status = StandardPublications.Concluded;
    } else {
        status = '';
    }

    function _renderLocations() {
        if (item.creatorExtraData.coverageType === 'LOCALITY_NAME' || item.creatorExtraData.coverageType === 'POSTAL_CODE') {
            return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {item.creatorExtraData.locations.map((location, index) => {
                        if (item.creatorExtraData.coverageType === 'LOCALITY_NAME') {
                            if (index === item.creatorExtraData.locations.length - 1) {
                                return <p key={location.locality}>{location.locality}</p>
                            } else {
                                return <p key={location.locality}>{location.locality},&nbsp;</p>
                            }
                        } else {
                            if (index === item.creatorExtraData.locations.length - 1) {
                                return <p key={location.locality}>{location.zipCode}</p>
                            } else {
                                return <p key={location.locality}>{location.zipCode},&nbsp;</p>
                            }
                        }
                    })}
                </div>
            );
        }
    }

    function _openURL() {
        let itemID = item.id;
        setcanOpenURL({ ...canOpenURL, itemID: true });
    }

    function advertImage() {
        if (item.type === 'PROMOTIONAL_AD') {
            if (item.link && (item.link.slice(0, 3) === 'www' || item.link.slice(0, 3) === 'htt')) {
                let url = item.link.slice(0, 3) === 'www' ? 'https://' + item.link : item.link;
                return (
                    <a href={url} target="_blank" className="imageContainer">
                        <img src={item.image} className="advertsImage" />
                        <div className="buttonLinkContainer">
                            <p className='link-text'>{Actions.KnowMore}</p>
                            <BiChevronRight size={18} color="#FFF" style={{ marginRight: 5 }} />
                        </div>
                    </a>
                );
            }

            return (
                <button type="button" className="imageContainer" onClick={() => _openURL()}>
                    <img src={item.image} className="advertsImage" />
                    <div className="buttonLinkContainer">
                        {canOpenURL.itemID ? <p className='link-text'>{item.link}</p> : <p className='link-text'>{Actions.KnowMore}</p>}
                        <BiChevronRight size={18} color="#FFF" style={{ marginRight: 5 }} />
                    </div>
                </button>
            );
        }

        return (
            <div className="imageContainer">
                <img src={item.image} className="advertsImage" />
            </div>
        )
    }

    function _renderContent() {
        if (item.type === 'MEET_UP') {
            return (
                <>
                    <div id="myAdvertisementsItem-content">
                        <EndAdsOrAnnouncModal itemID={item.id} screen='Advertisement' />
                        <div className="header-adverts-item">
                            <div className="advertsTitleContainer">
                                <p className='publication-title'>{item.creatorExtraData.title}</p>

                                <div className='publication-status-container'>
                                    <p className='publication-status'>{status.toUpperCase()}</p>
                                </div>
                            </div>

                            <div className="advertsTitleContainer">
                                <p className='publication-name'>{item.productName}</p>
                            </div>
                        </div>

                        <div className="advertsTextContainer">
                            <p>{item.textContent}</p>
                        </div>

                        {advertImage()}

                        <div className="locations">
                            {_renderLocations()}
                            <div style={{ display: 'flex', marginTop: 3 }}>
                                <p>{item.creatorExtraData.locations[0].regionOrState}&nbsp;</p>
                                <p>{item.creatorExtraData.locations[0].country}</p>
                            </div>
                        </div>

                        <div className="footer">
                            <div className='footer-item'>
                                <p>{MeetUps.MeetUpLocation}: {item.meetUpData.meetUpAddress}</p>
                            </div>

                            <div className='footer-item'>
                                <p>{MeetUps.MeetUpStartDate}: {item.meetUpData.startDateFormatted}</p>
                            </div>

                            <div className='footer-item'>
                                <p>{MeetUps.MeetUpEndDate}: {item.meetUpData.endDateFormatted}</p>
                            </div>

                            <div className='footer-item publication-date'>
                                <p>{StandardPublications.StartDate}: {item.creatorExtraData.startDateFormatted}</p>
                            </div>

                            {item.creatorExtraData.endDate !== null && <div className='footer-item'>
                                <p>{StandardPublications.EndDate}: {item.creatorExtraData.endDateFormatted}</p>
                            </div>}

                            {item.creatorExtraData.earlierEndDate !== null && <div className='footer-item'>
                                <p>{StandardPublications.EarlierEndDate}: {item.creatorExtraData.earlierEndDateFormatted}</p>
                            </div>}

                            <div className='footer-item'>
                                <p>{StandardPublications.PotentialNumberOfViews}: {item.creatorExtraData.potentialNumberOfViews}</p>
                            </div>

                            <div className='meet-up-container'>
                                <div className='meet-up-attendees-container'>
                                    <div>
                                        <p>{MeetUps.Attendees}: {item.meetUpData.numberOfConfirmedAttendances}</p>
                                    </div>

                                    <div>
                                        <p>{MeetUps.MaybeAttendees}: {item.meetUpData.numberOfPossibleAttendances}</p>
                                    </div>
                                </div>

                                <button className='button-attendees' onClick={() => history.push({ pathname: '/meetUpParticipants', state: { item: item, screen: 'Advertisement' } })}>
                                    <p style={{ fontWeight: 'bold', marginRight: 5, marginBottom: 2 }}>{MeetUps.Attendees}</p>
                                    <FaAngleDoubleRight size={22} color="#000" />
                                </button>
                            </div>
                        </div>
                    </div>
                    {(item.creatorExtraData.status !== 'TERMINATED' && item.creatorExtraData.status !== 'TERMINATED_BY_USER' && item.creatorExtraData.status !== 'CONCLUDED') && <button className='button-cancel-publication' onClick={() => dispatch(openEndAdvertsModal(item.id))}>
                        {item.type === 'MEET_UP' ? MeetUps.CancelMeetUp : Actions.StopBroadcasting}
                    </button>}
                </>
            );
        }

        return (
            <>
                <div id="myAdvertisementsItem-content">
                    <EndAdsOrAnnouncModal itemID={item.id} screen='Advertisement' />
                    <div className="header-adverts-item">
                        <div className="advertsTitleContainer">
                            <p className='publication-title'>{item.creatorExtraData.title}</p>

                            <div className='publication-status-container'>
                                <p className='publication-status'>{status.toUpperCase()}</p>
                            </div>
                        </div>

                        <div className="advertsTitleContainer">
                            <p className='publication-name'>{item.productName}</p>
                        </div>
                    </div>

                    <div className="advertsTextContainer">
                        <p>{item.textContent}</p>
                    </div>

                    {advertImage()}

                    <div className="locations">
                        {_renderLocations()}
                        <div style={{ display: 'flex', marginTop: 3 }}>
                            <p>{item.creatorExtraData.locations[0].regionOrState}&nbsp;</p>
                            <p>{item.creatorExtraData.locations[0].country}</p>
                        </div>
                    </div>

                    <div className="footer">
                        <div className='footer-item publication-date'>
                            <p>{StandardPublications.StartDate}: {item.creatorExtraData.startDateFormatted}</p>
                        </div>

                        {item.creatorExtraData.endDate !== null && <div className='footer-item'>
                            <p>{StandardPublications.EndDate}: {item.creatorExtraData.endDateFormatted}</p>
                        </div>}

                        {item.creatorExtraData.earlierEndDate !== null && <div className='footer-item'>
                            <p>{StandardPublications.EarlierEndDate}: {item.creatorExtraData.earlierEndDateFormatted}</p>
                        </div>}

                        <div className='footer-item'>
                            <p>{StandardPublications.PotentialNumberOfViews}: {item.creatorExtraData.potentialNumberOfViews}</p>
                        </div>
                    </div>
                </div>
                {(item.creatorExtraData.status !== 'TERMINATED' && item.creatorExtraData.status !== 'TERMINATED_BY_USER' && item.creatorExtraData.status !== 'CONCLUDED') && <button className='button-cancel-publication' onClick={() => dispatch(openEndAdvertsModal(item.id))}>
                    {item.type === 'MEET_UP' ? MeetUps.CancelMeetUp : Actions.StopBroadcasting}
                </button>}
            </>
        );
    }

    return (
        <div id="allAdvertisements-container">
            <Header back='/myPublications' screen={StandardPublications.MyStandardPublications} />
            {_renderContent()}
        </div>
    );
}