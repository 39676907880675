import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

import Header from '../../../../components/header';
import AdvertisementHeader from '../../../../components/adsHeader';

import './styles.css';

export default function AboutAdvertisements() {
    const StandardPublications = useSelector(state => state.TextsReducer.StandardPublications);
    const history = useHistory();

    return (
        <>
        <Header back='/' screen={StandardPublications.About} />
        <AdvertisementHeader />
        <div id="about-advertisements-container">
            <div className="explanation-container">
                <p>{StandardPublications.Message.Explanation1}</p>
                <p>{StandardPublications.Message.Explanation2}</p>
                <p>{StandardPublications.Message.Explanation3}</p>
                <p>{StandardPublications.Message.Explanation4}</p>
                <p>{StandardPublications.Message.Explanation5}</p>
            </div>

            <button type="button" className="price-button-container" onClick={()=> history.push('/advertCosts') }>
                {StandardPublications.AdvertCosts}
            </button>
        </div>
        </>
    );
}