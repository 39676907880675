import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from './redux';
import Router from './routes';
import './index.css';

ReactDOM.render(
    <React.StrictMode>
        <Provider store = { store }>
            <PersistGate loading={null} persistor={ persistor }>
                <Router />
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);