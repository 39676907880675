const INITIAL_STATE = {
    reloadingGetContent: false,
    reloadingGetRequests: false,
    reloadingGetAssists: false,
    allContent: [],
    allRequests: [{ id: 'EXPLANATION', contentType: 'EXPLANATION' }, { id: 'NOREQUESTS', contentType: 'NOREQUESTS' }, { id: 'HEADER', contentType: 'HEADER' }, { id: 'NOADVERTS', contentType: 'NOADVERTS' }],
    allAssists: [{ request: { id: 'HEADER_REQUEST' }, contentType: 'HEADER_REQUEST' }, { request: { id: 'NO_ASSISTS1' }, contentType: 'NO_ASSISTS' }, { request: { id: 'HEADER_OFFER' }, contentType: 'HEADER_OFFER' }, { request: { id: 'NO_ASSISTS2' }, contentType: 'NO_ASSISTS' }],
    title: '',
    requestDesc: '',
    requestImage: '',
    loadingImage: false,
    eventDate: '',
    erroAssist: '',
    successAssist: '',
    calendarType: '',
    openCalendar: false,
    newRequestLoad: false,
    openFilter: false,
    openOffers: {},
    profileModal: false,
    announcerModal: false,
    profileAssistModal: false,
    assistModalItem: {},
    endAssistanceModal: false,
    reportModal: false,
    reportString: '',
    sendingReport: false,
    itemErrorMessage: '',
    itemSuccessMessage: '',
    itemMessageID: null,
    activeAssistance: 0,
    offerAssistLoad: false,
    acceptAssistLoad: false,
    fieldError: false,
    assistActivate: 0,
    assistNotificationStatus: false,
    loadingSurvey: false,
    meetUpTemporaryStatus: { key: 0, status: '' },
    surveyTemporaryStatus: { publicationId: 0, surveyId: 0, optionId: 0 }
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_ALL_ACTIVITIES':
            return { ...state, reloadingGetContent: action.payload }
        case 'GET_ALL_REQUESTS':
            return { ...state, reloadingGetRequests: action.payload.reload }
        case 'GET_ALL_ASSISTS':
            return { ...state, reloadingGetAssists: action.payload.reload }
        case 'SET_ALL_CONTENT':
            return { ...state, allContent: action.payload.allContent, reloadingGetContent: action.payload.reload }
        case 'SET_ALL_REQUESTS':
            return { ...state, allRequests: action.payload.allRequests, reloadingGetRequests: action.payload.reload, offerAssistLoad: false }
        case 'SET_ALL_ASSISTS':
            return { ...state, allAssists: action.payload.assists, reloadingGetAssists: action.payload.reload, acceptAssistLoad: false }
        case 'MODIFY_REQUEST_TITLE':
            return { ...state, title: action.payload }
        case 'MODIFY_REQUEST_DESC':
            return { ...state, requestDesc: action.payload }
        case 'ASYNC_SEND_REQUEST_IMAGE':
            return { ...state, loadingImage: true }
        case 'SET_REQUEST_IMAGE':
            return { ...state, requestImage: action.payload, loadingImage: false }
        case 'SET_ERRO_ASSIST_MESSAGE':
            return { ...state, erroAssist: action.payload, newRequestLoad: false, loadingImage: false }
        case 'SET_OPEN_CALENDAR':
            return { ...state, openCalendar: true, calendarType: action.payload }
        case 'SET_CLOSE_CALENDAR':
            return { ...state, openCalendar: false, calendarType: '' }
        case 'SET_EVENT_DATE':
            return { ...state, openCalendar: false, eventDate: action.payload }
        case 'SET_EVENT_IOS_DATE':
            return { ...state, eventDate: action.payload }
        case 'ASYNC_NEW_REQUEST':
            return { ...state, newRequestLoad: true }
        case 'REQUEST_CREATED':
            return { ...state, newRequestLoad: false, successAssist: action.payload, erroAssist: '', title: '', requestImage: '', requestDesc: '', eventDate: '', calendarType: '' }
        case 'FINISH_ALERT':
            return { ...state, erroLogin: '' }
        case 'SET_CHANGE_FILTER':
            return { ...state, openFilter: action.payload }
        case 'CLEAR_NEW_REQUEST_FORM':
            return { ...state, title: '', requestDesc: '', requestImage: '', eventDate: '', calendarType: '', erroAssist: '', successAssist: '' }
        case 'SET_PROFILE_MODAL':
            return { ...state, profileModal: action.payload }
        case 'SET_ANNOUNCER_MODAL':
            return { ...state, announcerModal: action.payload }
        case 'ITEM_ASSIST_MODAL':
            return { ...state, assistModalItem: action.payload }
        case 'SET_PROFILE_ASSIST_MODAL':
            return { ...state, profileAssistModal: action.payload }
        case 'SET_END_ASSISTANCE_MODAL':
            return { ...state, endAssistanceModal: action.payload }
        case 'SET_REPORT_MODAL':
            return { ...state, reportModal: action.payload }
        case 'SET_REPORT_STRING':
            return { ...state, reportString: action.payload }
        case 'REPORT_ASSIST':
            return { ...state, sendingReport: true }
        case 'REPORT_SENDED':
            return { ...state, sendingReport: false, reportString: '' }
        case 'SET_SUCCESS_REPORT_MESSAGE':
            return { ...state, successAssist: action.payload }
        case 'SET_ERRO_REPORT_MESSAGE':
            return { ...state, erroAssist: action.payload }
        case 'SET_ITEM_ERROR_MESSAGE':
            return { ...state, itemErrorMessage: action.payload.message, itemMessageID: action.payload.requestID }
        case 'SET_ITEM_SUCCESS_MESSAGE':
            return { ...state, itemSuccessMessage: action.payload.message, itemMessageID: action.payload.requestID }
        case 'SET_CHANGE_ANIMATION_OFFERS':
            return { ...state, openOffers: action.payload }
        case 'ACTIVE_ASSISTANCE':
            return { ...state, activeAssistance: action.payload }
        case 'OFFER_ASSISTENCE_SENT':
            return { ...state, offerAssistLoad: action.payload }
        case 'OFFER_ASSISTENCE_SENT_ERROR':
            return { ...state, offerAssistLoad: false }
        case 'ACCEPT_ASSIST_LOADING':
            return { ...state, acceptAssistLoad: action.payload }
        case 'ACCEPT_ASSIST_LOADING_ERROR':
            return { ...state, acceptAssistLoad: false }
        case 'SET_FIELD_ASSIST_ERROR':
            return { ...state, fieldError: action.payload }
        case 'SET_STATE_SCREEN':
            return { ...state, assistActivate: action.payload }
        case 'SET_ASSISTANCE_NOTIFICATION':
            return { ...state, assistNotificationStatus: action.payload }
        case 'ANNOUNCEMENT_ATTEND_MEET_UP':
            return { ...state, meetUpTemporaryStatus: { key: action.payload.id, status: action.payload.status } }
        case 'CLEAN_MEET_UP_TEMPORARY_STATUS':
            return { ...state, meetUpTemporaryStatus: { key: 0, status: '' } }
        case 'ANNOUNCEMET_VOTE_FOR_SURVEY':
            return { ...state, surveyTemporaryStatus: { publicationId: action.payload.publicationId, surveyId: action.payload.surveyId, optionId: action.payload.surveyOptionId } }
        case 'ASYNC_ANNOUNCEMET_VOTE_FOR_SURVEY':
            return { ...state, loadingSurvey: true }
        case 'CLEAN_SURVEY_TEMPORARY_STATUS':
            return { ...state, surveyTemporaryStatus: { publicationId: 0, surveyId: 0, optionId: 0 }, loadingSurvey: false }
        case 'LOG_OUT':
            return {
                ...state,
                username: '',
                password: '',
                allRequests: [{ id: 'NOREQUESTS', contentType: 'NOREQUESTS' }, { id: 'HEADER', contentType: 'HEADER' }, { id: 'NOADVERTS', contentType: 'NOADVERTS' }],
                allAssists: []
            }
        default:
            return state;
    }
}