export const modifyAnnouncementType = type => {
    return {
        type: 'MODIFY_ANNOUNCEMENT_TYPE',
        payload: type
    }
}

export const modifyAnnouncementsTitle = text => {
    return {
        type: 'MODIFY_ANNOUNCEMENT_TITLE',
        payload: text
    }
}

export const modifyAnnouncementsDesc = text => {
    return {
        type: 'MODIFY_ANNOUNCEMENT_CONTENT',
        payload: text
    }
}

export const asyncSendAnnouncementImage = image => {
    return {
        type: 'ASYNC_SEND_ANNOUNCEMENT_IMAGE',
        payload: image
    }
}

export const modifyNumberOfOptions = value => {
    const array = new Array(value).fill('option');
    return {
        type: 'MODIFY_NUMBER_OF_OPTIONS_ANNOUNCEMENTS',
        payload: { value, array }
    }
}

export const modifyOptionText = value => {
    return {
        type: 'MODIFY_ANNOUNCEMENT_OPTION_TEXT',
        payload: value
    }
}

export const modifySurveyTitle = text => {
    return {
        type: 'MODIFY_ANNOUNCEMENT_SURVEY_TITLE',
        payload: text
    }
}

export const openAnnouncementCalendar = (type, order) => {
    return {
        type: 'OPEN_ANNOUNCEMENT_CALENDAR',
        payload: { type, order }
    }
}

export const openAnnouncementMeetUpCalendar = (type, order) => {
    return {
        type: 'OPEN_ANNOUNCEMENT_MEET_UP_CALENDAR',
        payload: { type, order }
    }
}

export const closeAnnouncementCalendar = () => {
    return { type: 'CLOSE_ANNOUNCEMENT_CALENDAR' }
}

export const closeAnnouncementMeetUpCalendar = () => {
    return { type: 'CLOSE_ANNOUNCEMENT_MEET_UP_CALENDAR' }
}

export const setStartDate = date => {
    return {
        type: 'SET_START_DATE',
        payload: date
    }
}

export const setEndDate = date => {
    return {
        type: 'SET_END_DATE',
        payload: date
    }
}

export const setMeetUpStartDate = date => {
    return {
        type: 'SET_MEET_UP_START_DATE',
        payload: date
    }
}

export const setMeetUpEndDate = date => {
    return {
        type: 'SET_MEET_UP_END_DATE',
        payload: date
    }
}

export const announcementsError = text => {
    return {
        type: 'ANNOUNCEMENTS_ERROR_MESSAGE',
        payload: text
    }
}

export const asyncSendAnnouncement = () => {
    return { type: 'ASYNC_SEND_ANNOUNCEMENT' }
}

export const getAnnouncements = reload => {
    return {
        type: 'GET_ANNOUNCEMENTS',
        payload: reload
    }
}

export const getPotentialNumberOfViewsForAnnouncements = () => {
    return { type: 'GET_POTENTIAL_NUMBER_OF_VIEWS_FOR_ANNOUNCEMENTS' }
}

export const setAnnouncementIosStartDate = date => {
    return {
        type: 'SET_ANNOUNCEMENT_IOS_START_DATE',
        payload: date
    }
}

export const setAnnouncementIosEndDate = date => {
    return {
        type: 'SET_ANNOUNCEMENT_IOS_END_DATE',
        payload: date
    }
}

export const setAnnouncementMeetUpIosStartDate = date => {
    return {
        type: 'SET_ANNOUNCEMENT_MEET_UP_IOS_START_DATE',
        payload: date
    }
}

export const setAnnouncementMeetUpIosEndDate = date => {
    return {
        type: 'SET_ANNOUNCEMENT_MEET_UP_IOS_END_DATE',
        payload: date
    }
}

export const clearAnnouncements = () => {
    return { type: 'CLEAR_ANNOUNCEMENT' }
}

export const modifyMeetUpAddress = text => {
    return {
        type: 'MODIFY_ANNOUNCEMENT_MEET_UP_ADDRESS',
        payload: text
    }
}

export const cancelAnnouncement = announcementId => {
    return {
        type: 'ASYNC_CANCEL_ANNOUNCEMENT',
        payload: announcementId
    }
}

export const openEndAnnouncementsModal = itemID => {
    return {
        type: 'OPEN_END_ANNOUNCEMENTS_MODAL',
        payload: itemID
    }
}

export const closeEndAnnouncementsModal = () => {
    return { type: 'CLOSE_END_ANNOUNCEMENTS_MODAL' }
}
