export const SingOut = () => {
    return { type: 'ASYNC_LOG_OUT' }
}

export const siteSingOut = history => {
    history.push('/login')
    return { type: 'LOG_OUT' }
}

export const getTextLanguage = language => {
    return {
        type: 'GET_TEXT_LANGUAGE',
        payload: language
    }
}

export const getCountries = language => {
    return {
        type: 'GET_COUNTRIES',
        payload: language
    }
}

export const startUseAssist = username => {
    return {
        type: 'START_USE_ASSIST',
        payload: username
    }
}

export const sendRegistrationToken = token => {
    return {
        type: 'SEND_REGISTRATION_TOKEN',
        payload: token
    }
}

export const getUserData = () => {
    return { type: 'ASYNC_USER_DATA' }
}

export const asyncUpdateUser = () => {
    return {
        type: 'ASYNC_UPDATE_USER',
        payload: 'message'
    }
}

export const asyncSendUserImage = image => {
    return {
        type: 'ASYNC_SEND_USER_IMAGE',
        payload: image
    }
}

export const asyncSendUserIdCardImage = image => {
    return {
        type: 'ASYNC_SEND_USER_IDCARD_IMAGE',
        payload: image
    }
}

export const setUserBio = text => {
    return {
        type: 'SET_USER_BIO',
        payload: text
    }
}

export const setUserFirstName = text => {
    return {
        type: 'SET_USER_FIRST_NAME',
        payload: text
    }
}

export const setUserLastName = text => {
    return {
        type: 'SET_USER_LAST_NAME',
        payload: text
    }
}

export const setUserBirthDate = text => {
    return {
        type: 'SET_USER_BIRTH_DATE',
        payload: text
    }
}

export const setUserBirthDateIos = date => {
    return {
        type: 'SET_USER_BIRTH_DATE_IOS',
        payload: date
    }
}

export const setUserEmail = text => {
    return {
        type: 'SET_USER_EMAIL',
        payload: text
    }
}

export const setUserPhone = text => {
    return {
        type: 'SET_USER_PHONE',
        payload: text
    }
}

export const setUserGender = text => {
    return {
        type: 'SET_USER_GENDER',
        payload: text
    }
}

export const setUserIdentity = text => {
    return {
        type: 'SET_USER_IDENTITY',
        payload: text
    }
}

export const setUserCountry = text => {
    return {
        type: 'SET_USER_COUNTRY',
        payload: text
    }
}

export const setUserRegion = text => {
    return {
        type: 'SET_USER_REGION',
        payload: text
    }
}

export const setUserCity = text => {
    return {
        type: 'SET_USER_CITY',
        payload: text
    }
}

export const setUserZipcode = text => {
    return {
        type: 'SET_USER_ZIPCODE',
        payload: text
    }
}

export const setUserLocation = info => {
    const data = {
        latitude: String(info.coords.latitude).replace(",", "."),
        longitude: String(info.coords.longitude).replace(",", "."),
    }
    return {
        type: 'SET_USER_LOCATION',
        payload: data
    }
}

export const setRequestFilter = value => {
    return {
        type: 'SET_REQUEST_FILTER',
        payload: value
    }
}

export const setActivitiesFilter = value => {
    return {
        type: 'SET_ACTIVITIES_FILTER',
        payload: value
    }
}

export const openAccountCalendar = () => {
    return { type: 'OPEN_ACCOUNT_CALENDAR' }
}

export const closeAccountCalendar = () => {
    return { type: 'CLOSE_ACCOUNT_CALENDAR' }
}

export const updateUserLocation = info => {
    return {
        type: 'UPDATE_USER_LOCATION',
        payload: info
    }
}

export const retrieveCurrentAreaAndNumberOfUsers = info => {
    return {
        type: 'RETRIEVE_CURRENT_AREA_AND_NUMBER_OF_USERS',
        payload: info
    }
}

export const AsyncGetLocation = info => {
    return {
        type: 'ASYNC_GET_USER_LOCATION',
        payload: info
    }
}

export const setExplanationLoading = status => {
    return {
        type: 'SET_EXPLANATION_LOADING',
        payload: status
    }
}

export const setExplanation = explanation => {
    return {
        type: 'SET_EXPLANATION_USER',
        payload: explanation
    }
}

export const closeUserData = () => {
    return { type: 'CLOSE_USER_DATA' }
}

export const asyncAdvertsAvailable = () => {
    return { type: 'ASYNC_ADVERTS_AVAILABLE' }
}

export const retrieveMaxNumberOfBroadcastingDaysForRequest = () => {
    return { type: 'ASYNC_RETRIEVE_MAX_NUMBER_OF_DAYS_FOR_REQUEST' }
}