const INITIAL_STATE = {
    chatMessage: '',
    messages: []
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case 'SET_CHAT_MESSAGE':
            return { ...state, chatMessage: action.payload }
        case 'CLEAR_CHAT_MESSAGE':
            return { ...state, chatMessage: '' }
        case 'SET_CHAT_MESSAGES':
            return { ...state, messages: action.payload }
        default:
            return state;
    }
}