import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Dropdown } from 'react-dropdown-now';
import { IoIosMenu } from "react-icons/io";
import { BsFillCaretDownFill } from "react-icons/bs";
import 'react-dropdown-now/style.css';

import { siteSingOut } from '../../../redux/actions/userActions';

import assistIcon from '../../../assets/settingsIcons/miniAssistIconWeb.png';
import activitiesIcon from '../../../assets/book-open-outline.png';
import settingsIcon from '../../../assets/settingsIconWeb.png';
import singOutIcon from '../../../assets/settingsIcons/singOutIcon.png';

import accountIcon from '../../../assets/settingsIcons/accountIcon.png';
import AdvertisementsIcon from '../../../assets/settingsIcons/AdvertisementsIcon.png';
import AnnouncementsIcon from '../../../assets/settingsIcons/AnnouncementsIconWeb.png';
import mailIcon from '../../../assets/settingsIcons/mail.png';
import invitFriendsIcon from '../../../assets/settingsIcons/invitFriendsIconWeb.png';
import './styles.css';

export default function Header({ screenName }) {
    const Menu = useSelector(state => state.TextsReducer.Menu);
    const Settings = useSelector(state => state.TextsReducer.Settings);
    const StandardPublications = useSelector(state => state.TextsReducer.StandardPublications);
    const PremiumPublications = useSelector(state => state.TextsReducer.PremiumPublications);
    const status = useSelector(state => state.UserReducer.status);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    function handleScroll(event) {
        let header = document.getElementById("header-menu-mobile-container");

        if (window.pageYOffset > 0) {
            header.classList.add("sticky");
        } else {
            header.classList.remove("sticky");
        }
    }

    function headerIcon() {
        let icon;

        switch (screenName) {
            case Menu.Assistance:
                icon = assistIcon;
                break;
            case StandardPublications.MyStandardPublications:
                icon = AdvertisementsIcon;
                break;
            default:
                icon = accountIcon;
                break;
        }

        return icon;
    }

    function DropDownSettingsItem(props) {
        let icon;
        let name;
        let classNameImg = 'item-dropdown-settings-image';
        let classNameText = 'item-menu-text';

        switch (props.name) {
            case 'Account':
                icon = accountIcon;
                name = Settings.MyAccount;
                classNameImg += ' disabled';
                classNameText += ' disabled';
                break;
            case 'StandardPublications':
                icon = AdvertisementsIcon;
                name = Settings.StandardPublications;
                break;
            case 'PremiumPublications':
                icon = AnnouncementsIcon;
                name = Settings.PremiumPublications;
                classNameImg += ' special-image';
                break;
            case 'InviteFriends':
                icon = invitFriendsIcon;
                name = Settings.InviteFriends;
                classNameImg += ' special-image';
                classNameImg += ' disabled';
                classNameText += ' disabled';
                break;

            case 'ContactUs':
                icon = mailIcon;
                name = Settings.ContactUs;
                classNameImg += ' special-image';
                classNameImg += ' disabled';
                classNameText += ' disabled';
                break;
            case 'SignOut':
                icon = singOutIcon;
                name = Settings.SignOut;
                break;
            default:
                break;
        }

        return (
            <div className='item-dropdown'>
                <div className='image-dropdown-container'>
                    <img className={classNameImg} src={icon} />
                </div>
                <span className={classNameText}>{name}</span>
            </div>
        );
    }

    function DropDownMenuItem(props) {
        let icon;
        let name;
        let classNameImg = 'item-dropdown-menu-image';
        let classNameText = 'item-menu-text';

        switch (props.name) {
            case 'Assistance':
                icon = assistIcon;
                name = Menu.Assistance;
                classNameImg += ' disabled';
                classNameText += ' disabled';
                break;
            case 'MyActivities':
                icon = activitiesIcon;
                name = Menu.MyActivities;
                break;
            case 'MyStandardPublications':
                icon = AdvertisementsIcon;
                name = StandardPublications.MyStandardPublications;
                classNameImg += ' special-image';
                break;
            case 'MyPremiumPublications':
                icon = AnnouncementsIcon;
                name = PremiumPublications.MyPremiumPublications;
                break;
            case 'Settings':
                icon = settingsIcon;
                name = Menu.Settings;
                break;
            default:
                break;
        }

        if (props.name === 'Settings') {
            return (
                <div id='settings-dropdown' className='item-dropdown'>
                    <div className='image-dropdown-container'>
                        <img className={classNameImg} src={icon} />
                    </div>
                    <div className='settings-dropdown'>
                        <Dropdown
                            options={settingsOptions}
                            placeholder={null}
                            arrowClosed={<span className='settings-label'>{Menu.Settings}</span>}
                            arrowOpen={<span className='settings-label select'>{Menu.Settings} <BsFillCaretDownFill style={{ position: 'absolute', bottom: 0, right: -20 }} size={16} color="#FFF" /></span>}
                            onChange={event => {
                                switch (event.value) {
                                    case 'StandardPublications':
                                        history.push('/newPublication')
                                        break;
                                    case 'PremiumPublications':
                                        history.push('/newPremiumPublication')
                                        break;
                                    case 'SignOut':
                                        dispatch(siteSingOut(history))
                                        break;
                                    default:
                                        break;
                                }
                            }}
                        />
                    </div>
                </div>
            )
        }
        return (
            <div className='item-dropdown'>
                <div className='image-dropdown-container'>
                    <img className={classNameImg} src={icon} />
                </div>
                <span className={classNameText}>{name}</span>
            </div>
        );
    }

    const settingsOptions = [
        { label: Menu.Settings, value: 'Account', view: <DropDownSettingsItem name='Account' />, disabled: true },
        { label: Menu.Settings, value: 'StandardPublications', view: <DropDownSettingsItem name='StandardPublications' /> },
        status === 'VERIFIED_ANNOUNCER' || status === 'TESTER' || status === 'ADMIN' ? { label: Menu.Settings, value: 'PremiumPublications', view: <DropDownSettingsItem name='PremiumPublications' /> } : null,
        { label: Menu.Settings, value: 'InviteFriends', view: <DropDownSettingsItem name='InviteFriends' />, disabled: true },
        { label: Menu.Settings, value: 'ContactUs', view: <DropDownSettingsItem name='ContactUs' />, disabled: true },
        { label: Menu.Settings, value: 'SignOut', view: <DropDownSettingsItem name='SignOut' /> }
    ];

    const menuOptions = [
        { label: 'a', value: 'Assistance', view: <DropDownMenuItem name='Assistance' />, disabled: true },
        { label: 'a', value: 'MyActivities', view: <DropDownMenuItem name='MyActivities' /> },
        { label: 'a', value: 'Settings', view: <DropDownMenuItem name='Settings' />, disabled: true },
    ];

    return (
        <div id="header-menu-mobile-container">
            <div className='menu-options-container'>
                <Dropdown
                    options={menuOptions}
                    onChange={event => {
                        switch (event.value) {
                            case 'MyActivities':
                                history.push('/myActivities')
                                break;
                            default:
                                break;
                        }
                    }}
                    placeholder='a'
                    arrowClosed={<IoIosMenu size={50} color="#FFF" />}
                    arrowOpen={<IoIosMenu size={50} color="#FFF" />}
                />
                <div className='menu-space' />
                <div className='menu-screen-name-container'>
                    <span className='menu-text'>{screenName}</span>
                    <img className='menu-img' src={headerIcon()} />
                </div>
            </div>
        </div>
    )
}