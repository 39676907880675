import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useSelector } from 'react-redux';

import Header from '../../../../components/header';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function AdvertCosts() {
    const StandardPublications = useSelector(state => state.TextsReducer.StandardPublications);

    const [numPages, setNumPages] = useState(null);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [value, setValue] = useState("https://assistfilestorage.blob.core.windows.net/info/ASSIST-PRICES.pdf");

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    const documentOptions = {
        cMapUrl: 'cmaps/',
        cMapPacked: true,
    };

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
    }

    return (
        <div>
            <Header back='/aboutPublications' screen={StandardPublications.AdvertCosts} />
            <Document loading={" "} options={documentOptions} file={value} onLoadSuccess={onDocumentLoadSuccess}>
                {
                    Array.from(
                        new Array(numPages),
                        (el, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                loading={" "}
                                width={windowDimensions.width <= 1280 ? windowDimensions.width : windowDimensions.width * 80 / 100}
                            />
                        ),
                    )
                }  
            </Document>
        </div>
    )
}