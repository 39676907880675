import React from 'react';
import { Dropdown } from 'react-dropdown-now';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import 'react-dropdown-now/style.css';

import { siteSingOut } from '../../../redux/actions/userActions';

import assistIcon from '../../../assets/settingsIcons/miniAssistIconWeb.png';
import activitiesIcon from '../../../assets/book-open-outline.png';
import settingsIcon from '../../../assets/settingsIcon.png';
import singOutIcon from '../../../assets/settingsIcons/singOutIcon.png';

import accountIcon from '../../../assets/settingsIcons/accountIcon.png';
import AdvertisementsIcon from '../../../assets/settingsIcons/AdvertisementsIcon.png';
import AnnouncementsIcon from '../../../assets/settingsIcons/AnnouncementsIconWeb.png';
import mailIcon from '../../../assets/settingsIcons/mail.png';
import invitFriendsIcon from '../../../assets/settingsIcons/invitFriendsIconWeb.png';
import './styles.css';

export default function Header({ back, screen }) {
    const Menu = useSelector(state => state.TextsReducer.Menu);
    const Settings = useSelector(state => state.TextsReducer.Settings);
    const status = useSelector(state => state.UserReducer.status);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    function DropDownItem(props) {
        let icon;
        let name;
        let classNameImg = 'item-dropdown-image';
        let classNameText = 'item-text'

        switch (props.name) {
            case 'Account':
                icon = accountIcon;
                name = Settings.MyAccount;
                classNameImg += ' disabled'
                classNameText += ' disabled'
                break;
            case 'StandardPublications':
                icon = AdvertisementsIcon;
                name = Settings.StandardPublications;
                break;
            case 'PremiumPublications':
                icon = AnnouncementsIcon;
                name = Settings.PremiumPublications;
                classNameImg += ' special-image';
                break;
            case 'InviteFriends':
                icon = invitFriendsIcon;
                name = Settings.InviteFriends;
                classNameImg += ' special-image';
                classNameImg += ' disabled'
                classNameText += ' disabled'
                break;
            case 'ContactUs':
                icon = mailIcon;
                name = Settings.ContactUs;
                classNameImg += ' disabled'
                classNameText += ' disabled'
                break;
            case 'SignOut':
                icon = singOutIcon;
                name = Settings.SignOut;
                break;
            default:
                break;
        }

        return (
            <div className='item-dropdown'>
                <div className='image-dropdown-container'>
                    <img className={classNameImg} src={icon} />
                </div>
                <span className={classNameText}>{name}</span>
            </div>
        );
    }

    const settingsOptions = [
        { label: Menu.Settings, value: 'Account', view: <DropDownItem name='Account' />, disabled: true },
        { label: Menu.Settings, value: 'StandardPublications', view: <DropDownItem name='StandardPublications' /> },
        status === 'VERIFIED_ANNOUNCER' || status === 'TESTER' || status === 'ADMIN' ? { label: Menu.Settings, value: 'PremiumPublications', view: <DropDownItem name='PremiumPublications' /> } : null,
        { label: Menu.Settings, value: 'InviteFriends', view: <DropDownItem name='InviteFriends' />, disabled: true },
        { label: Menu.Settings, value: 'ContactUs', view: <DropDownItem name='ContactUs' />, disabled: true },
        { label: Menu.Settings, value: 'SignOut', view: <DropDownItem name='SignOut' /> }
    ];

    return (
        <div id="header-menu-container">
            <div className='menu-options-container'>
                <button id='main-header-assist-option' className='menu-option disabled' type="button" onClick={() => null}>{Menu.Assistance} <img className='menu-image' src={assistIcon} /></button>
                <button id='main-header-activities-option' className={`menu-option${location.pathname === '/myActivities' || location.pathname === '/' ? ' active' : ''}`} type="button" onClick={() => history.push('/myActivities')}>{Menu.MyActivities} <img className='menu-image' src={activitiesIcon} /></button>
                <div id='main-header-config-option' className='menu-option-dropdown'>
                    <Dropdown
                        options={settingsOptions}
                        placeholder={Menu.Settings}
                        onChange={event => {
                            switch (event.value) {
                                case 'StandardPublications':
                                    history.push('/newPublication')
                                    break;
                                case 'PremiumPublications':
                                    history.push('/newPremiumPublication')
                                    break;
                                case 'SignOut':
                                    dispatch(siteSingOut(history))
                                    break;
                                default:
                                    break;
                            }
                        }}
                        onOpen={() => {
                            document.getElementById("main-header-config-option").classList.add('active');
                            if(location.pathname === '/myActivities' || location.pathname === '/') {
                                document.getElementById("main-header-activities-option").classList.remove('active');
                            }
                        }}
                        onClose={() => {
                            document.getElementById("main-header-config-option").classList.remove('active');
                            if(location.pathname === '/myActivities' || location.pathname === '/') {
                                document.getElementById("main-header-activities-option").classList.add('active');
                            }
                        }}
                    />
                    <img className='dropdown-image' src={settingsIcon} />
                </div>
            </div>
        </div>
    )
}