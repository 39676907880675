import React from 'react';

import backgroundListView from '../../../assets/backgroundListView.png';

import './styles.css';

export default function ListEmptyComponent({message}) {
    return (
        <div id="empty-list-container">
            <img src={backgroundListView} />
            <p>{ message }</p>
        </div>
    )
}