import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

import { getUserData, getTextLanguage } from '../../../redux/actions/userActions';

import HeaderMenu from '../../components/headerMenu';
import HeaderMenuMobile from '../../components/headerMenuMobile';

import AdvertisementsIcon from '../../../assets/settingsIcons/AdvertisementsIcon.png';
import AnnouncementsIcon from '../../../assets/settingsIcons/AnnouncementsIconWeb.png';

import './styles.css';

export default function Home() {
    const StandardPublications = useSelector(state => state.TextsReducer.StandardPublications);
    const PremiumPublications = useSelector(state => state.TextsReducer.PremiumPublications);
    const Menu = useSelector(state => state.TextsReducer.Menu);
    const status = useSelector(state => state.UserReducer.status);
    const id = useSelector(state => state.UserReducer.id);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (id !== 0) {
            dispatch(getUserData());
        }
    }, [id]);

    useEffect(() => {
        let deviceLanguage = navigator.language || navigator.userLanguage;
        deviceLanguage = deviceLanguage.replace("-", "_")

        dispatch(getTextLanguage(deviceLanguage));
    }, []);

    return (
        <div id="activities-container">
            <HeaderMenu />
            <HeaderMenuMobile screenName={Menu.MyActivities} />
            <div className='activities-content'>
                <button className='activities-button' onClick={() => history.push('/myPublications')}>
                    {StandardPublications.MyStandardPublications}
                    <img className='menu-image' src={AdvertisementsIcon} />
                </button>
                {(status === 'VERIFIED_ANNOUNCER' || status === 'TESTER' || status === 'ADMIN') && <button className='activities-button' onClick={() => history.push('/myPremiumPublications')}>
                    {PremiumPublications.MyPremiumPublications}
                    <img className='menu-image too-big' src={AnnouncementsIcon} />
                </button>}
            </div>
        </div>
    )
}