import React from 'react';
import ProgressBar from "@ramonak/react-progress-bar";
import { BsCheckLg } from "react-icons/bs";
import { FiExternalLink } from "react-icons/fi";
import ReactTooltip from 'react-tooltip';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import 'react-tooltip/dist/index.js'

import { announcementAttendMeetUp } from '../../../../redux/actions/assistActions';

import premiumVerifiedIcon from '../../../../assets/premiumVerified.png';
import './styles.css';

export default function AnnouncementsItem({ item }) {
    const PremiumPublications = useSelector(state => state.TextsReducer.PremiumPublications);
    const MeetUps = useSelector(state => state.TextsReducer.MeetUps);
    const Surveys = useSelector(state => state.TextsReducer.Surveys);
    const Actions = useSelector(state => state.TextsReducer.Actions);
    const itemMessageID = useSelector(state => state.AssistReducer.itemMessageID);
    const itemErrorMessage = useSelector(state => state.AssistReducer.itemErrorMessage);
    const itemSuccessMessage = useSelector(state => state.AssistReducer.itemSuccessMessage);
    const meetUpTemporaryStatus = useSelector(state => state.AssistReducer.meetUpTemporaryStatus);
    const dispatch = useDispatch();
    const history = useHistory();

    function daysBetween(date) {
        let one = new Date();
        let two = new Date(date);

        let millisecondsPerDay = 1000 * 60 * 60 * 24;
        let millisBetween = two.getTime() - one.getTime();
        let days = millisBetween / millisecondsPerDay;

        return Math.floor(days);
    }

    function renderMessages(itemID) {
        if (itemMessageID === itemID && itemErrorMessage.length > 5) {
            return (
                <p className='error-meet-up-message'>{itemErrorMessage}</p>
            );
        } else if (itemMessageID === itemID && itemSuccessMessage.length > 5) {
            return (
                <p className='success-meet-up-message'>{itemSuccessMessage}</p>
            );
        } else {
            return;
        }
    }

    function _renderLocations() {
        if (item.creatorExtraData.coverageType === 'LOCALITY_NAME' || item.creatorExtraData.coverageType === 'POSTAL_CODE') {
            return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {item.creatorExtraData.locations.map((location, index) => {
                        if (item.creatorExtraData.coverageType === 'LOCALITY_NAME') {
                            if (index === item.creatorExtraData.locations.length - 1) {
                                return <p style={{ marginBottom: 0 }} key={location.locality}>{location.locality}</p>
                            } else {
                                return <p style={{ marginBottom: 0 }} key={location.locality}>{location.locality},&nbsp;</p>
                            }
                        } else {
                            if (index === item.creatorExtraData.locations.length - 1) {
                                return <p style={{ marginBottom: 0 }} key={location.locality}>{location.zipCode}</p>
                            } else {
                                return <p style={{ marginBottom: 0 }} key={location.locality}>{location.zipCode},&nbsp;</p>
                            }
                        }
                    })}
                </div>
            );
        }
    }

    function _meetUpButtonColor(meetUpData, statusButton) {
        if (meetUpData.userAttendanceStatus === statusButton) {
            if (meetUpTemporaryStatus.key === meetUpData.id && meetUpTemporaryStatus.status !== meetUpData.userAttendanceStatus) {
                return '#0091D0'
            } else if (meetUpTemporaryStatus.key === meetUpData.id && meetUpTemporaryStatus.status === meetUpData.userAttendanceStatus) {
                return '#97e0ff'
            }

            return '#97e0ff'
        } else {
            if (meetUpTemporaryStatus.key === meetUpData.id && meetUpTemporaryStatus.status === statusButton) {
                return '#97e0ff'
            }
            return '#0091D0'
        }
    }

    if (item.type === 'MEET_UP') {
        return (
            <div id="myAnnouncementsItem-container">
                <div className='announc-header'>
                    <div>
                        <img className='image-header' src={item.announcerPhoto} />
                        <img className='image-verified' src={premiumVerifiedIcon} />
                    </div>

                    <div className='announc-header-name'>
                        <span>{item.announcerFirstName?.toUpperCase()} {item.announcerLastName?.toUpperCase()}</span>
                        {item.announcerAdditionalDescription && <span>{item.announcerAdditionalDescription}</span>}
                    </div>
                </div>

                <div className="header-announc-item">
                    <button className="announcTitleContainer" onClick={() => history.push({ pathname: '/fullPremiumPublication', state: item })}>
                        <p className='publication-name'>
                            {item.title}
                            <span className='message-icon-container' data-tip={Actions.MoreDetails}><FiExternalLink style={{ marginLeft: 5 }} size={11} color='#0383ba' /></span>
                            <ReactTooltip className='tool-tip' />
                        </p>
                    </button>
                </div>

                <div className="announcTextContainer">
                    <p>{item.text}</p>
                </div>

                <div className="imageContainer">
                    <img src={item.image} className="announcImage" />
                </div>

                <div className="locations">
                    {_renderLocations()}
                    <div style={{ display: 'flex', marginTop: 3 }}>
                        <p>{item.creatorExtraData.locations[0].regionOrState}&nbsp;</p>
                        <p>{item.creatorExtraData.locations[0].country}</p>
                    </div>
                </div>

                <div className="footer">
                    <div className='footer-item'>
                        <p>{MeetUps.MeetUpLocation}: {item.meetUpData.meetUpAddress}</p>
                    </div>

                    <div className='footer-item'>
                        <p>{MeetUps.MeetUpStartDate}: {item.meetUpData.startDateFormatted}</p>
                    </div>

                    <div className='footer-item'>
                        <p>{MeetUps.MeetUpEndDate}: {item.meetUpData.endDateFormatted}</p>
                    </div>

                    <div className='meet-up-container'>
                        {renderMessages(item.meetUpData.id)}

                        <div className='meet-up-buttons-container'>
                            <button className='meet-up-button' style={{ backgroundColor: _meetUpButtonColor(item.meetUpData, 'ATTENDING') }} onClick={() => item.meetUpData.userAttendanceStatus !== 'ATTENDING' ? dispatch(announcementAttendMeetUp(item.id, item.meetUpData.id, 'ATTENDING')) : null} disabled={daysBetween(item.meetUpData.startDate) < 0}>
                                {MeetUps.Attend}
                            </button>

                            <button className='meet-up-button' style={{ backgroundColor: _meetUpButtonColor(item.meetUpData, 'MAYBE_ATTENDING') }} onClick={() => item.meetUpData.userAttendanceStatus !== 'MAYBE_ATTENDING' ? dispatch(announcementAttendMeetUp(item.id, item.meetUpData.id, 'MAYBE_ATTENDING')) : null} disabled={daysBetween(item.meetUpData.startDate) < 0}>
                                {MeetUps.MaybeAttend}
                            </button>
                        </div>

                        <div className='meet-up-attendees-container'>
                            <div>
                                <p>{MeetUps.Attendees}: {item.meetUpData.numberOfConfirmedAttendances}</p>
                            </div>

                            <div>
                                <p>{MeetUps.MaybeAttendees}: {item.meetUpData.numberOfPossibleAttendances}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (item.type === 'SURVEY') {
        let totalNumbersOfVotes = 0;

        item.surveyData.options.forEach(item => {
            totalNumbersOfVotes = totalNumbersOfVotes + item.numberOfVotes;
        });

        return (
            <div id="myAnnouncementsItem-container">
                <div className='announc-header'>
                    <div>
                        <img className='image-header' src={item.announcerPhoto} />
                        <img className='image-verified' src={premiumVerifiedIcon} />
                    </div>

                    <div className='announc-header-name'>
                        <span>{item.announcerFirstName?.toUpperCase()} {item.announcerLastName?.toUpperCase()}</span>
                        {item.announcerAdditionalDescription && <span>{item.announcerAdditionalDescription}</span>}
                    </div>
                </div>

                <div className="header-announc-item">
                    <button className="announcTitleContainer" onClick={() => history.push({ pathname: '/fullPremiumPublication', state: item })}>
                        <p className='publication-name'>
                            {item.title}
                            <span className='message-icon-container' data-tip={Actions.MoreDetails}><FiExternalLink style={{ marginLeft: 5 }} size={11} color='#0383ba' /></span>
                            <ReactTooltip className='tool-tip' />
                        </p>
                    </button>
                </div>

                <div className="announcTextContainer">
                    <p>{item.text}</p>
                </div>

                <p className='survey-question-title'>{item.surveyData.question}</p>

                <div className='survey-data-container'>
                    {item.surveyData.options.map(option => {
                        return (
                            <div key={option.id} className='survey-data-option'>
                                <div className='survey-data-check'>
                                    {item.surveyData.userSelectedOption && item.surveyData.userSelectedOption.id === option.id ? <BsCheckLg size={16} color="#0074a6" /> : null}
                                </div>

                                <div className='survey-bar-container'>
                                    <span className='option-text'>{option.text.trim()}</span>
                                    <ProgressBar
                                        completed={totalNumbersOfVotes === 0 ? 0 : option.numberOfVotes / totalNumbersOfVotes * 100}
                                        bgColor='#0091D0'
                                        className="wrapper"
                                        barContainerClassName="container"
                                        labelClassName="label"
                                        labelAlignment='center'
                                    />
                                </div>
                            </div>
                        );
                    })}
                    <p style={{ marginTop: 10, fontStyle: 'italic', fontSize: 12 }}>{Surveys.TotalNumberOfVotes}: {totalNumbersOfVotes}</p>
                </div>

                <div className="locations">
                    {_renderLocations()}
                    <div style={{ display: 'flex', marginTop: 3 }}>
                        <p>{item.creatorExtraData.locations[0].regionOrState}&nbsp;</p>
                        <p>{item.creatorExtraData.locations[0].country}</p>
                    </div>
                </div>

                <div className="footer">
                    <div className='footer-space' />

                    <div className='footer-item'>
                        <p className='text-date'>{PremiumPublications.StartDate}:&nbsp; <p className='date'>{item.creatorExtraData.startDateFormatted}</p></p>
                    </div>

                    {item.creatorExtraData.endDate !== null && <div className='footer-item'>
                        <p className='text-date'>{PremiumPublications.EndDate}:&nbsp; <p className='date'>{item.creatorExtraData.endDateFormatted}</p></p>
                    </div>}

                    {item.creatorExtraData.earlierEndDate !== null && <div className='footer-item'>
                        <p className='text-date'>{PremiumPublications.EarlierEndDate}:&nbsp; <p className='date'>{item.creatorExtraData.earlierEndDateFormatted}</p></p>
                    </div>}
                </div>
            </div >
        )
    }

    return (
        <div id="myAnnouncementsItem-container">
            <div className='announc-header'>
                <div>
                    <img className='image-header' src={item.announcerPhoto} />
                    <img className='image-verified' src={premiumVerifiedIcon} />
                </div>

                <div className='announc-header-name'>
                    <span>{item.announcerFirstName?.toUpperCase()} {item.announcerLastName?.toUpperCase()}</span>
                    {item.announcerAdditionalDescription && <span>{item.announcerAdditionalDescription}</span>}
                </div>
            </div>

            <div className="header-announc-item">
                <button className="announcTitleContainer" onClick={() => history.push({ pathname: '/fullPremiumPublication', state: item })}>
                    <p className='publication-name'>
                        {item.title}
                        <span className='message-icon-container' data-tip={Actions.MoreDetails}><FiExternalLink style={{ marginLeft: 5 }} size={11} color='#0396d5' /></span>
                        <ReactTooltip className='tool-tip' />
                    </p>
                </button>
            </div>

            <div className="announcTextContainer">
                <p>{item.text}</p>
            </div>

            <div className="imageContainer">
                <img src={item.image} className="announcImage" />
            </div>

            <div className="locations">
                {_renderLocations()}
                <div style={{ display: 'flex', marginTop: 3 }}>
                    <p>{item.creatorExtraData.locations[0].regionOrState}&nbsp;</p>
                    <p>{item.creatorExtraData.locations[0].country}</p>
                </div>
            </div>

            <div className="footer">
                <div className='footer-space' />

                <div className='footer-item'>
                    <p className='text-date'>{PremiumPublications.StartDate}:&nbsp; <p className='date'>{item.creatorExtraData.startDateFormatted}</p></p>
                </div>

                {item.creatorExtraData.endDate !== null && <div className='footer-item'>
                    <p className='text-date'>{PremiumPublications.EndDate}:&nbsp; <p className='date'>{item.creatorExtraData.endDateFormatted}</p></p>
                </div>}

                {item.creatorExtraData.earlierEndDate !== null && <div className='footer-item'>
                    <p className='text-date'>{PremiumPublications.EarlierEndDate}:&nbsp; <p className='date'>{item.creatorExtraData.earlierEndDateFormatted}</p></p>
                </div>}
            </div>
        </div >
    );
}