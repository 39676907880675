import { combineReducers } from 'redux';

import TextsReducer from './textsReducer';
import AuthReducer from './authReducer';
import UserReducer from './userReducer';
import RegisterReducer from './registerReducer';
import AssistReducer from './assistReducer';
import ChatReducer from './chatReducer';
import HistoryReducer from './historyReducer';
import SettingsReducer from './settingsReducer';
import AnnouncementsReducer from './announcementsReducer';
import AdvertisementsReducer from './advertisementsReducer';
import RoutesReducer from './routesReducer';

export default combineReducers({
    TextsReducer,
    AuthReducer,
    RegisterReducer,
    UserReducer,
    AssistReducer,
    ChatReducer,
    HistoryReducer,
    SettingsReducer,
    AnnouncementsReducer,
    AdvertisementsReducer,
    RoutesReducer
});