const INITIAL_STATE = {
    id: 0,
    username: '',
    authorities: [],
    token: '',
    language: '',
    enabled: false,
    showRules: [],
    showExplanation: [],
    loadingExplanation: false,
    auth: false,
    getUserData: false,
    firstName: '',
    lastName: '',
    photo: '',
    gender: '',
    birthDate: '',
    accountCalendar: false,
    phoneNr: '',
    zipCode: '',
    locality: '',
    regionOrState: '',
    country: '',
    shortBio: '',
    identityCard: '',
    role: '',
    status: '',
    currentArea: '',
    numberOfUsersAroundMe: '',
    announcerBroadcastingArea: '',
    announcerAdditionalDescription: '',
    latitude: '',
    longitude: '',
    locationInfo: {},
    requestFilter: 3,
    activitiesFilter: 'ALL',
    countries: [],
    areAdvertsAvailableForGeneralAudience: false,
    numberOfBroadcastingDaysForRequest: 15
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_COUNTRIES':
            return { ...state, countries: action.payload }
        case 'START_USE_ASSIST':
            return { ...state, showRules: action.payload }
        case 'REFRESH_TOKEN':
            return { ...state, token: action.payload }
        case 'SET_USER':
            return {
                ...state,
                id: action.payload.id,
                username: action.payload.username,
                authorities: action.payload.authorities,
                token: action.payload.token,
                enabled: action.payload.enabled,
                auth: true,
                getUserData: true
            }
        case 'CLOSE_USER_DATA':
            return { ...state, getUserData: false }
        case 'GET_TEXT_LANGUAGE':
            return { ...state, language: action.payload }
        case 'SET_USER_DATA':
            return {
                ...state,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                photo: action.payload.photo,
                gender: action.payload.gender,
                birthDate: action.payload.birthDate,
                phoneNr: action.payload.phoneNr,
                zipCode: action.payload.zipCode,
                locality: action.payload.locality,
                regionOrState: action.payload.regionOrState,
                country: action.payload.country,
                shortBio: action.payload.shortBio,
                identityCard: action.payload.identityCard,
                role: action.payload.role,
                status: action.payload.status,
                announcerBroadcastingArea: action.payload.announcerBroadcastingArea,
                requestFilter: action.payload.currentFilter,
                announcerAdditionalDescription: action.payload.announcerAdditionalDescription,
            }
        case 'SET_CURRENT_AREA_INFOS':
            return { ...state, currentArea: action.payload.currentArea, numberOfUsersAroundMe: action.payload.numberOfUsers }
        case 'SET_NUMBER_OF_DAYS_FOR_REQUEST':
            return { ...state, numberOfBroadcastingDaysForRequest: action.payload }
        case 'GET_ALL_REQUESTS':
            return { ...state, locationInfo: action.payload.info }
        case 'SET_USER_LOCATION':
            return { ...state, latitude: action.payload.latitude, longitude: action.payload.longitude }
        case 'SET_UPLOADED_USER_IMAGE':
            return { ...state, photo: action.payload }
        case 'SET_USER_BIO':
            return { ...state, shortBio: action.payload }
        case 'SET_USER_FIRST_NAME':
            return { ...state, firstName: action.payload }
        case 'SET_USER_LAST_NAME':
            return { ...state, lastName: action.payload }
        case 'SET_USER_GENDER':
            return { ...state, gender: action.payload }
        case 'SET_USER_BIRTH_DATE':
            return { ...state, birthDate: action.payload, accountCalendar: false }
        case 'SET_USER_BIRTH_DATE_IOS':
            return { ...state, birthDate: action.payload }
        case 'OPEN_ACCOUNT_CALENDAR':
            return { ...state, accountCalendar: true }
        case 'CLOSE_ACCOUNT_CALENDAR':
            return { ...state, accountCalendar: false }
        case 'SET_USER_EMAIL':
            return { ...state, username: action.payload }
        case 'SET_USER_PHONE':
            return { ...state, phoneNr: action.payload }
        case 'SET_USER_IDENTITY':
            return { ...state, identityCard: action.payload }
        case 'SET_USER_COUNTRY':
            return { ...state, country: action.payload }
        case 'SET_USER_REGION':
            return { ...state, regionOrState: action.payload }
        case 'SET_USER_CITY':
            return { ...state, locality: action.payload }
        case 'SET_USER_ZIPCODE':
            return { ...state, zipCode: action.payload }
        case 'SET_SUGEST_USER_LOCATION':
            return { ...state, country: action.payload.countryCode, regionOrState: action.payload.regionOrState, locality: action.payload.locality, zipCode: action.payload.postalCode }
        case 'SET_REQUEST_FILTER':
            return { ...state, requestFilter: action.payload }
        case 'SET_ACTIVITIES_FILTER':
            return { ...state, activitiesFilter: action.payload }
        case 'ASYNC_SEND_USER_IMAGE':
            return { ...state, photo: '' }
        case 'SET_EXPLANATION_LOADING':
            return { ...state, loadingExplanation: action.payload }
        case 'SET_EXPLANATION_USER':
            return { ...state, showExplanation: action.payload }
        case 'ADVERTS_AVAILABLE_FOR_GENERAL_USERS':
            return { ...state, areAdvertsAvailableForGeneralAudience: action.payload }
        case 'LOG_OUT':
            return {
                ...state,
                id: 0,
                username: '',
                authorities: [],
                token: '',
                enabled: false,
                auth: false,
                firstName: '',
                lastName: '',
                photo: '',
                gender: '',
                birthDate: '',
                phoneNr: '',
                zipCode: '',
                locality: '',
                regionOrState: '',
                country: '',
                shortBio: '',
                identityCard: '',
                role: '',
                status: '',
                currentArea: '',
                numberOfUsersAroundMe: '',
                announcerAdditionalDescription: ''
            }
        default:
            return state;
    }
}