const INITIAL_STATE = {
    contactTitle: '',
    contactContent: '',
    contactLoad: false,
    settingErrorMessage: '',
    settingSuccessMessage: '',
    myAccountFileds: false,
    passwordUpdateModal: false,
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    loadingUpdatePassword: false,
    modalErrorMessage: '',
    deleteAccountModal: false,
    invitedFriendEmail: '',
    invitedFriendEmail2: '',
    invitedFriendEmail3: '',
    inviteFriendLoading: false,
    inviteFriendFields: 1,
    fieldError: false,
    webScreen: 'advertisements'
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case 'MODIFY_CONTACT_TITLE':
            return { ...state, contactTitle: action.payload }
        case 'MODIFY_CONTACT_CONTENT':
            return { ...state, contactContent: action.payload }
        case 'SET_ERRO_CONTACT_MESSAGE':
            return { ...state, settingErrorMessage: action.payload }
        case 'SET_SUCCESS_CONTACT_MESSAGE':
            return { ...state, settingSuccessMessage: action.payload }
        case 'ASYNC_SEND_MESSAGE':
            return { ...state, contactLoad: true }
        case 'SENT_MESSAGE': 
            return { ...state, contactLoad: false, contactTitle: '', contactContent: '', settingErrorMessage: '', settingSuccessMessage: action.payload  }
        case 'CLEAR_CONTACT_FORM': 
            return { ...state, contactTitle: '', contactContent: '', settingErrorMessage: '', settingSuccessMessage: '' }
        case 'MY_ACCOUNT_FILDS':
            return { ...state, myAccountFileds: action.payload }
        case 'PASSWORD_UPDATE_MODAL':
            return { ...state, passwordUpdateModal: action.payload }
        case 'SET_CURRENT_PASSWORD':
            return { ...state, currentPassword: action.payload }
        case 'SET_NEW_PASSWORD':
            return { ...state, newPassword: action.payload }
        case 'SET_CONFIRM_NEW_PASSWORD':
            return { ...state, confirmNewPassword: action.payload }
        case 'ASYNC_UPDATE_PASSWORD':
            return { ...state, loadingUpdatePassword: true, modalErrorMessage: '' }
        case 'PASSWORD_CHANGED':
            return { ...state, modalErrorMessage: '', loadingUpdatePassword: false, passwordUpdateModal: false, currentPassword: '', newPassword: '', confirmNewPassword: '' }
        case 'SET_MODAL_ERROR_MESSAGE':
            return { ...state, modalErrorMessage: action.payload }
        case 'FAIL_CHANGE_PASSWORD':
            return { ...state, modalErrorMessage: '', passwordUpdateModal: false, loadingUpdatePassword: false }
        case 'DELETE_ACCOUNT_MODAL':
            return { ...state, deleteAccountModal: action.payload }
        case 'SET_INVITED_FRIEND_EMAIL':
            return { ...state, invitedFriendEmail: action.payload }
        case 'SET_INVITED_FRIEND_EMAIL2':
            return { ...state, invitedFriendEmail2: action.payload }
        case 'SET_INVITED_FRIEND_EMAIL3':
            return { ...state, invitedFriendEmail3: action.payload }
        case 'ASYNC_INVITE_FRIENDS':
            return { ...state, inviteFriendLoading: true }
        case 'FINISH_INVITE_FRIENDS':
            return { ...state, inviteFriendLoading: false }
        case 'SET_UPDATE_INVITE_FIELDS':
            return { ...state, inviteFriendFields: action.payload }
        case 'SET_FIELD_SETTINGS_ERROR':
            return { ...state, fieldError: action.payload }
        case 'SET_WEB_SCREEN':
            return { ...state, webScreen: action.payload }
        case 'LOG_OUT':
            return { ...state, deleteAccountModal: false }
        default:
            return state;
    }
}