const INITIAL_STATE = {
    username: '',
    password: '',
    erroLogin: '',
    successLogin: '',
    loading_login: false
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case 'MODIFY_USERNAME':
            return { ...state, username: action.payload }
        case 'MODIFY_PASSWORD':
            return { ...state, password: action.payload }
        case 'ASYNC_AUTHENTICATE':
            return { ...state, loading_login: true, erroLogin: '' }
        case 'SET_USER':
            return { ...state, erroLogin: '', loading_login: false }
        case 'FAIL_LOGIN': 
            return { ...state, erroLogin: action.payload, loading_login: false }
        case 'FINISH_ALERT':
            return { ...state, erroLogin: '', successLogin: '' }
        case 'SUCCESS_LOGIN_MESSAGE': 
            return { ...state, successLogin: action.payload, username: '' }
        case 'LOG_OUT':
            return { ...state, username: '', password: '' }
        default:
            return state;
    }
}