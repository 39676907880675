import React, { useEffect, useRef } from 'react';
import Dropzone from 'react-dropzone';
import { FiAlertCircle, FiHelpCircle } from "react-icons/fi";
import { FaLongArrowAltRight, FaChevronDown } from "react-icons/fa";
import { Spinner } from 'react-activity';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import dt from 'date-and-time';
import { useSelector, useDispatch } from 'react-redux';
import 'react-tooltip/dist/index.js'

import Header from '../../../../components/header';
import AdvertisementHeader from '../../../../components/adsHeader';

import { getAdvertisementsAllowedCountries, modifyAdvertisementsTitle, modifyAdvertisementsDays, modifyAdvertisementsLink, modifyAdvertisementsProduct, modifyMeetUpAddress, modifyAdvertisementsDesc, modifySelectArea, asyncSendAdvertisementImage, setNumberOfFileds, modifyAdvertisementCountry, modifyAdvertisementRegion, modifyAdvertisementCity1, modifyAdvertisementCity2, modifyAdvertisementCity3, modifyAdvertisementZipCode1, modifyAdvertisementZipCode2, modifyAdvertisementZipCode3, openAdvertisementCalendar, closeAdvertisementCalendar, setStartDate, advertisementsError, asyncSendAdvertisement, asyncCalculatePrice, modifyAdvertType, setMeetUpStartDate, setMeetUpEndDate, changePublishAsSoonAsPossible, clearAdvertisements, clearAdvertLocality, setChangeLocality, retrieveRegionsOfCountry, retrieveLocalitiesOfRegion } from '../../../../../redux/actions/advertisementsActions';
import { setFieldError } from '../../../../../redux/actions/settingsActions';
import './styles.css';

export default function NewAdvertisements() {
    const Errors = useSelector(state => state.TextsReducer.Errors);
    const Actions = useSelector(state => state.TextsReducer.Actions);
    const StandardPublications = useSelector(state => state.TextsReducer.StandardPublications);
    const StandardPublicationTypes = useSelector(state => state.TextsReducer.StandardPublicationTypes);
    const MeetUps = useSelector(state => state.TextsReducer.MeetUps);
    const ClassifiedAds = useSelector(state => state.TextsReducer.ClassifiedAds);
    const Assistance = useSelector(state => state.TextsReducer.Assistance);
    const SubscriptionAndAccount = useSelector(state => state.TextsReducer.SubscriptionAndAccount);
    const advertisementsCountries = useSelector(state => state.AdvertisementsReducer.advertisementsCountries);
    const regionsOfCountry = useSelector(state => state.AdvertisementsReducer.regionsOfCountry);
    const localitiesOfRegion = useSelector(state => state.AdvertisementsReducer.localitiesOfRegion);
    const postalCodesOfRegion = useSelector(state => state.AdvertisementsReducer.postalCodesOfRegion);
    const advertType = useSelector(state => state.AdvertisementsReducer.advertType);
    const title = useSelector(state => state.AdvertisementsReducer.title);
    const productName = useSelector(state => state.AdvertisementsReducer.productName);
    const link = useSelector(state => state.AdvertisementsReducer.link);
    const content = useSelector(state => state.AdvertisementsReducer.content);
    const image = useSelector(state => state.AdvertisementsReducer.image);
    const loadingImage = useSelector(state => state.AdvertisementsReducer.loadingImage);
    const numberOfFileds = useSelector(state => state.AdvertisementsReducer.numberOfFileds);
    const selectArea = useSelector(state => state.AdvertisementsReducer.selectArea);
    const numberOfDays = useSelector(state => state.AdvertisementsReducer.numberOfDays);
    const AdvertisementCountry = useSelector(state => state.AdvertisementsReducer.AdvertisementCountry);
    const AdvertisementRegion = useSelector(state => state.AdvertisementsReducer.AdvertisementRegion);
    const locality1 = useSelector(state => state.AdvertisementsReducer.locality1);
    const locality2 = useSelector(state => state.AdvertisementsReducer.locality2);
    const locality3 = useSelector(state => state.AdvertisementsReducer.locality3);
    const advertisementCalendar = useSelector(state => state.AdvertisementsReducer.advertisementCalendar);
    const startDate = useSelector(state => state.AdvertisementsReducer.startDate);
    const calendarType = useSelector(state => state.AdvertisementsReducer.calendarType);
    const publishAsSoonAsPossible = useSelector(state => state.AdvertisementsReducer.publishAsSoonAsPossible);
    const price = useSelector(state => state.AdvertisementsReducer.price);
    const currency = useSelector(state => state.AdvertisementsReducer.currency);
    const advertisementLoading = useSelector(state => state.AdvertisementsReducer.advertisementLoading);
    const advertisementErrorMessage = useSelector(state => state.AdvertisementsReducer.advertisementErrorMessage);
    const advertisementSuccessMessage = useSelector(state => state.AdvertisementsReducer.advertisementSuccessMessage);
    const meetUpAddress = useSelector(state => state.AdvertisementsReducer.meetUpAddress);
    const potentialNumberOfViewsForAdvert = useSelector(state => state.AdvertisementsReducer.potentialNumberOfViewsForAdvert);
    const meetUpStartDate = useSelector(state => state.AdvertisementsReducer.meetUpStartDate);
    const meetUpEndDate = useSelector(state => state.AdvertisementsReducer.meetUpEndDate);
    const fieldError = useSelector(state => state.SettingsReducer.fieldError);
    const country = useSelector(state => state.UserReducer.country);
    const regionOrState = useSelector(state => state.UserReducer.regionOrState);
    const locality = useSelector(state => state.UserReducer.locality);
    const zipCode = useSelector(state => state.UserReducer.zipCode);
    const checkedAdvert = useSelector(state => state.AdvertisementsReducer.checkedAdvert);
    const language = useSelector(state => state.UserReducer.language);
    const dispatch = useDispatch();

    const locality1Ref = useRef(null);
    const locality2Ref = useRef(null);
    const locality3Ref = useRef(null);

    const minDate = new Date(new Date().getTime() + 60 * 60 * 24 * 1000);
    const date = new Date();

    useEffect(() => {
        const minStartDate = new Date(new Date().getTime() + 61 * 60 * 24 * 1000);
        dispatch(getAdvertisementsAllowedCountries());
        dispatch(setStartDate(dt.format(minStartDate, 'YYYY-MM-DDTHH:mm')));

        return () => {
            dispatch(setFieldError(false));
            dispatch(clearAdvertisements());
        }
    }, []);

    useEffect(() => {
        dispatch(clearAdvertLocality());
        dispatch(setChangeLocality(false));
        if (advertType === 'MEET_UP') {
            dispatch(modifyAdvertisementCountry(country));
            dispatch(modifyAdvertisementRegion(regionOrState));
            dispatch(retrieveRegionsOfCountry());
            dispatch(retrieveLocalitiesOfRegion());
        }

    }, [advertType]);

    let countriesList = advertisementsCountries.map(item => (
        { label: item.name, value: item.code }
    ));

    let regionsList = regionsOfCountry.map(item => (
        { label: item, value: item }
    ));

    let localitiesList = localitiesOfRegion.map(item => (
        { label: item, value: item }
    ));

    let postalCodesList = postalCodesOfRegion.map(item => (
        { label: item, value: item }
    ));

    const advertTypes = [
        { label: StandardPublicationTypes.ClassifiedAd, value: 'CLASSIFIED_AD' },
        { label: StandardPublicationTypes.MeetUp, value: 'MEET_UP' },
        { label: StandardPublicationTypes.PromotionalAd, value: 'PROMOTIONAL_AD' }
    ];

    const daysOptions = [
        { label: '1', value: 1 },
        { label: '3', value: 3 },
        { label: '7', value: 7 },
        { label: '15', value: 15 },
        { label: '30', value: 30 }
    ];

    const daysPromotionalOptions = [
        { label: '1', value: 1 },
        { label: '3', value: 3 },
        { label: '7', value: 7 },
        { label: '15', value: 15 },
        { label: '30', value: 30 }
    ];

    function getBannerImage(files) {
        const _uploadedFiles = files.map(file => ({
            file,
        }));

        dispatch(asyncSendAdvertisementImage(_uploadedFiles[0]))
    }

    function _advertisementButton() {
        let textButton = advertType === 'PROMOTIONAL_AD' && !checkedAdvert ? StandardPublications.CalculatePrice : advertType === 'MEET_UP' && !checkedAdvert ? StandardPublications.ValidateData : Actions.Confirm;
        if (advertisementLoading) {
            return (
                <div className="spinner-container">
                    <Spinner size={16} color='#000' />
                </div>
            );
        }

        return <button className="btn" type="submit">{textButton}</button>;
    }

    function _asyncSendAdvertisements(event) {
        event.preventDefault();

        let message = Errors.User.FieldEmpty;

        if (advertType === 'PROMOTIONAL_AD') {
            if (title.length < 1 || link.length < 1 || productName.length < 1 || content.length < 2 || image.length < 2 || AdvertisementCountry.length < 1) {
                dispatch(setFieldError(true));
                dispatch(advertisementsError(message));
                setTimeout(function () { dispatch(advertisementsError('')); }, 3000);
            } else if (AdvertisementRegion === '' && (locality1 !== '' || locality2 !== '' || locality3 !== '')) {
                dispatch(setFieldError(true));
                dispatch(advertisementsError(message));
                setTimeout(function () { dispatch(advertisementsError('')); }, 3000);
            } else {
                dispatch(setFieldError(false));
                !checkedAdvert ? dispatch(asyncCalculatePrice()) : dispatch(asyncSendAdvertisement());
            }
        } else if (advertType === 'CLASSIFIED_AD') {
            if (title.length < 1 || productName.length < 1 || content.length < 2 || image.length < 2) {
                dispatch(setFieldError(true));
                dispatch(advertisementsError(message));
                setTimeout(function () { dispatch(advertisementsError('')); }, 3000);
            } else {
                dispatch(setFieldError(false));
                dispatch(asyncSendAdvertisement());
            }
        } else {
            if (title.length < 1 || productName.length < 1 || content.length < 2 || image.length < 2 || AdvertisementCountry.length < 1 || meetUpStartDate.length < 2 || meetUpEndDate.length < 2 || meetUpAddress.length < 2) {
                dispatch(setFieldError(true));
                dispatch(advertisementsError(message));
                setTimeout(function () { dispatch(advertisementsError('')); }, 3000);
            } else if (AdvertisementRegion === '' && (locality1 !== '' || locality2 !== '' || locality3 !== '')) {
                dispatch(setFieldError(true));
                dispatch(advertisementsError(message));
                setTimeout(function () { dispatch(advertisementsError('')); }, 3000);
            } else {
                dispatch(setFieldError(false));
                !checkedAdvert ? dispatch(asyncCalculatePrice()) : dispatch(asyncSendAdvertisement());
            }
        }
    }

    function dragColor(isDragActive, isDragReject) {
        if (fieldError && image === '') {
            return '#FF0000'
        } else if (isDragReject) {
            return '#FF0000'
        } else if (isDragActive) {
            return '#008000'
        } else {
            return '#DDD'
        }
    }

    function preLoadingImage() {
        if (loadingImage) {
            return <Spinner size={16} color='#000' />;
        } else if (image === '') {
            return <span>{StandardPublications.Image}</span>;
        } else {
            return <img src={image} />
        }
    }

    const customStylesPublicationTypes = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#999',
            minHeight: '42px',
            height: '42px',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '42px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '42px',
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: '#000'
        }),
    };

    const customStylesCountries = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: fieldError && AdvertisementCountry === '' ? 'red' : '#999',
            minHeight: '42px',
            height: '42px',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '42px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '42px',
        }),
    };

    const customStylesDays = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: fieldError && numberOfDays === 0 ? 'red' : '#DDD',
            minHeight: '42px',
            height: '42px',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '42px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '42px',
        }),
    };

    function _typeDescription() {
        if (advertType === 'CLASSIFIED_AD') {
            return <span>{ClassifiedAds.Description} <br /> <br /> {ClassifiedAds.Description2}</span>;
        } else if (advertType === 'MEET_UP') {
            return <span>{MeetUps.Description}  <br /> <br /> {MeetUps.Description2}</span>;
        } else {
            return <span>{StandardPublications.Description}</span>;
        }
    }

    return (
        <div id="advertisements-container">
            <Header back='/' screen={StandardPublications.Create} />
            <AdvertisementHeader />
            <div id="form-advertisements-container">
                <form onSubmit={_asyncSendAdvertisements}>
                    <div className="field-container">
                        <Select
                            id="publicationTypes"
                            styles={customStylesPublicationTypes}
                            placeholder={advertType === 'CLASSIFIED_AD' ? StandardPublicationTypes.ClassifiedAd : advertType === 'MEET_UP' ? StandardPublicationTypes.MeetUp : StandardPublicationTypes.PromotionalAd}
                            onChange={key => dispatch(modifyAdvertType(key.value))}
                            isOptionSelected={key => key.value === advertType}
                            options={advertTypes}
                            isSearchable={false}
                        />
                    </div>

                    <div className='publication-info'>
                        <div>
                            {_typeDescription()}
                            {advertType === 'MEET_UP' &&
                                <>
                                    <span className='message-icon-container' data-tip={MeetUps.AddressManagementMessage}><FiAlertCircle style={{ position: 'absolute', bottom: 1, left: 5 }} size={14} color='#000' /></span>
                                    <ReactTooltip className='tool-tip' />
                                </>
                            }
                        </div>

                        {advertType === 'CLASSIFIED_AD' && <div className='classified-ad-message-container'>
                            <span className='classified-ad-message'><FaLongArrowAltRight style={{ marginRight: 3 }} size={14} color="#000" /> {country} • {regionOrState} • {locality} • {zipCode}</span>
                            <span className='classified-ad-message' data-tip={ClassifiedAds.AddressManagementMessage}><FiAlertCircle style={{ marginLeft: 5, marginBottom: 1 }} size={14} color='#000' /></span>
                            <ReactTooltip className='tool-tip' />
                        </div>}
                    </div>

                    <div className='publication-spacing'>
                        <div className='first-spacing' />
                        <FaChevronDown size={22} color="#333" />
                        <div className='last-spacing' />
                    </div>

                    <div className="field-container">
                        <label htmlFor="advertisementsTitle">{StandardPublications.Reference}</label>
                        <input
                            id="advertisementsTitle"
                            style={{ borderColor: fieldError && title === '' ? 'red' : '#DDD' }}
                            value={title}
                            onChange={event => dispatch(modifyAdvertisementsTitle(event.target.value))}
                        />
                        <span className='advertisementsTitle-icon-container' data-tip={StandardPublications.Message.TitleForReference}><FiHelpCircle size={22} color='#000' /></span>
                        <ReactTooltip multiline className='tool-tip' />
                    </div>

                    {advertType === 'PROMOTIONAL_AD' && <div className="field-container">
                        <label htmlFor="advertisementsLink">{StandardPublications.Link}</label>
                        <input
                            id="advertisementsLink"
                            style={{ borderColor: fieldError && link === '' ? 'red' : '#DDD' }}
                            value={link}
                            onChange={event => dispatch(modifyAdvertisementsLink(event.target.value))}
                        />
                        <span className='advertisementsTitle-icon-container' data-tip={StandardPublications.Message.ChoseLinkOrText}><FiHelpCircle size={22} color='#000' /></span>
                        <ReactTooltip multiline className='tool-tip' />
                    </div>}

                    <div className="field-container">
                        <label htmlFor="advertisementsProductName">{advertType === 'CLASSIFIED_AD' ? ClassifiedAds.ClassifiedAdTitle : advertType === 'MEET_UP' ? MeetUps.MeetUpTitle : StandardPublications.Title}</label>
                        <input
                            id="advertisementsProductName"
                            style={{ borderColor: fieldError && productName === '' ? 'red' : '#DDD' }}
                            value={productName}
                            onChange={event => dispatch(modifyAdvertisementsProduct(event.target.value))}
                        />
                    </div>

                    <div className="field-container">
                        <label htmlFor="advertisementsContent">{StandardPublications.Text}</label>
                        <textarea
                            id="advertisementsContent"
                            style={{ borderColor: fieldError && content === '' ? 'red' : '#DDD' }}
                            value={content}
                            maxLength={500}
                            onChange={event => dispatch(modifyAdvertisementsDesc(event.target.value))}
                        />
                    </div>

                    {advertType === 'MEET_UP' && <div className="field-container">
                        <label htmlFor="advertisementsLocation">{MeetUps.MeetUpLocation}</label>
                        <input
                            id="advertisementsLocation"
                            style={{ borderColor: fieldError && meetUpAddress === '' ? 'red' : '#DDD' }}
                            value={meetUpAddress}
                            onChange={event => dispatch(modifyMeetUpAddress(event.target.value))}
                        />
                    </div>}

                    {advertType === 'MEET_UP' && <div className='meet-up-dates-container'>
                        <div className='meet-up-dates-content'>
                            <div className="field-container">
                                <label htmlFor="advertisementsStartDate">{MeetUps.MeetUpStartDate}</label>
                                <input
                                    id="meetUpStartDate"
                                    type="datetime-local"
                                    value={meetUpStartDate}
                                    min={dt.format(date, 'YYYY-MM-DDTHH:mm')}
                                    style={{ borderColor: fieldError && meetUpStartDate === '' ? 'red' : '#DDD' }}
                                    onChange={event => dispatch(setMeetUpStartDate(event.target.value))}
                                />
                            </div>
                        </div>

                        <div className='meet-up-dates-content'>
                            <div className="field-container">
                                <label htmlFor="advertisementsStartDate">{MeetUps.MeetUpEndDate}</label>
                                <input
                                    id="meetUpEndDate"
                                    type="datetime-local"
                                    value={meetUpEndDate}
                                    min={meetUpStartDate === '' ? null : dt.format(new Date(meetUpStartDate), 'YYYY-MM-DDTHH:mm')}
                                    style={{ borderColor: fieldError && meetUpEndDate === '' ? 'red' : '#DDD' }}
                                    onChange={event => dispatch(setMeetUpEndDate(event.target.value))}
                                />
                            </div>
                        </div>
                    </div>}

                    <div className="field-container">
                        <Dropzone accept='image/*' onDropAccepted={getBannerImage}>
                            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                <div
                                    {...getRootProps()}
                                    style={{ borderColor: dragColor(isDragActive, isDragReject) }}
                                    className={image === '' ? "dropzone" : "dropzone activited"}>
                                    <input {...getInputProps()} />
                                    {preLoadingImage()}
                                </div>
                            )}
                        </Dropzone>
                    </div>

                    {advertType !== 'CLASSIFIED_AD' && <>
                        <div className="field-container">
                            <label htmlFor="advertisementsCountries">{SubscriptionAndAccount.Country}</label>
                            <Select
                                id="advertisementsCountries"
                                styles={advertType === 'MEET_UP' ? customStylesPublicationTypes : customStylesCountries}
                                placeholder={advertType === 'MEET_UP' && countriesList.length > 0 ? countriesList.filter(item => item.value === country)[0].label : SubscriptionAndAccount.Country}
                                onChange={key => {
                                    dispatch(modifyAdvertisementCountry(key.value));
                                    dispatch(retrieveRegionsOfCountry());
                                }}
                                isOptionSelected={key => key.value === AdvertisementCountry}
                                isDisabled={advertType === 'MEET_UP'}
                                options={countriesList}
                            />
                        </div>

                        <div className="field-container">
                            <label htmlFor="advertisementsRegion">{SubscriptionAndAccount.Region + ` (${Errors.User.OptionalField})`}</label>
                            <Select
                                id="advertisementsRegion"
                                styles={advertType === 'MEET_UP' ? customStylesPublicationTypes : customStylesCountries}
                                placeholder={advertType === 'MEET_UP' && regionsList.length > 0 ? regionsList.filter(item => item.value === regionOrState)[0].label : SubscriptionAndAccount.Region}
                                noOptionsMessage={() => '...'}
                                onChange={key => {
                                    dispatch(modifyAdvertisementRegion(key.value));
                                    dispatch(retrieveLocalitiesOfRegion());
                                }}
                                isOptionSelected={key => key.value === AdvertisementRegion}
                                isDisabled={advertType === 'MEET_UP'}
                                options={regionsList}
                            />
                        </div>

                        <div className="advertisementsLocalityContainer">
                            <button style={{ backgroundColor: selectArea === 'City' ? '#92C9FF' : '#FFF', color: selectArea === 'City' ? '#FFF' : '#000' }} className="btn" type="button" onClick={() => {
                                dispatch(modifySelectArea('City'));
                                locality1Ref.current.select.clearValue();
                                locality2Ref.current.select.clearValue();
                                locality3Ref.current.select.clearValue();
                            }}>{SubscriptionAndAccount.City}</button>
                            <button style={{ backgroundColor: selectArea === 'ZipCode' ? '#92C9FF' : '#FFF', color: selectArea === 'ZipCode' ? '#FFF' : '#000' }} className="btn" type="button" onClick={() => {
                                dispatch(modifySelectArea('ZipCode'));
                                locality1Ref.current.select.clearValue();
                                locality2Ref.current.select.clearValue();
                                locality3Ref.current.select.clearValue();
                            }}>{SubscriptionAndAccount.ZipCode}</button>
                        </div>

                        <p>{selectArea === 'City' ? StandardPublications.TargetedCity : StandardPublications.TargetedZipCode}</p>

                        <div className="field-container">
                            <label htmlFor="advertisementsLocality1">{(selectArea === 'City' ? SubscriptionAndAccount.City : SubscriptionAndAccount.ZipCode) + ' 1'}</label>
                            <Select
                                ref={locality1Ref}
                                id="advertisementsLocality1"
                                name="form-field-name"
                                styles={customStylesCountries}
                                placeholder={(selectArea === 'City' ? SubscriptionAndAccount.City : SubscriptionAndAccount.ZipCode) + ' 1'}
                                noOptionsMessage={() => '...'}
                                onChange={key => key && dispatch(modifyAdvertisementCity1(key.value))}
                                options={selectArea === 'City' ? localitiesList : postalCodesList}
                            />
                        </div>

                        <div className="field-container">
                            <label htmlFor="advertisementsLocality2">{(selectArea === 'City' ? SubscriptionAndAccount.City : SubscriptionAndAccount.ZipCode) + ' 2'}</label>
                            <Select
                                ref={locality2Ref}
                                id="advertisementsLocality2"
                                styles={customStylesCountries}
                                placeholder={(selectArea === 'City' ? SubscriptionAndAccount.City : SubscriptionAndAccount.ZipCode) + ' 2'}
                                noOptionsMessage={() => '...'}
                                onChange={key => key && dispatch(modifyAdvertisementCity2(key.value))}
                                options={selectArea === 'City' ? localitiesList : postalCodesList}
                            />
                        </div>

                        <div className="field-container">
                            <label htmlFor="advertisementsLocality3">{(selectArea === 'City' ? SubscriptionAndAccount.City : SubscriptionAndAccount.ZipCode) + ' 3'}</label>
                            <Select
                                ref={locality3Ref}
                                id="advertisementsLocality3"
                                styles={customStylesCountries}
                                placeholder={(selectArea === 'City' ? SubscriptionAndAccount.City : SubscriptionAndAccount.ZipCode) + ' 3'}
                                noOptionsMessage={() => '...'}
                                onChange={key => key && dispatch(modifyAdvertisementCity3(key.value))}
                                options={selectArea === 'City' ? localitiesList : postalCodesList}
                            />
                        </div>
                    </>}

                    {advertType === 'CLASSIFIED_AD' && <div className="field-container row">
                        <label className="switch">
                            <input
                                id="publishAsSoonAsPossible"
                                type="checkbox"
                                onChange={() => dispatch(changePublishAsSoonAsPossible(!publishAsSoonAsPossible))}
                                value={publishAsSoonAsPossible}
                                checked={publishAsSoonAsPossible}
                            />
                            <span className="slider"></span>
                        </label>
                        <label htmlFor="publishAsSoonAsPossible" style={{ color: '#666' }}>{ClassifiedAds.PublishAsSoonAsPossible}</label>
                        <span className='publishAsSoonAsPossible-icon-container' data-tip={ClassifiedAds.PublishAsSoonAsPossibleExplanation}><FiAlertCircle size={22} color='#666' /></span>
                        <ReactTooltip multiline className='tool-tip' />
                    </div>}

                    {advertType === 'PROMOTIONAL_AD' && <div className='meet-up-dates-container'>
                        <div className='meet-up-dates-content'>
                            <div className="field-container">
                                <label htmlFor="advertisementsStartDate">{StandardPublications.StartDate}</label>
                                <input
                                    id="advertisementsStartDate"
                                    type="datetime-local"
                                    value={startDate}
                                    min={dt.format(minDate, 'YYYY-MM-DDTHH:mm')}
                                    style={{ borderColor: fieldError && startDate === '' ? 'red' : '#DDD' }}
                                    onChange={event => dispatch(setStartDate(event.target.value))}
                                />
                            </div>
                        </div>

                        <div className='meet-up-dates-content'>
                            <div className="field-container">
                                <label htmlFor="advertisementsDays">{StandardPublications.NumberOfDays}</label>
                                <Select
                                    id="advertisementsDays"
                                    styles={customStylesDays}
                                    placeholder={StandardPublications.NumberOfDays}
                                    onChange={key => dispatch(modifyAdvertisementsDays(key.value))}
                                    options={advertType === 'PROMOTIONAL_AD' ? daysPromotionalOptions : daysOptions}
                                />

                            </div>
                        </div>
                    </div>}



                    {((advertType === 'CLASSIFIED_AD' && !publishAsSoonAsPossible) || advertType === 'MEET_UP') && <div className="field-container">
                        <label htmlFor="advertisementsStartDate">{StandardPublications.StartDate}</label>
                        <input
                            id="advertisementsStartDate"
                            type="datetime-local"
                            value={startDate}
                            min={dt.format(minDate, 'YYYY-MM-DDTHH:mm')}
                            style={{ borderColor: fieldError && startDate === '' ? 'red' : '#DDD' }}
                            onChange={event => dispatch(setStartDate(event.target.value))}
                        />
                    </div>}

                    {checkedAdvert && advertType === 'PROMOTIONAL_AD' && <div className="value-container">
                        <label>{StandardPublications.AdvertPrice}:</label>&nbsp;
                        <label>{price}</label>&nbsp;
                        <label>{currency}</label>
                    </div>}

                    {checkedAdvert && <div className="value-container">
                        <label>{StandardPublications.PotentialNumberOfViews}:</label>&nbsp;
                        <label>{potentialNumberOfViewsForAdvert}</label>
                    </div>}

                    <span className="errorMessage">{advertisementErrorMessage}</span>
                    <span className="successMessage">{advertisementSuccessMessage}</span>

                    {_advertisementButton()}
                </form>
            </div>
        </div>
    );
}