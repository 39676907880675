export const modifyUsername = username => {
    return {
        type: 'MODIFY_USERNAME',
        payload: username
    }
}

export const modifyPassword = password => {
    return {
        type: 'MODIFY_PASSWORD',
        payload: password
    }
}

export const authenticate = (history, username, password) => {
    return {
        type: 'ASYNC_AUTHENTICATE',
        payload: { history, username, password }
    }
}

export const asyncForgotPassword = () => {
    return { type: 'ASYNC_FORGOT_PASSWORD' }
}

export const setErroLogin = message => {
    return {
        type: 'FAIL_LOGIN',
        payload: message
    }
}