const INITIAL_STATE = {
    email: '',
    password: '',
    confirmPassword: '',
    orgStatus: 0,
    addressCountry: '',
    addressRegion: '',
    addressCity: '',
    addressZipCod: '',
    organizationName: '',
    phoneNumber: '',
    imageURI: '',
    bio: '',
    firstName: '',
    lastName: '',
    birthDate: '',
    gender: 'U',
    openCalendar: false,
    showIdCardModal: false,
    registerImageUploading: false,
    uploadingIdCard: false,
    idCard: '',
    latitude: '',
    longitude: '',
    erroMessage: '',
    fieldError: false,
    registerButton: false,
    countriesWithoutPostalCode: []
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case 'MODIFY_REGISTER_EMAIL':
            return { ...state, email: action.payload }
        case 'MODIFY_REGISTER_PASSWORD':
            return { ...state, password: action.payload }
        case 'MODIFY_REGISTER_CONFIRM_PASSWORD':
            return { ...state, confirmPassword: action.payload }
        case 'MODIFY_REGISTER_ORGSTATUS':
            return { ...state, orgStatus: action.payload }
        case 'MODIFY_ADDRESS_COUNTRY':
            return { ...state, addressCountry: action.payload }
        case 'MODIFY_ADDRESS_REGION':
            return { ...state, addressRegion: action.payload }
        case 'MODIFY_ADDRESS_CITY':
            return { ...state, addressCity: action.payload }
        case 'MODIFY_ADDRESS_ZIPCODE':
            return { ...state, addressZipCod: action.payload }
        case 'MODIFY_REGISTER_ORGNAME':
            return { ...state, organizationName: action.payload }
        case 'MODIFY_REGISTER_PHONE':
            return { ...state, phoneNumber: action.payload }
        case 'MODIFY_REGISTER_BIO':
            return { ...state, bio: action.payload }
        case 'MODIFY_REGISTER_FIRST_NAME':
            return { ...state, firstName: action.payload }
        case 'MODIFY_REGISTER_LAST_NAME':
            return { ...state, lastName: action.payload }
        case 'MODIFY_REGISTER_GENDER':
            return { ...state, gender: action.payload }
        case 'MODIFY_ID_CARD':
            return { ...state, idCard: action.payload, uploadingIdCard: false }
        case 'ERROR_ID_CARD':
            return { ...state, uploadingIdCard: false }
        case 'SET_ID_CARD_MODAL':
            return { ...state, showIdCardModal: action.payload }
        case 'OPEN_REGISTER_CALENDAR':
            return { ...state, openCalendar: true }
        case 'CLOSE_REGISTER_CALENDAR':
            return { ...state, openCalendar: false }
        case 'SET_REGISTER_BIRTH_DATE':
            return { ...state, birthDate: action.payload, openCalendar: false }
        case 'SET_REGISTER_BIRTH_DATE_IOS':
            return { ...state, birthDate: action.payload }
        case 'SET_SUGEST_LOCATION':
            return { ...state, addressCountry: action.payload.countryCode, addressRegion: action.payload.regionOrState, addressCity: action.payload.locality, addressZipCod: action.payload.postalCode }
        case 'SET_COORDS':
            return { ...state, latitude: action.payload.lat, longitude: action.payload.lon }
        case 'SET_UPLOADED_IMAGE':
            return { ...state, imageURI: action.payload, registerImageUploading: false }
        case 'ASYNC_SEND_IMAGE':
            return { ...state, registerImageUploading: true }
        case 'REGISTER_IMAGE_ERROR':
            return { ...state, registerImageUploading: false }
        case 'MODIFY_ERROR_MESSAGE':
            return { ...state, erroMessage: action.payload }
        case 'ASYNC_SEND_IDCARD_IMAGE':
            return { ...state, uploadingIdCard: true }
        case 'SET_FIELD_ERROR':
            return { ...state, fieldError: action.payload }
        case 'ASYNC_CREATE_USER':
            return { ...state, registerButton: true }
        case 'ENABLE_REGISTER_BUTTON':
            return { ...state, registerButton: false }
        case 'SET_COUNTRIES_WHITHOUT_POSTALCODE':
            return { ...state, countriesWithoutPostalCode: action.payload }
        case 'CANCEL_REGISTER':
                return { ...state, email: '', password: '', confirmPassword: '', orgStatus: 0 }
        case 'LOG_OUT':
            return { ...state, email: '', password: '', confirmPassword: '', orgStatus: 0 }
        default:
            return state;
    }
}