export const modifyContactTitle = value => {
    return {
        type: 'MODIFY_CONTACT_TITLE',
        payload: value
    }
}

export const modifyContactContent = value => {
    return {
        type: 'MODIFY_CONTACT_CONTENT',
        payload: value
    }
}

export const modifyContactName = value => {
    return {
        type: 'MODIFY_CONTACT_NAME',
        payload: value
    }
}

export const modifyContactEmail = value => {
    return {
        type: 'MODIFY_CONTACT_EMAIL',
        payload: value
    }
}

export const modifyContactNumber = value => {
    return {
        type: 'MODIFY_CONTACT_NUMBER',
        payload: value
    }
}

export const setPasswordUpdateModal = status => {
    return {
        type: 'PASSWORD_UPDATE_MODAL',
        payload: status
    }
}

export const setErroContactMessage = message => {
    return {
        type: 'SET_ERRO_CONTACT_MESSAGE',
        payload: message
    }
}

export const asyncSendMessage = () => {
    return { type: 'ASYNC_SEND_MESSAGE' }
}

export const asyncSendMessageSite = () => {
    return { type: 'ASYNC_SEND_MESSAGE_SITE' }
}

export const ClearContactForm = () => {
    return { type: 'CLEAR_CONTACT_FORM' }
}

export const myAccountFilds = state => {
    return {
        type: 'MY_ACCOUNT_FILDS',
        payload: state
    }
}

export const setCurrentPassword = text => {
    return {
        type: 'SET_CURRENT_PASSWORD',
        payload: text
    }
}

export const setNewPassword = text => {
    return {
        type: 'SET_NEW_PASSWORD',
        payload: text
    }
}

export const setConfirmNewPassword = text => {
    return {
        type: 'SET_CONFIRM_NEW_PASSWORD',
        payload: text
    }
}

export const asyncUpdatePassword = () => {
    return { type: 'ASYNC_UPDATE_PASSWORD' }
}

export const setModalErrorMessage = message => {
    return {
        type: 'SET_MODAL_ERROR_MESSAGE',
        payload: message
    }
}

export const openDeleteAccountModal = status => {
    return {
        type: 'DELETE_ACCOUNT_MODAL',
        payload: status
    }
}

export const modifyInvitedFriendEmail = text => {
    return {
        type: 'SET_INVITED_FRIEND_EMAIL',
        payload: text
    }
}

export const modifyInvitedFriendEmail2 = text => {
    return {
        type: 'SET_INVITED_FRIEND_EMAIL2',
        payload: text
    }
}

export const modifyInvitedFriendEmail3 = text => {
    return {
        type: 'SET_INVITED_FRIEND_EMAIL3',
        payload: text
    }
}

export const asyncInviteFriends = () => {
    return { type: 'ASYNC_INVITE_FRIENDS' }
}

export const asyncCloseAccount = () => {
    return { type: 'ASYNC_CLOSE_ACCOUNT' }
}

export const updateInviteFields = number => {
    return {
        type: 'SET_UPDATE_INVITE_FIELDS',
        payload: number
    }
}

export const setFieldError = value => {
    return {
        type: 'SET_FIELD_SETTINGS_ERROR',
        payload: value
    }
}

export const setWebScreen = value => {
    return {
        type: 'SET_WEB_SCREEN',
        payload: value
    }
}