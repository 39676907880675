import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";

import Header from '../header';
import './styles.css';

export default function MeetUpParticipants() {
    const StandardPublications = useSelector(state => state.TextsReducer.StandardPublications);
    const PremiumPublications = useSelector(state => state.TextsReducer.PremiumPublications);
    const MeetUps = useSelector(state => state.TextsReducer.MeetUps);
    const location = useLocation();

    const { item, screen } = location.state;

    return (
        <div id="meet-up-participants-container">
            <Header back={screen === 'Advertisement' ? '/fullPublication' : '/fullPremiumPublication'} screenState={item} screen={screen === 'Advertisement' ? StandardPublications.MyStandardPublications : PremiumPublications.MyPremiumPublications} />
            <div>
                <p className='text-title' style={{ color: screen === 'Advertisement' ? '#5d1e03' : '#0091D0' }}>{screen === 'Advertisement' ? item.productName : item.title}</p>

                <div className='participants-container'>
                    <div className='participants-item-container'>
                        <p className='text-title'>{MeetUps.Attendees}: {item.meetUpData.numberOfConfirmedAttendances}</p>
                        {item.meetUpData.confirmedAttendances.map(item => <p key={item.attendingUserId}>{item.attendingUserFirstName} {item.attendingUserLastName}</p>)}
                    </div>
                    <div className='participants-item-container'>
                        <p className='text-title'>{MeetUps.MaybeAttendees}: {item.meetUpData.numberOfPossibleAttendances}</p>
                        {item.meetUpData.possibleAttendances.map(item => <p key={item.attendingUserId}>{item.attendingUserFirstName} {item.attendingUserLastName}</p>)}
                    </div>
                </div>
            </div>
        </div>
    );
};