import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";

import PrivacyPolicyDE from '../../../../assets/doc/PrivacyPolicy-DE.pdf';
import PrivacyPolicyEN from '../../../../assets/doc/PrivacyPolicy-EN.pdf';
import PrivacyPolicyFR from '../../../../assets/doc/PrivacyPolicy-FR.pdf';
import PrivacyPolicyNL from '../../../../assets/doc/PrivacyPolicy-NL.pdf';

import TermsOfUseDE from '../../../../assets/doc/TermsOfUse-DE.pdf';
import TermsOfUseEN from '../../../../assets/doc/TermsOfUse-EN.pdf';
import TermsOfUseFR from '../../../../assets/doc/TermsOfUse-FR.pdf';
import TermsOfUseNL from '../../../../assets/doc/TermsOfUse-NL.pdf';
import './styles.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ReadTerms() {
    const Terms = useSelector(state => state.TextsReducer.Terms);
    const { id } = useParams();

    const [numPages, setNumPages] = useState(null);
    const [value, setValue] = useState({ label: Terms.TermsText, value: 0 });
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    const documentOptions = {
        cMapUrl: 'cmaps/',
        cMapPacked: true,
    };

    const options = [
        { label: Terms.TermsText, value: 0 },
        { label: Terms.PrivacyPolicy, value: 1 }
    ];

    let privacyPolicy;
    let termsOfUse;

    if(id === 'DE') {
        privacyPolicy = PrivacyPolicyDE;
        termsOfUse = TermsOfUseDE;
    } else if(id === 'EN') {
        privacyPolicy = PrivacyPolicyEN;
        termsOfUse = TermsOfUseEN;
    } else if(id === 'FR') {
        privacyPolicy = PrivacyPolicyFR;
        termsOfUse = TermsOfUseFR;
    } else {
        privacyPolicy = PrivacyPolicyNL;
        termsOfUse = TermsOfUseNL;
    }

    const customStyles = {
        control: (provided, state) => ({
          ...provided,
          background: '#fff',
          borderColor: '#999',
          minHeight: '42px',
          height: '42px',
          boxShadow: state.isFocused ? null : null,
        }),
    
        valueContainer: (provided, state) => ({
          ...provided,
          height: '42px',
          padding: '0 6px'
        }),
    
        input: (provided, state) => ({
          ...provided,
          margin: '0px',
        }),
        indicatorSeparator: state => ({
          display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: '42px',
        }),
    };

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
    }

    return (
        <div id="read-terms-container">
            <div className="select-container">
                <Select
                    value={value}
                    styles={customStyles}
                    onChange={ value => setValue(value) }
                    options={options}
                />
            </div>
            <Document loading={" "} options={documentOptions} file={value.value === 0 ? termsOfUse : privacyPolicy} onLoadSuccess={onDocumentLoadSuccess}>
                {
                    Array.from(
                        new Array(numPages),
                        (el, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                loading={" "}
                                width={windowDimensions.width <= 1280 ? windowDimensions.width : windowDimensions.width * 80 / 100}
                            />
                        ),
                    )
                }  
            </Document>
        </div>
    )
}