export const getAllContent = reload => {
    return {
        type: 'GET_ALL_ACTIVITIES',
        payload: reload
    }
}

export const getAllAssists = (info, reload) => {
    return {
        type: 'GET_ALL_ASSISTS',
        payload: { info, reload }
    }
}

export const getAllRequests = (info, reload, first) => {
    return {
        type: 'GET_ALL_REQUESTS',
        payload: { info, reload, first }
    }
}

export const modifyRequestTitle = text => {
    return {
        type: 'MODIFY_REQUEST_TITLE',
        payload: text
    }
}

export const modifyRequestDesc = text => {
    return {
        type: 'MODIFY_REQUEST_DESC',
        payload: text
    }
}

export const setErroAssistMessage = message => {
    return {
        type: 'SET_ERRO_ASSIST_MESSAGE',
        payload: message
    }
}

export const asyncNewRequest = (info, navigation) => {
    return {
        type: 'ASYNC_NEW_REQUEST',
        payload: { info, navigation }
    }
}

export const ClearNewRequestForm = () => {
    return { type: 'CLEAR_NEW_REQUEST_FORM' }
}

export const setChangeFilter = state => {
    return {
        type: 'SET_CHANGE_FILTER',
        payload: state
    }
}

export const setProfileModal = state => {
    return {
        type: 'SET_PROFILE_MODAL',
        payload: state
    }
}

export const setAnnouncerModal = state => {
    return {
        type: 'SET_ANNOUNCER_MODAL',
        payload: state
    }
}

export const setItemModal = item => {
    return {
        type: 'ITEM_ASSIST_MODAL',
        payload: item
    }
}

export const setProfileAssistModal = state => {
    return {
        type: 'SET_PROFILE_ASSIST_MODAL',
        payload: state
    }
}

export const setEndAssistanceModal = state => {
    return {
        type: 'SET_END_ASSISTANCE_MODAL',
        payload: state
    }
}

export const setFieldError = value => {
    return {
        type: 'SET_FIELD_ASSIST_ERROR',
        payload: value
    }
}

export const reportAssist = targetObj => {
    return {
        type: 'REPORT_ASSIST',
        payload: targetObj
    }
}

export const setReportModal = state => {
    return {
        type: 'SET_REPORT_MODAL',
        payload: state
    }
}

export const setReportString = text => {
    return {
        type: 'SET_REPORT_STRING',
        payload: text
    }
}

export const asyncOfferAssistence = (info, data) => {
    return {
        type: 'ASYNC_OFFER_ASSISTENCE',
        payload: { info, data }
    }
}

export const acceptAssist = (info, offerId, itemRequestID, participants) => {
    return {
        type: 'ASYNC_ACCEPT_ASSISTENCE',
        payload: { info, offerId, itemRequestID, participants }
    }
}

export const refuseAssist = (info, offerId) => {
    return {
        type: 'ASYNC_REFUSE_ASSISTENCE',
        payload: { info, offerId }
    }
}

export const asyncEndAssistance = (info, assitanceID) => {
    return {
        type: 'ASYNC_END_ASSISTENCE',
        payload: { info, assitanceID }
    }
}

export const asyncCancelRequest = (info, requestId) => {
    return {
        type: 'ASYNC_CANCEL_REQUEST',
        payload: { info, requestId }
    }
}

export const setOpenCalendar = calendarType => {
    return {
        type: 'SET_OPEN_CALENDAR',
        payload: calendarType
    }
}

export const closeCalendar = () => {
    return { type: 'SET_CLOSE_CALENDAR' }
}

export const setEventDate = date => {
    return {
        type: 'SET_EVENT_DATE',
        payload: date
    }
}

export const setEventIosDate = date => {
    return {
        type: 'SET_EVENT_IOS_DATE',
        payload: date
    }
}

export const setChangeAnimationOffers = state => {
    return {
        type: 'SET_CHANGE_ANIMATION_OFFERS',
        payload: state
    }
}

export const deactivateAssistPopUp = () => {
    return {
        type: 'ACTIVE_ASSISTANCE',
        payload: 0
    }
}

export const setAssistanceNotification = status => {
    return {
        type: 'SET_ASSISTANCE_NOTIFICATION',
        payload: status
    }
}

export const announcementAttendMeetUp = (id, meetUpId, status) => {
    return {
        type: 'ANNOUNCEMENT_ATTEND_MEET_UP',
        payload: { id, meetUpId, status }
    }
}

export const voteForSurveyOption = (publicationId, surveyId, surveyOptionId) => {
    return {
        type: 'ANNOUNCEMET_VOTE_FOR_SURVEY',
        payload: { publicationId, surveyId, surveyOptionId }
    }
}

export const asyncVoteForSurveyOption = itemID => {
    return {
        type: 'ASYNC_ANNOUNCEMET_VOTE_FOR_SURVEY',
        payload: itemID
    }
}

export const modifyDurationOfPublication = value => {
    return {
        type: 'MODIFY_DURATION_OF_PUBLICATION',
        payload: value
    }
}

export const asyncSendRequestImage = image => {
    return {
        type: 'ASYNC_SEND_REQUEST_IMAGE',
        payload: image
    }
}