import React, { useEffect } from 'react';
import { Spinner } from 'react-activity';
import { useSelector, useDispatch } from 'react-redux';

import { getAnnouncements } from '../../../../redux/actions/announcementsActions';

import Header from '../../../components/header';
import ListEmptyComponent from '../../../components/listEmptyComponent';
import AnnouncementsItem from '../announcementsItem';
import './styles.css';

export default function MyAnnouncements() {
    const PremiumPublications = useSelector(state => state.TextsReducer.PremiumPublications);
    const allAnnouncements = useSelector(state => state.AnnouncementsReducer.allAnnouncements);
    const reloadingAnnouncements = useSelector(state => state.AnnouncementsReducer.reloadingAnnouncements);
    const dispatch = useDispatch();

    useEffect(() => {
        let reload = true;
        _getAnnouncements(reload);
    },[]);

    function _getAnnouncements(reload) {
        dispatch(getAnnouncements(reload));
    }

    function emptyList() {
        if(allAnnouncements.length === 0) {
            return <ListEmptyComponent message={PremiumPublications.None} />
        }
    }

    return (
        <div id="myAnnouncements-container">
            <Header back='/myActivities' screen={PremiumPublications.MyPremiumPublications} />
            {reloadingAnnouncements && allAnnouncements.length === 0 && <div className="spinner-container">
                <Spinner size={16} color='#000' />
            </div>}
            {emptyList()}
            <div className="list-container">
                {allAnnouncements && allAnnouncements.map(item => <AnnouncementsItem key={item.id.toString()} item={item} />)}
            </div>
        </div>
    )
}