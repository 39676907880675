import { takeEvery, takeLatest, put, call, select, delay } from 'redux-saga/effects';
import moment from 'moment';
import uuid from 'react-uuid'
import dt from 'date-and-time';
import api from '../services/api';

export default function* rootSaga() {
    yield takeEvery('GET_TEXT_LANGUAGE', asyncGetTextLanguage);
    yield takeEvery('GET_COUNTRIES', asyncGetCountries);
    yield takeEvery('ASYNC_RETRIEVE_MAX_NUMBER_OF_DAYS_FOR_REQUEST', retrieveMaxNumberOfBroadcastingDaysForRequest);
    yield takeEvery('GET_ADVERTISEMENTS_ALLOWED_COUNTRIES', asyncGetAdvertisementsAllowedCountries);
    yield takeEvery('RETRIEVE_REGIONS_OF_COUNTRY', asyncRetrieveRegionsOfCountry);
    yield takeEvery('RETRIEVE_LOCALITIES_OF_REGIONS', asyncRetrieveLocalitiesOfRegion);
    yield takeEvery('ASYNC_AUTHENTICATE', asyncAuth);
    yield takeEvery('ASYNC_FORGOT_PASSWORD', asyncForgotPassword);
    yield takeEvery('ASYNC_VERIFY_EMAIL', asyncVerifyEmail);
    yield takeEvery('ASYNC_GET_LOCATION', asyncGetLocation);
    yield takeEvery('ASYNC_GET_USER_LOCATION', asyncGetUserLocation);
    yield takeEvery('ASYNC_SEND_IMAGE', asyncSendImage);
    yield takeEvery('ASYNC_SEND_IDCARD_IMAGE', asyncSendIdCardImage);
    yield takeLatest('ASYNC_CREATE_USER', asyncCreateUser);
    yield takeEvery('SEND_REGISTRATION_TOKEN', asyncSendRegistrationToken);
    yield takeEvery('ASYNC_USER_DATA', asyncUserData);
    yield takeEvery('GET_ALL_REQUESTS', asyncGetAllRequests);
    yield takeEvery('GET_ALL_ASSISTS', asyncGetAllAssists);
    yield takeEvery('GET_OLD_REQUESTS', asyncGetOldRequests);
    yield takeEvery('GET_GOOD_DEEDS', asyncGetGoodDeeds);
    yield takeEvery('ASYNC_NEW_REQUEST', asyncNewRequest);
    yield takeEvery('ASYNC_SEND_MESSAGE', asyncSendMessage);
    yield takeEvery('ASYNC_SEND_MESSAGE_SITE', asyncSendMessageSite);
    yield takeEvery('ASYNC_SEND_USER_IMAGE', asyncSendUserImage);
    yield takeEvery('ASYNC_SEND_USER_IDCARD_IMAGE', asyncSendUserIdCardImage);
    yield takeEvery('ASYNC_UPDATE_USER', asyncUpdateUser);
    yield takeEvery('ASYNC_UPDATE_PASSWORD', asyncUpdatePassword);
    yield takeEvery('ASYNC_INVITE_FRIENDS', asyncInviteFriends);
    yield takeEvery('ASYNC_CLOSE_ACCOUNT', asyncCloseAccount);
    yield takeEvery('REPORT_ASSIST', asyncReportAssist);
    yield takeEvery('ASYNC_OFFER_ASSISTENCE', asyncOfferAssistence);
    yield takeEvery('ASYNC_ACCEPT_ASSISTENCE', asyncAcceptAssist);
    yield takeEvery('ASYNC_REFUSE_ASSISTENCE', asyncRefuseAssist);
    yield takeEvery('SEND_CHAT_MESSAGES', asyncSendChatMessages);
    yield takeEvery('ASYNC_END_ASSISTENCE', asyncEndAssistance);
    yield takeEvery('ASYNC_CANCEL_REQUEST', asyncCancelRequest);
    yield takeEvery('ASYNC_SEND_ANNOUNCEMENT_IMAGE', asyncSendAnnouncementImage);
    yield takeEvery('ASYNC_SEND_ANNOUNCEMENT', asyncSendAnnouncement);
    yield takeEvery('ANNOUNCEMENT_ATTEND_MEET_UP', asyncAnnouncementAttendMeetUp);
    yield takeEvery('ASYNC_ANNOUNCEMET_VOTE_FOR_SURVEY', asyncVoteForSurveyOption);
    yield takeEvery('GET_ANNOUNCEMENTS', asyncGetAnnouncements);
    yield takeEvery('ASYNC_SEND_ADVERTISEMENT_IMAGE', asyncSendAdvertisementsImage);
    yield takeEvery('ASYNC_CALCULATE_PRICE', asyncCalculatePrice);
    yield takeEvery('ASYNC_SEND_ADVERTISEMENT', asyncSendAdvertisement);
    yield takeEvery('GET_ADVERTISEMENTS', asyncGetAdvertisements);
    yield takeEvery('UPDATE_USER_LOCATION', asyncUpdateUserLocation);
    yield takeEvery('SET_NEW_CHAT_MESSAGES', setNewChatMessages);
    yield takeEvery('REMOVE_ASSIST_PARTICIPANT', asyncRemoveAssistParticipants);
    yield takeEvery('GET_POTENTIAL_NUMBER_OF_VIEWS_FOR_ANNOUNCEMENTS', asyncPotentialNumberOfViewsForAnnouncements);
    yield takeEvery('ASYNC_CANCEL_ANNOUNCEMENT', asyncCancelAnnouncement);
    yield takeEvery('ASYNC_CANCEL_ADVERTISEMENT', asyncCancelAdvertisement);
    yield takeEvery('ASYNC_LOG_OUT', asyncLogOut);
}

function* asyncGetTextLanguage(action) {
    try {
        let languageCode = action.payload;
        const id = yield select(state => state.UserReducer.id);
        const country = yield select(state => state.UserReducer.country);
        const token = yield select(state => state.UserReducer.token);

        if(languageCode.length === 2) {
            if(country.length > 0) {
                languageCode = languageCode + '_' + country;
            } else {
                const locale = yield call(api.get, 'https://ipapi.co/json/');
                languageCode = languageCode + '_' + locale.data.country_code;
            }
        } else if(languageCode.length > 5) {
            const locale = yield call(api.get, 'https://ipapi.co/json/');
            languageCode = languageCode.slice(0, 2) + '_' + locale.data.country_code;
        }

        const query = { languageCode }

        const updateLanguageQuery = { userId: id, languageCode: languageCode };

        const response = yield call(api.get, '/retrieveTextsForLanguage', { params: query, headers: {"Authorization" : `Bearer ${token}`} });
        const texts = response.data;

        yield put({ type: 'SET_TEXTS', payload: texts });

        if(token.length > 0) yield call(api.put, '/user/updateLanguage', null, { params: updateLanguageQuery, headers: { "Authorization": `Bearer ${token}` } });
    } catch(err) {
        console.log(err, 'Get Texts')
    }
}

function* asyncGetCountries(action) {
    try {
        const languageCode = action.payload;
        const token = yield select(state => state.UserReducer.token);

        const query = { languageCode }

        const response = yield call(api.get, '/retrieveAllCountries', { params: query, headers: { "Authorization": `Bearer ${token}` } });
        const countries = response.data;

        yield put({ type: 'SET_COUNTRIES', payload: countries });

    } catch (err) {
        console.log(err, 'Get Countries')
    }
}

function* retrieveMaxNumberOfBroadcastingDaysForRequest() {
    try {
        const token = yield select(state => state.UserReducer.token);

        const response = yield call(api.get, '/retrieveMaxNumberOfBroadcastingDaysForRequest', { headers: { "Authorization": `Bearer ${token}` } });
        const numberOfDays = response.data;

        yield put({ type: 'SET_NUMBER_OF_DAYS_FOR_REQUEST', payload: numberOfDays });

    } catch (err) {
        console.log(err, 'Retrieve Max Number Of Broadcasting Days For Request')
    }
}

function* asyncGetAdvertisementsAllowedCountries() {
    try {
        const language = yield select(state => state.UserReducer.language);
        const token = yield select(state => state.UserReducer.token);

        const query = { languageCode: language }

        const response = yield call(api.get, '/retrieveAllowedCountriesForAdvertisements', { params: query, headers: { "Authorization": `Bearer ${token}` } });
        const advertisementsCountries = response.data

        yield put({ type: 'SET_ADVERTISEMENTS_ALLOWED_COUNTRIES', payload: advertisementsCountries });

    } catch (err) {
        console.log(err, 'Get Advertisements Countries')
    }
}

function* asyncRetrieveRegionsOfCountry() {
    const Errors = yield select(state => state.TextsReducer.Errors);
    try {
        const AdvertisementCountry = yield select(state => state.AdvertisementsReducer.AdvertisementCountry);
        const token = yield select(state => state.UserReducer.token);

        const query = { country: AdvertisementCountry }

        if (AdvertisementCountry !== '') {
            const response = yield call(api.get, '/retrieveRegionsOfCountry', { params: query, headers: { "Authorization": `Bearer ${token}` } });
            const regionsOfCountry = response.data;

            yield put({ type: 'SET_REGIONS_OF_COUNTRY', payload: regionsOfCountry });
        } else {
            yield put({ type: 'SET_REGIONS_OF_COUNTRY', payload: [] });
            yield put({ type: 'SET_LOCALITIES_OF_REGIONS', payload: [] });
            yield put({ type: 'SET_POSTAL_CODES_OF_REGIONS', payload: [] });
        }
    } catch (err) {
        console.log(err, 'Get Regions of Country');

        let message;

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'ADVERTISEMENTS_ERROR_MESSAGE', payload: message });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'ADVERTISEMENTS_ERROR_MESSAGE', payload: message });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'ADVERTISEMENTS_ERROR_MESSAGE', payload: message });
        }

        yield delay(5000);
        yield put({ type: 'ADVERTISEMENTS_ERROR_MESSAGE', payload: '' });
    }
}

function* asyncRetrieveLocalitiesOfRegion() {
    const Errors = yield select(state => state.TextsReducer.Errors);
    try {
        const AdvertisementCountry = yield select(state => state.AdvertisementsReducer.AdvertisementCountry);
        const AdvertisementRegion = yield select(state => state.AdvertisementsReducer.AdvertisementRegion);
        const token = yield select(state => state.UserReducer.token);

        const query = { country: AdvertisementCountry, region: AdvertisementRegion }

        if (AdvertisementCountry !== '' && AdvertisementRegion !== '') {
            const response1 = yield call(api.get, '/retrieveLocalitiesOfRegion', { params: query, headers: { "Authorization": `Bearer ${token}` } });
            const localitiesOfRegion = response1.data;

            const response2 = yield call(api.get, '/retrievePostalCodesOfRegion', { params: query, headers: { "Authorization": `Bearer ${token}` } });
            const postalCodesOfRegion = response2.data;

            yield put({ type: 'SET_LOCALITIES_OF_REGIONS', payload: localitiesOfRegion });
            yield put({ type: 'SET_POSTAL_CODES_OF_REGIONS', payload: postalCodesOfRegion });
        } else {
            yield put({ type: 'SET_LOCALITIES_OF_REGIONS', payload: [] });
            yield put({ type: 'SET_POSTAL_CODES_OF_REGIONS', payload: [] });
        }
    } catch (err) {
        console.log(err, 'Get Localities of Region');

        let message;

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'ADVERTISEMENTS_ERROR_MESSAGE', payload: message });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'ADVERTISEMENTS_ERROR_MESSAGE', payload: message });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'ADVERTISEMENTS_ERROR_MESSAGE', payload: message });
        }

        yield delay(5000);
        yield put({ type: 'ADVERTISEMENTS_ERROR_MESSAGE', payload: '' });
    }
}

function* asyncAuth(action) {
    const Errors = yield select(state => state.TextsReducer.Errors);

    try {
        const { history, username, password } = action.payload;
        const objAuth = { username, password };
        const response = yield call(api.post, '/authenticate', objAuth);
        const user = response.data;

        if (user.enabled) {
            yield put({ type: 'SET_USER', payload: user });
            //yield put({ type: 'GET_ALL_REQUESTS', payload: { info, reload: true } });
            //yield put({ type: 'GET_ALL_ASSISTS', payload: { info, reload: true } });
            history.push('/myActivities');
        } else if (user.enabled === false) {
            yield put({ type: 'FAIL_LOGIN', payload: Errors.DisabledUser });
            yield delay(4000);
            yield put({ type: 'FINISH_ALERT' });
        } else {
            yield put({ type: 'FAIL_LOGIN', payload: Errors.User.IncorrectLogin });
            yield delay(4000);
            yield put({ type: 'FINISH_ALERT' });
        }
    } catch (err) {
        let message;

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'FAIL_LOGIN', payload: message });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'FAIL_LOGIN', payload: message });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'FAIL_LOGIN', payload: message });
        }

        yield delay(5000);
        yield put({ type: 'FINISH_ALERT' });
    }
}

function* asyncForgotPassword() {
    const Errors = yield select(state => state.TextsReducer.Errors);
    const Subscription = yield select(state => state.TextsReducer.Subscription);

    try {
        const username = yield select(state => state.AuthReducer.username);

        const query = { userEmail: username }

        const response = yield call(api.post, '/user/resetPassword', null, { params: query });
        const user = response.data;

        if (user.status) {
            yield put({ type: 'SUCCESS_LOGIN_MESSAGE', payload: Subscription.EmailSent });
            yield delay(4000);
            yield put({ type: 'SUCCESS_LOGIN_MESSAGE', payload: '' });
        } else {
            yield put({ type: 'FAIL_LOGIN', payload: Errors.User.EmailInvalid });
            yield delay(4000);
            yield put({ type: 'FINISH_ALERT' });
        }
    } catch (err) {
        console.log(err, 'Forgot Password')
        let message;

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'FAIL_LOGIN', payload: message });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'FAIL_LOGIN', payload: message });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'FAIL_LOGIN', payload: message });
        }

        yield delay(6000);
        yield put({ type: 'FINISH_ALERT' });
    }
}

function* asyncVerifyEmail(action) {
    const Errors = yield select(state => state.TextsReducer.Errors);

    try {
        const email = yield select(state => state.RegisterReducer.email);
        const navigation = action.payload;

        const query = { email }

        const response = yield call(api.get, '/checkEmailTaken', { params: query });
        const emailUse = response.data

        if (emailUse.taken) {
            yield put({ type: 'MODIFY_ERROR_MESSAGE', payload: Errors.User.EmailTaken });
        } else {
            navigation.navigate('Address');
        }

    } catch (err) {
        let message;

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'MODIFY_ERROR_MESSAGE', payload: message });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'MODIFY_ERROR_MESSAGE', payload: message });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'MODIFY_ERROR_MESSAGE', payload: message });
        }

        yield delay(6000);
        yield put({ type: 'MODIFY_ERROR_MESSAGE', payload: '' });

        console.log(err, 'checkEmailTaken')
    }
}

function* asyncGetLocation(action) {
    const Errors = yield select(state => state.TextsReducer.Errors);

    try {
        const language = yield select(state => state.UserReducer.language);
        const info = action.payload;

        const query = {
            lat: String(info.coords.latitude).replace(",", "."),
            lon: String(info.coords.longitude).replace(",", "."),
            languageCode: language
        }

        const response = yield call(api.get, '/retrieveLocationSuggested', { params: query });
        const location = response.data

        yield put({ type: 'SET_SUGEST_LOCATION', payload: location });
        yield put({ type: 'SET_COORDS', payload: query });

    } catch (err) {
        let message;

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'MODIFY_ERROR_MESSAGE', payload: message });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'MODIFY_ERROR_MESSAGE', payload: message });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'MODIFY_ERROR_MESSAGE', payload: message });
        }

        yield delay(6000);
        yield put({ type: 'MODIFY_ERROR_MESSAGE', payload: '' });

        console.log(err, 'location')
    }
}

function* asyncGetUserLocation(action) {
    const Errors = yield select(state => state.TextsReducer.Errors);

    try {
        const language = yield select(state => state.UserReducer.language);
        const info = action.payload;

        const query = {
            lat: String(info.coords.latitude).replace(",", "."),
            lon: String(info.coords.longitude).replace(",", "."),
            languageCode: language
        }

        const response = yield call(api.get, '/retrieveLocationSuggested', { params: query });
        const location = response.data

        yield put({ type: 'SET_SUGEST_USER_LOCATION', payload: location });

    } catch (err) {
        let message;

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });
        }

        yield delay(5000);
        yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: '' });

        console.log(err, 'user location')
    }
}

function* asyncSendImage(action) {
    const Errors = yield select(state => state.TextsReducer.Errors);

    try {
        const imageData = action.payload;
        let data = new FormData();

        var file = {
            uri: imageData.uri,
            type: imageData.type,
            name: imageData.fileName,
        }

        data.append('multipartFile', file);
        data.append('containerType', 'USER_PROFILES');

        const response = yield call(api.post, '/images/upload', data);
        const image = response.data

        yield put({ type: 'SET_UPLOADED_IMAGE', payload: image });
        yield put({ type: 'MODIFY_ERROR_MESSAGE', payload: '' });
    } catch (err) {
        console.log(err, 'Send Image')

        let message;

        yield put({ type: 'REGISTER_IMAGE_ERROR' });

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'MODIFY_ERROR_MESSAGE', payload: message });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'MODIFY_ERROR_MESSAGE', payload: message });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'MODIFY_ERROR_MESSAGE', payload: message });
        }

        yield delay(5000);
        yield put({ type: 'MODIFY_ERROR_MESSAGE', payload: '' });
    }
}

function* asyncSendIdCardImage(action) {
    const Errors = yield select(state => state.TextsReducer.Errors);

    try {
        const imageData = action.payload;
        let data = new FormData();

        var file = {
            uri: imageData.uri,
            type: imageData.type,
            name: imageData.fileName,
        }

        data.append('multipartFile', file);
        data.append('containerType', 'ID_CARDS');

        const response = yield call(api.post, '/images/upload', data);
        const image = response.data

        yield put({ type: 'MODIFY_ID_CARD', payload: image });
        yield put({ type: 'MODIFY_ERROR_MESSAGE', payload: '' });
    } catch (err) {
        console.log(err, 'Send ID Card Image')

        let message;

        yield put({ type: 'ERROR_ID_CARD' });

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'MODIFY_ERROR_MESSAGE', payload: message });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'MODIFY_ERROR_MESSAGE', payload: message });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'MODIFY_ERROR_MESSAGE', payload: message });
        }
        yield delay(5000);
        yield put({ type: 'MODIFY_ERROR_MESSAGE', payload: '' });
    }
}

function* asyncCreateUser(action) {
    const Errors = yield select(state => state.TextsReducer.Errors);

    try {
        const navigation = action.payload;
        const language = yield select(state => state.UserReducer.language);
        const data = yield select(state => state.RegisterReducer);

        let user = {
            firstName: data.orgStatus === 0 ? data.firstName : data.organizationName,
            lastName: data.lastName,
            photo: data.imageURI,
            gender: data.gender,
            birthDate: dt.format(data.birthDate, 'YYYY-MM-DD'),
            email: data.email,
            password: data.password,
            phoneNr: data.phoneNumber,
            status: "PENDING",
            zipCode: data.addressZipCod,
            locality: data.addressCity,
            regionOrState: data.addressRegion,
            country: data.addressCountry,
            shortBio: data.bio,
            role: data.orgStatus === 0 ? 'REGULAR' : 'ORGANIZATION',
            identityCard: data.idCard,
            latitude: data.latitude,
            longitude: data.longitude
        }

        const query = {
            languageCode: language
        }

        yield call(api.post, '/user/registration', user, { params: query });

        let auth = {
            username: data.email,
            password: data.password
        }

        navigation.navigate('RegisterConfirm');
        yield put({ type: 'ENABLE_REGISTER_BUTTON' });
        yield put({ type: 'MODIFY_ERROR_MESSAGE', payload: '' });
    } catch (err) {
        console.log(err, 'create user');

        let message;

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'MODIFY_ERROR_MESSAGE', payload: message });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'MODIFY_ERROR_MESSAGE', payload: message });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'MODIFY_ERROR_MESSAGE', payload: message });
        }

        yield delay(5000);
        yield put({ type: 'MODIFY_ERROR_MESSAGE', payload: '' });
    }
}

function* asyncSendRegistrationToken(action) {
    try {
        const id = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);
        const messageToken = action.payload;

        const query = {
            userId: id,
            userMessagingToken: messageToken
        }

        yield call(api.put, '/user/updateMessagingToken', null, { params: query, headers: { "Authorization": `Bearer ${token}` } });

    } catch (err) {
        console.log(err, 'Send Notification Token');
    }
}

function* asyncUserData() {
    try {
        const userID = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);

        const response = yield call(api.get, '/retrieveUserData', { params: { userId: userID }, headers: { "Authorization": `Bearer ${token}` } });
        const user = response.data

        yield put({ type: 'SET_USER_DATA', payload: user });

        const tokenResponse = yield call(api.get, '/refresh', { headers: { "Authorization": `Bearer ${token}` } });
        const newToken = tokenResponse.data.token

        yield put({ type: 'REFRESH_TOKEN', payload: newToken });

    } catch (err) {
        console.log(err, 'usarData')

        if (err.response.status === 401) {
            yield put({ type: 'ASYNC_LOG_OUT', payload: true });
        }
    }
}

function* asyncNewRequest(action) {
    const Errors = yield select(state => state.TextsReducer.Errors);

    try {
        const info = action.payload.info;
        const navigation = action.payload.navigation;

        const Request = yield select(state => state.TextsReducer.Request);
        const title = yield select(state => state.AssistReducer.title);
        const requestDesc = yield select(state => state.AssistReducer.requestDesc);
        const eventDate = yield select(state => state.AssistReducer.eventDate);
        const id = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);
        const date = new Date;
        date.toJSON = function () { return moment(this).format(); }
        eventDate.toJSON = function () { return moment(this).format(); }
        const fomatedDate = JSON.stringify(date);
        const fomatedEventDate = JSON.stringify(eventDate);
        const sendDate = JSON.parse(fomatedDate);
        const sendEventDate = JSON.parse(fomatedEventDate);

        const objAuth = {
            title,
            textContent: requestDesc,
            userRequestingId: id,
            timeZoneOffset: sendDate.substr(sendDate.length - 6, sendDate.length),
            occurrenceDateAndTime: sendEventDate.substr(0, sendEventDate.length - 6),
            latitude: parseFloat(String(info.coords.latitude).replace(",", ".")),
            longitude: parseFloat(String(info.coords.longitude).replace(",", "."))
        };

        yield call(api.post, '/createRequest', objAuth, { headers: { "Authorization": `Bearer ${token}` } });

        yield put({ type: 'GET_ALL_REQUESTS', payload: { info, reload: false, navigation } });
        yield put({ type: 'GET_ALL_ASSISTS', payload: { info, reload: false } });

    } catch (err) {
        console.log(err, 'New Request')

        let message;

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'SET_ERRO_ASSIST_MESSAGE', payload: message });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'SET_ERRO_ASSIST_MESSAGE', payload: message });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'SET_ERRO_ASSIST_MESSAGE', payload: message });
        }

        yield delay(5000);
        yield put({ type: 'SET_ERRO_ASSIST_MESSAGE', payload: '' });
    }
}

function* asyncSendMessage() {
    const Errors = yield select(state => state.TextsReducer.Errors);

    try {
        const id = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);
        const contactTitle = yield select(state => state.SettingsReducer.contactTitle);
        const contactContent = yield select(state => state.SettingsReducer.contactContent);
        const ContactUs = yield select(state => state.TextsReducer.ContactUs);

        const query = {
            userId: id,
        }

        const messageData = {
            title: contactTitle,
            content: contactContent
        }

        yield call(api.post, '/contactUs', messageData, { params: query, headers: { "Authorization": `Bearer ${token}` } });

        yield put({ type: 'SENT_MESSAGE', payload: ContactUs.MessageSuccessful });
        yield delay(5000);
        yield put({ type: 'SET_SUCCESS_CONTACT_MESSAGE', payload: '' });
    } catch (err) {
        console.log(err, 'contact')

        let message;

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });
        }

        yield delay(5000);
        yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: '' });
    }
}

function* asyncSendMessageSite() {
    const Errors = yield select(state => state.TextsReducer.Errors);

    try {
        const id = yield select(state => state.UserReducer.id);
        const contactName = yield select(state => state.SettingsReducer.contactName);
        const contactEmail = yield select(state => state.SettingsReducer.contactEmail);
        const contactNumber = yield select(state => state.SettingsReducer.contactNumber);
        const contactTitle = yield select(state => state.SettingsReducer.contactTitle);
        const contactContent = yield select(state => state.SettingsReducer.contactContent);
        const ContactUs = yield select(state => state.TextsReducer.ContactUs);

        const messageData = {
            senderName: contactName,
            senderEmail: contactEmail,
            senderPhoneNumber: contactNumber,
            title: contactTitle,
            content: contactContent
        }

        yield call(api.post, '/contactUsFromSite', messageData);

        yield put({ type: 'SENT_MESSAGE', payload: ContactUs.MessageSuccessful });
        yield delay(5000);
        yield put({ type: 'SET_SUCCESS_CONTACT_MESSAGE', payload: '' });
    } catch (err) {
        console.log(err, 'contact')

        let message;

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });
        }

        yield delay(5000);
        yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: '' });
    }
}

function* asyncGetAllRequests(action) {
    try {
        const id = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);
        const language = yield select(state => state.UserReducer.language);
        const requestFilter = yield select(state => state.UserReducer.requestFilter);
        const info = action.payload.info;
        const navigation = action.payload.navigation;
        const first = action.payload.first;
        const reload = false;

        const firstQuery = {
            userId: id,
            latitude: String(info.coords.latitude).replace(",", "."),
            longitude: String(info.coords.longitude).replace(",", "."),
            filter: requestFilter,
            languageCode: language
        }

        const query = {
            userId: id,
            latitude: String(info.coords.latitude).replace(",", "."),
            longitude: String(info.coords.longitude).replace(",", "."),
            filter: requestFilter
        }

        let requests;

        if (first) {
            const response = yield call(api.get, '/retrieveAreaInfoAndRequestContent', { params: firstQuery, headers: { "Authorization": `Bearer ${token}` } });
            const currentArea = response.data.currentAreaAndNumberOfUsers.area
            const numberOfUsers = response.data.currentAreaAndNumberOfUsers.numberOfUsers
            requests = response.data.requests;
            yield put({ type: 'SET_CURRENT_AREA_INFOS', payload: { currentArea, numberOfUsers } });
        } else {
            const response = yield call(api.get, '/retrieveRequestContent', { params: query, headers: { "Authorization": `Bearer ${token}` } });
            requests = response.data;
        }

        const advertsResponse = yield call(api.get, '/retrievePublicationContent', { params: { userId: id }, headers: { "Authorization": `Bearer ${token}` } });
        const adverts = advertsResponse.data;

        requests = requests.length !== 0 ? requests : [{ id: 'NOREQUESTS', contentType: 'NOREQUESTS' }];
        let allRequests;

        if (adverts.length !== 0) {
            allRequests = [{ id: 'EXPLANATION', contentType: 'EXPLANATION' }, ...requests, { id: 'HEADER', contentType: 'HEADER' }, ...adverts]
        } else {
            allRequests = [{ id: 'EXPLANATION', contentType: 'EXPLANATION' }, ...requests, { id: 'HEADER', contentType: 'HEADER' }, { id: 'NOADVERTS', contentType: 'NOADVERTS' }]
        }

        yield put({ type: 'SET_ALL_REQUESTS', payload: { allRequests, reload } });
        yield put({ type: 'CLEAN_MEET_UP_TEMPORARY_STATUS' });
        yield put({ type: 'CLEAN_SURVEY_TEMPORARY_STATUS' });

        if (navigation) {
            yield put({ type: 'REQUEST_CREATED', payload: '' });
            navigation.goBack();
        }
    } catch (err) {
        let allRequests = [{ id: 'EXPLANATION', contentType: 'EXPLANATION' }, { id: 'NOREQUESTS', contentType: 'NOREQUESTS' }, { id: 'HEADER', contentType: 'HEADER' }, { id: 'NOADVERTS', contentType: 'NOADVERTS' }];
        let reload = false;
        yield put({ type: 'CLEAN_MEET_UP_TEMPORARY_STATUS' });
        yield put({ type: 'CLEAN_SURVEY_TEMPORARY_STATUS' });

        yield put({ type: 'SET_ALL_REQUESTS', payload: { allRequests, reload } });
        console.log(err, 'get All Requests')
    }
}

function* asyncGetAllAssists(action) {
    try {
        const info = action.payload.info;
        const id = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);
        const reload = false;
        let allAssists;

        const query = {
            userId: id,
            latitude: String(info.coords.latitude).replace(",", "."),
            longitude: String(info.coords.longitude).replace(",", ".")
        }

        const response = yield call(api.get, '/retrieveCurrentAssistanceRequested', { params: query, headers: { "Authorization": `Bearer ${token}` } });
        let assists = response.data;

        const offerResponse = yield call(api.get, '/retrieveCurrentAssistanceProvided', { params: query, headers: { "Authorization": `Bearer ${token}` } });
        let offerAssists = offerResponse.data;

        assists = assists.length !== 0 ? assists : [{ request: { id: 'NO_ASSISTS1' }, contentType: 'NO_ASSISTS' }];

        if (offerAssists.length !== 0) {
            allAssists = [{ request: { id: 'HEADER_REQUEST' }, contentType: 'HEADER_REQUEST' }, ...assists, { request: { id: 'HEADER_OFFER' }, contentType: 'HEADER_OFFER' }, ...offerAssists]
        } else {
            allAssists = [{ request: { id: 'HEADER_REQUEST' }, contentType: 'HEADER_REQUEST' }, ...assists, { request: { id: 'HEADER_OFFER' }, contentType: 'HEADER_OFFER' }, { request: { id: 'NO_ASSISTS2' }, contentType: 'NO_ASSISTS' }]
        }

        yield put({ type: 'SET_ALL_ASSISTS', payload: { assists: allAssists, reload } });
    } catch (err) {
        let assists = [{ request: { id: 'HEADER_REQUEST' }, contentType: 'HEADER_REQUEST' }, { request: { id: 'NO_ASSISTS1' }, contentType: 'NO_ASSISTS' }, { request: { id: 'HEADER_OFFER' }, contentType: 'HEADER_OFFER' }, { request: { id: 'NO_ASSISTS2' }, contentType: 'NO_ASSISTS' }];
        let reload = false;

        yield put({ type: 'SET_ALL_ASSISTS', payload: { assists, reload } });
        console.log(err, 'get All Assists')
    }
}

function* asyncGetOldRequests() {
    try {
        const id = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);
        const reload = false;

        const query = { userId: id };

        const response = yield call(api.get, '/retrieveUserRequests', { params: query, headers: { "Authorization": `Bearer ${token}` } });
        const oldRequests = response.data

        yield put({ type: 'SET_OLD_REQUESTS', payload: { oldRequests, reload } });
    } catch (err) {
        let oldRequests = [];
        let reload = false;

        yield put({ type: 'SET_OLD_REQUESTS', payload: { oldRequests, reload } });
        console.log(err, 'oldRequests')
    }
}

function* asyncGetGoodDeeds() {
    try {
        const id = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);
        const reload = false;

        const query = { userId: id };

        const response = yield call(api.get, '/retrieveUserOffers', { params: query, headers: { "Authorization": `Bearer ${token}` } });
        const goodDeeds = response.data

        yield put({ type: 'SET_GOOD_DEEDS', payload: { goodDeeds, reload } });
    } catch (err) {
        let goodDeeds = [];
        let reload = false;

        yield put({ type: 'SET_GOOD_DEEDS', payload: { goodDeeds, reload } });
        console.log(err, 'goodDeeds')
    }
}

function* asyncSendUserImage(action) {
    const Errors = yield select(state => state.TextsReducer.Errors);

    try {
        const id = yield select(state => state.UserReducer.id);
        const imageData = action.payload;
        let data = new FormData();

        var file = {
            uri: imageData.uri,
            type: imageData.type,
            name: imageData.fileName,
        }

        data.append('multipartFile', file);
        data.append('containerType', 'USER_PROFILES');
        data.append('userId', id);

        const response = yield call(api.post, '/images/upload', data, { headers: { "content-type": 'multipart/form-data; boundary=---011000010111000001101001' } });
        const image = response.data

        yield put({ type: 'SET_UPLOADED_USER_IMAGE', payload: image });

        yield put({ type: 'ASYNC_UPDATE_USER', payload: 'none' });

    } catch (err) {
        let message;

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });
        }

        yield delay(5000);
        yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: '' });

        console.log(err, 'SettingsImage')
    }
}

function* asyncSendUserIdCardImage(action) {
    const Errors = yield select(state => state.TextsReducer.Errors);

    try {
        const id = yield select(state => state.UserReducer.id);
        const imageData = action.payload;
        let data = new FormData();

        var file = {
            uri: imageData.uri,
            type: imageData.type,
            name: imageData.fileName,
        }

        data.append('multipartFile', file);
        data.append('containerType', 'ID_CARDS');
        data.append('userId', id);

        const response = yield call(api.post, '/images/upload', data, { headers: { "content-type": 'multipart/form-data; boundary=---011000010111000001101001' } });
        const image = response.data

        yield put({ type: 'SET_USER_IDENTITY', payload: image });

    } catch (err) {
        let message;

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });
        }

        yield delay(5000);
        yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: '' });

        console.log(err, 'User Id Card Image')
    }
}

function* asyncSendAnnouncementImage(action) {
    const Errors = yield select(state => state.TextsReducer.Errors);

    try {
        const id = yield select(state => state.UserReducer.id);
        const file = action.payload;
        const data = new FormData();

        data.append('multipartFile', file.file);
        data.append('containerType', 'PREMIUM_PUBLICATIONS');
        data.append('userId', id);

        const response = yield call(api.post, '/images/upload', data, { headers: { "content-type": 'multipart/form-data; boundary=---011000010111000001101001' } });
        const image = response.data

        yield put({ type: 'SET_UPLOADED_ANNOUNCEMENT_IMAGE', payload: image });

    } catch (err) {
        let message;

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'ANNOUNCEMENTS_ERROR_MESSAGE', payload: message });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'ANNOUNCEMENTS_ERROR_MESSAGE', payload: message });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'ANNOUNCEMENTS_ERROR_MESSAGE', payload: message });
        }

        yield delay(5000);
        yield put({ type: 'ANNOUNCEMENTS_ERROR_MESSAGE', payload: '' });

        console.log(err, 'Announcement Image')
    }
}

function* asyncSendAdvertisementsImage(action) {
    const Errors = yield select(state => state.TextsReducer.Errors);

    try {
        const id = yield select(state => state.UserReducer.id);
        const file = action.payload;
        const data = new FormData();

        data.append('multipartFile', file.file);
        data.append('containerType', 'STANDARD_PUBLICATIONS');
        data.append('userId', id);

        const response = yield call(api.post, '/images/upload', data);
        const image = response.data

        yield put({ type: 'SET_UPLOADED_ADVERTISEMENT_IMAGE', payload: image });

    } catch (err) {
        let message;

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'ADVERTISEMENTS_ERROR_MESSAGE', payload: message });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'ADVERTISEMENTS_ERROR_MESSAGE', payload: message });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'ADVERTISEMENTS_ERROR_MESSAGE', payload: message });
        }

        yield delay(6000);
        yield put({ type: 'ADVERTISEMENTS_ERROR_MESSAGE', payload: '' });

        console.log(err, 'Advertisements Image')
    }
}

function* asyncUpdateUser(action) {
    const sendMessage = action.payload === 'message' ? true : false;
    const Errors = yield select(state => state.TextsReducer.Errors);

    try {
        const data = yield select(state => state.UserReducer);
        const SubscriptionAndAccount = yield select(state => state.TextsReducer.SubscriptionAndAccount);
        const birthDate = new Date(data.birthDate);

        let user = {
            firstName: data.firstName,
            lastName: data.lastName,
            photo: data.photo,
            gender: data.gender,
            birthDate: dt.format(birthDate, 'YYYY-MM-DD'),
            email: data.username,
            phoneNr: data.phoneNr,
            status: data.status,
            zipCode: data.zipCode,
            locality: data.locality,
            regionOrState: data.regionOrState,
            country: data.country,
            shortBio: data.shortBio,
            role: data.role,
            identityCard: data.identityCard,
            latitude: parseFloat(data.latitude),
            longitude: parseFloat(data.longitude),
        }

        const query = {
            userId: data.id,
            languageCode: data.language
        }

        if (user.firstName.length < 1 || user.lastName.length < 1 || user.photo.length < 1 || user.birthDate.length < 1 || user.email.length < 1 || user.phoneNr.length < 1 || user.zipCode.length < 1 || user.locality.length < 1 || user.regionOrState.length < 1 || user.country.length < 1 || user.shortBio.length < 4) {
            let message = Errors.User.FieldEmpty;
            yield put({ type: 'SET_FIELD_SETTINGS_ERROR', payload: true });
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });
            yield delay(5000);
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: '' });
        } else {
            yield put({ type: 'MY_ACCOUNT_FILDS', payload: false });
            yield put({ type: 'SET_FIELD_SETTINGS_ERROR', payload: false });
            yield call(api.put, '/user/updateAccount', user, { params: query, headers: { "Authorization": `Bearer ${data.token}` } });

            if (sendMessage) {
                let message = SubscriptionAndAccount.UpdateSuccessful;
                yield put({ type: 'SET_SUCCESS_CONTACT_MESSAGE', payload: message });
                yield delay(5000);
                yield put({ type: 'SET_SUCCESS_CONTACT_MESSAGE', payload: '' });
            }
        }

    } catch (err) {
        let message;

        yield put({ type: 'ASYNC_USER_DATA' });

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });
        }

        yield delay(5000);
        yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: '' });


        console.log(err, 'updateUser');
    }
}

function* asyncUpdatePassword() {
    const Errors = yield select(state => state.TextsReducer.Errors);

    try {
        const token = yield select(state => state.UserReducer.token);
        const id = yield select(state => state.UserReducer.id);
        const ChangePasswordOrEmail = yield select(state => state.TextsReducer.ChangePasswordOrEmail);
        const currentPassword = yield select(state => state.SettingsReducer.currentPassword);
        const newPassword = yield select(state => state.SettingsReducer.newPassword);

        let obj = {
            oldPassword: currentPassword,
            newPassword: newPassword
        }

        const query = { userId: id }

        const response = yield call(api.put, '/user/changePassword', obj, { params: query, headers: { "Authorization": `Bearer ${token}` } });

        if (response.data.status) {
            yield put({ type: 'PASSWORD_CHANGED' });

            let message = ChangePasswordOrEmail.NewPasswordSuccess;
            yield put({ type: 'SET_SUCCESS_CONTACT_MESSAGE', payload: message });
            yield delay(5000);
            yield put({ type: 'SET_SUCCESS_CONTACT_MESSAGE', payload: '' });
        } else {
            let message = Errors.User.PasswordNoMatch;
            yield put({ type: 'FAIL_CHANGE_PASSWORD' });
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });
            yield delay(5000);
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: '' });
        }


    } catch (err) {
        console.log(err, 'updatePassword')

        yield put({ type: 'FAIL_CHANGE_PASSWORD' });

        let message;

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });
        }

        yield delay(5000);
        yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: '' });
    }
}

function* asyncInviteFriends() {
    const Errors = yield select(state => state.TextsReducer.Errors);

    try {
        const id = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);
        const language = yield select(state => state.UserReducer.language);
        const invitedFriendEmail = yield select(state => state.SettingsReducer.invitedFriendEmail);
        const invitedFriendEmail2 = yield select(state => state.SettingsReducer.invitedFriendEmail2);
        const invitedFriendEmail3 = yield select(state => state.SettingsReducer.invitedFriendEmail3);
        const InviteFriends = yield select(state => state.TextsReducer.InviteFriends);

        const query = {
            userId: id,
            languageCode: language
        }

        let emailsList = [invitedFriendEmail, invitedFriendEmail2, invitedFriendEmail3];
        emailsList = emailsList.filter(item => item !== '');

        const data = {
            emailsList: emailsList
        }

        yield call(api.post, '/inviteFriends', emailsList, { params: query, headers: { "Authorization": `Bearer ${token}` } });

        yield put({ type: 'FINISH_INVITE_FRIENDS' });

        let message = InviteFriends.InviteSuccessful;
        yield put({ type: 'SET_SUCCESS_CONTACT_MESSAGE', payload: message });
        yield delay(4000);
        yield put({ type: 'SET_SUCCESS_CONTACT_MESSAGE', payload: '' });
    } catch (err) {
        yield put({ type: 'FINISH_INVITE_FRIENDS' });

        let message;

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: message });
        }

        yield delay(5000);
        yield put({ type: 'SET_ERRO_CONTACT_MESSAGE', payload: '' });

        console.log(err, 'Invite Friends')
    }
}

function* asyncCloseAccount() {
    try {
        const id = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);

        const query = { userId: id }

        yield call(api.put, '/user/deleteAccount', null, { params: query, headers: { "Authorization": `Bearer ${token}` } });

        yield put({ type: 'ASYNC_LOG_OUT' });
    } catch (err) {
        console.log(err, 'Delete Account');
    }
}

function* asyncReportAssist(action) {
    const Errors = yield select(state => state.TextsReducer.Errors);
    const Complaints = yield select(state => state.TextsReducer.Complaints);

    try {
        const targetObj = action.payload;
        const id = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);
        const reportString = yield select(state => state.AssistReducer.reportString);
        const date = new Date;
        date.toJSON = function () { return moment(this).format(); }
        const fomatedDate = JSON.stringify(date);
        const sendDate = JSON.parse(fomatedDate);

        const data = {
            complainingUserId: id,
            complaintReason: reportString,
            targetedUserId: targetObj.targetedUserId,
            targetedAssistanceId: targetObj.targetedAssistanceId,
            targetedAssistanceRequestId: targetObj.targetedAssistanceRequestId,
            targetedAdvertId: targetObj.targetedAdvertId,
            timeZoneOffset: sendDate.substr(sendDate.length - 6, sendDate.length)
        }

        yield call(api.post, '/report', data, { headers: { "Authorization": `Bearer ${token}` } });

        yield put({ type: 'REPORT_SENDED' });
        let message = Complaints.ReportConfirmText;
        yield put({ type: 'SET_SUCCESS_REPORT_MESSAGE', payload: message });
        yield delay(5000);
        yield put({ type: 'SET_SUCCESS_REPORT_MESSAGE', payload: '' });
    } catch (err) {
        console.log(err, 'report')

        yield put({ type: 'REPORT_SENDED' });

        let message;

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'SET_ERRO_REPORT_MESSAGE', payload: message });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'SET_ERRO_REPORT_MESSAGE', payload: message });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'SET_ERRO_REPORT_MESSAGE', payload: message });
        }

        yield delay(5000);
        yield put({ type: 'SET_ERRO_REPORT_MESSAGE', payload: '' });
    }
}

function* asyncOfferAssistence(action) {
    const Errors = yield select(state => state.TextsReducer.Errors);
    const data = action.payload.data;
    try {
        const info = action.payload.info;
        const AreaContent = yield select(state => state.TextsReducer.AreaContent);
        const id = yield select(state => state.UserReducer.id);
        const photo = yield select(state => state.UserReducer.photo);
        const token = yield select(state => state.UserReducer.token);
        const date = new Date;
        date.toJSON = function () { return moment(this).format(); }
        const fomatedDate = JSON.stringify(date);
        const sendDate = JSON.parse(fomatedDate);
        let message;

        if (id === data.userRequestingId) {
            message = AreaContent.OfferAssistanceError;
            yield put({ type: 'SET_ITEM_ERROR_MESSAGE', payload: { message, requestID: data.requestId } });
            yield delay(5000);
            yield put({ type: 'SET_ITEM_ERROR_MESSAGE', payload: { message: '', requestID: null } });
        } else if (data.currentUserOfferStatus !== 'NOT_SENT') {
            let message = AreaContent.OfferSent;
            yield put({ type: 'SET_ITEM_SUCCESS_MESSAGE', payload: { message, requestID: data.requestId } });
            yield delay(5000);
            yield put({ type: 'SET_ITEM_SUCCESS_MESSAGE', payload: { message: '', requestID: null } });
        } else if (photo.length < 2) {
            message = Errors.User.PhotoAbsent;
            yield put({ type: 'SET_ITEM_ERROR_MESSAGE', payload: { message, requestID: data.requestId } });
            yield delay(5000);
            yield put({ type: 'SET_ITEM_ERROR_MESSAGE', payload: { message: '', requestID: null } });
        } else {
            const query = {
                userId: id,
                latitude: String(info.coords.latitude).replace(",", "."),
                longitude: String(info.coords.longitude).replace(",", "."),
                requestId: data.requestId,
                offset: sendDate.substr(sendDate.length - 6, sendDate.length)
            }

            yield put({ type: 'OFFER_ASSISTENCE_SENT', payload: data.requestId });

            yield call(api.post, '/answerRequest', null, { params: query, headers: { "Authorization": `Bearer ${token}` } });

            message = AreaContent.OfferSent;

            yield put({ type: 'GET_ALL_REQUESTS', payload: { info, reload: false } });
        }
    } catch (err) {
        console.log(err, 'Offer Assistence')

        yield put({ type: 'OFFER_ASSISTENCE_SENT_ERROR' });

        let message;

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'SET_ITEM_ERROR_MESSAGE', payload: { message, requestID: data.requestId } });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'SET_ITEM_ERROR_MESSAGE', payload: { message, requestID: data.requestId } });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'SET_ITEM_ERROR_MESSAGE', payload: { message, requestID: data.requestId } });
        }

        yield delay(5000);
        yield put({ type: 'SET_ITEM_ERROR_MESSAGE', payload: { message: '', requestID: null } });
    }
}

function* asyncAcceptAssist(action) {
    try {
        const offerId = action.payload.offerId;
        const info = action.payload.info;
        const participants = action.payload.participants;
        const itemRequestID = action.payload.itemRequestID;
        const id = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);
        const date = new Date;
        date.toJSON = function () { return moment(this).format(); }
        const fomatedDate = JSON.stringify(date);
        const sendDate = JSON.parse(fomatedDate);

        const query = {
            userId: id,
            offerId: offerId,
            offset: sendDate.substr(sendDate.length - 6, sendDate.length)
        }

        yield put({ type: 'ACCEPT_ASSIST_LOADING', payload: itemRequestID });

        yield call(api.put, '/acceptAssistance', null, { params: query, headers: { "Authorization": `Bearer ${token}` } });

        yield put({ type: 'GET_ALL_ASSISTS', payload: { info, reload: false } });

        if (participants.length === 1) {
            yield put({ type: 'ACTIVE_ASSISTANCE', payload: itemRequestID });
        }
    } catch (err) {
        console.log(err, 'Accept Assist');

        yield put({ type: 'ACCEPT_ASSIST_LOADING_ERROR' });
    }
}


function* asyncRefuseAssist(action) {
    try {
        const offerId = action.payload.offerId;
        const info = action.payload.info;
        const id = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);

        const query = {
            userId: id,
            offerId: offerId
        }

        yield call(api.put, '/refuseAssistance', null, { params: query, headers: { "Authorization": `Bearer ${token}` } });

        yield put({ type: 'GET_ALL_ASSISTS', payload: { info, reload: false } });
    } catch (err) {
        console.log(err, 'Refuse Assist');
    }
}

function* asyncSendChatMessages(action) {
    try {
        let assistID = action.payload
        const allMessages = yield select(state => state.ChatReducer.messages);
        const chatMessage = yield select(state => state.ChatReducer.chatMessage);
        const id = yield select(state => state.UserReducer.id);
        const firstName = yield select(state => state.UserReducer.firstName);
        const lastName = yield select(state => state.UserReducer.lastName);
        const token = yield select(state => state.UserReducer.token);
        const date = new Date;
        date.toJSON = function () { return moment(this).format(); }
        const fomatedDate = JSON.stringify(date);

        if (chatMessage.length > 0) {
            const data = {
                text: chatMessage,
                author: `${firstName} ${lastName}`,
                authorId: id,
                creationDate: JSON.parse(fomatedDate),
                assistanceId: assistID.toString()
            };

            const message = {
                id: uuid(),
                text: chatMessage,
                author: `${firstName} ${lastName}`,
                authorId: id,
                creationDate: JSON.parse(fomatedDate),
                assistanceId: assistID.toString(),
                checked: true
            };

            yield put({ type: 'SET_CHAT_MESSAGES', payload: [...allMessages, message] });
            yield put({ type: 'CLEAR_CHAT_MESSAGE' });

            yield call(api.post, '/chat/message', data, { headers: { "Authorization": `Bearer ${token}` } });
        }

    } catch (err) {
        const allMessages = yield select(state => state.ChatReducer.messages);
        console.log(err, 'send chat')

        let messages = [...allMessages];

        messages[messages.length - 1].status = 'UPLOAD_FAILED';

        yield put({ type: 'SET_CHAT_MESSAGES', payload: messages });

        yield put({ type: 'CLEAR_CHAT_MESSAGE' });
    }
}

function* asyncEndAssistance(action) {
    try {
        const assitanceID = action.payload.assitanceID;
        const info = action.payload.info;
        const id = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);
        const allMessages = yield select(state => state.ChatReducer.messages);

        const date = new Date;
        date.toJSON = function () { return moment(this).format(); }
        const fomatedDate = JSON.stringify(date);
        const sendDate = JSON.parse(fomatedDate);

        const query = {
            userId: id,
            assistanceId: assitanceID,
            offset: sendDate.substr(sendDate.length - 6, sendDate.length)
        }

        yield call(api.put, '/concludeAssistance', null, { params: query, headers: { "Authorization": `Bearer ${token}` } });

        yield put({ type: 'GET_ALL_ASSISTS', payload: { info, reload: true } });

        const messages = allMessages.filter(message => (message.assistanceId !== assitanceID.toString()));

        yield put({ type: 'SET_CHAT_MESSAGES', payload: messages });
    } catch (err) {
        console.log(err, 'conclude Assistance');
    }
}

function* asyncCancelRequest(action) {
    try {
        const info = action.payload.info;
        const requestId = action.payload.requestId;
        const id = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);

        const date = new Date;
        date.toJSON = function () { return moment(this).format(); }
        const fomatedDate = JSON.stringify(date);
        const sendDate = JSON.parse(fomatedDate);

        const query = {
            userId: id,
            requestId: requestId,
            offset: sendDate.substr(sendDate.length - 6, sendDate.length)
        }

        yield call(api.put, '/cancelRequest', null, { params: query, headers: { "Authorization": `Bearer ${token}` } });

        yield put({ type: 'GET_ALL_ASSISTS', payload: { info, reload: false } });
    } catch (err) {
        console.log(err, 'Cancel Request');
    }
}

function* asyncSendAnnouncement() {
    const Errors = yield select(state => state.TextsReducer.Errors);
    const PremiumPublications = yield select(state => state.TextsReducer.PremiumPublications);

    try {
        const id = yield select(state => state.UserReducer.id);
        const photo = yield select(state => state.UserReducer.photo);
        const announcementType = yield select(state => state.AnnouncementsReducer.announcementType);
        const title = yield select(state => state.AnnouncementsReducer.title);
        const surveyTitle = yield select(state => state.AnnouncementsReducer.surveyTitle);
        const optionText = yield select(state => state.AnnouncementsReducer.optionText);
        const content = yield select(state => state.AnnouncementsReducer.content);
        const image = yield select(state => state.AnnouncementsReducer.image);
        const startDate = yield select(state => state.AnnouncementsReducer.startDate);
        const endDate = yield select(state => state.AnnouncementsReducer.endDate);
        const meetUpStartDate = yield select(state => state.AnnouncementsReducer.meetUpStartDate);
        const meetUpEndDate = yield select(state => state.AnnouncementsReducer.meetUpEndDate);
        const meetUpAddress = yield select(state => state.AnnouncementsReducer.meetUpAddress);
        const potentialNumberOfViewsForAnnouncements = yield select(state => state.AnnouncementsReducer.potentialNumberOfViewsForAnnouncements);
        const token = yield select(state => state.UserReducer.token);

        let date = new Date();
        date.toJSON = function () { return moment(this).format(); }
        const fomatedDate = JSON.stringify(date);
        const sendDate = JSON.parse(fomatedDate);

        let surveyOptions = [];
        optionText.forEach(item => {
            if (item !== '') {
                surveyOptions = [...surveyOptions, item];
            }
        });

        const query = { userId: id }

        const data = {
            contentType: "PREMIUM_PUBLICATION",
            title: title,
            text: content,
            image: image,
            timeZoneOffset: sendDate.substr(sendDate.length - 6, sendDate.length),
            startDate: startDate + ':00',
            endDate: endDate + ':00',
            potentialNumberOfViews: potentialNumberOfViewsForAnnouncements,
            type: announcementType,
            surveyData: {
                question: surveyTitle,
                options: surveyOptions.map((item, index) => { return { order: index, text: item } })
            },
            meetUpData: {
                startDate: meetUpStartDate.length > 2 ? meetUpStartDate + ':00' : '',
                endDate: meetUpEndDate.length > 2 ? meetUpEndDate + ':00' : '',
                address: meetUpAddress
            }
        };

        if (photo.length < 2) {
            yield put({ type: 'ANNOUNCEMENTS_ERROR_MESSAGE', payload: Errors.User.PhotoAbsent });
            yield delay(5000);
            yield put({ type: 'ANNOUNCEMENTS_ERROR_MESSAGE', payload: '' });
        } else {
            yield call(api.post, '/createPremiumPublication', data, { params: query, headers: { "Authorization": `Bearer ${token}` } });

            yield put({ type: 'SENDED_ANNOUNCEMENT' });
            yield put({ type: 'GET_ANNOUNCEMENTS', payload: false });

            yield put({ type: 'ANNOUNCEMENTS_SUCCESS_MESSAGE', payload: PremiumPublications.CreationSuccessful });
            yield delay(5000);
            yield put({ type: 'ANNOUNCEMENTS_SUCCESS_MESSAGE', payload: '' });
        }

    } catch (err) {
        console.log(err, 'Send Announcement')

        let message;

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'ANNOUNCEMENTS_ERROR_MESSAGE', payload: message });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'ANNOUNCEMENTS_ERROR_MESSAGE', payload: message });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'ANNOUNCEMENTS_ERROR_MESSAGE', payload: message });
        }

        yield delay(5000);
        yield put({ type: 'ANNOUNCEMENTS_ERROR_MESSAGE', payload: '' });
    }
}

function* asyncAnnouncementAttendMeetUp(action) {
    const Errors = yield select(state => state.TextsReducer.Errors);

    try {
        const id = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);
        const info = yield select(state => state.UserReducer.locationInfo);
        const publicationId = action.payload.id;
        const meetUpId = action.payload.meetUpId;
        const status = action.payload.status;
        const date = new Date;
        date.toJSON = function () { return moment(this).format(); }
        const fomatedDate = JSON.stringify(date);
        const sendDate = JSON.parse(fomatedDate);

        const query = { userId: id, publicationId: publicationId, meetUpId: meetUpId, status: status, offset: sendDate.substr(sendDate.length - 6, sendDate.length) }

        yield call(api.put, '/attendMeetUp', {}, { params: query, headers: { "Authorization": `Bearer ${token}` } });

        //yield put({ type: 'GET_ALL_REQUESTS', payload: { info, reload: false } });
        yield put({ type: 'GET_ADVERTISEMENTS', payload: false });
        yield put({ type: 'GET_ANNOUNCEMENTS', payload: false });

    } catch (err) {
        console.log(err, 'Announcement Attend Meet Up')

        yield put({ type: 'CLEAN_MEET_UP_TEMPORARY_STATUS' });

        let message;

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'SET_ITEM_ERROR_MESSAGE', payload: { message, requestID: action.payload.id } });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'SET_ITEM_ERROR_MESSAGE', payload: { message, requestID: action.payload.id } });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'SET_ITEM_ERROR_MESSAGE', payload: { message, requestID: action.payload.id } });
        }

        yield delay(5000);
        yield put({ type: 'SET_ITEM_ERROR_MESSAGE', payload: { message: '', requestID: null } });
    }
}

function* asyncVoteForSurveyOption(action) {
    const Errors = yield select(state => state.TextsReducer.Errors);

    try {
        const id = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);
        const info = yield select(state => state.UserReducer.locationInfo);
        const surveyTemporaryStatus = yield select(state => state.AssistReducer.surveyTemporaryStatus);

        const date = new Date;
        date.toJSON = function () { return moment(this).format(); }
        const fomatedDate = JSON.stringify(date);
        const sendDate = JSON.parse(fomatedDate);

        const query = { userId: id, publicationId: surveyTemporaryStatus.publicationId, surveyId: surveyTemporaryStatus.surveyId, surveyOptionId: surveyTemporaryStatus.optionId, offset: sendDate.substr(sendDate.length - 6, sendDate.length) }

        yield call(api.put, '/voteForSurveyOption', {}, { params: query, headers: { "Authorization": `Bearer ${token}` } });

        //yield put({ type: 'GET_ALL_REQUESTS', payload: { info, reload: false } });
        yield put({ type: 'GET_ADVERTISEMENTS', payload: false });
        yield put({ type: 'GET_ANNOUNCEMENTS', payload: false });

    } catch (err) {
        console.log(err, 'Announcement Vote For Survey')

        yield put({ type: 'CLEAN_SURVEY_TEMPORARY_STATUS' });

        let message;

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'SET_ITEM_ERROR_MESSAGE', payload: { message, requestID: action.payload } });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'SET_ITEM_ERROR_MESSAGE', payload: { message, requestID: action.payload } });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'SET_ITEM_ERROR_MESSAGE', payload: { message, requestID: action.payload } });
        }

        yield delay(5000);
        yield put({ type: 'SET_ITEM_ERROR_MESSAGE', payload: { message: '', requestID: null } });
    }
}

function* asyncCalculatePrice() {
    const Errors = yield select(state => state.TextsReducer.Errors);

    try {
        const id = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);
        const advertType = yield select(state => state.AdvertisementsReducer.advertType);
        const selectArea = yield select(state => state.AdvertisementsReducer.selectArea);
        const numberOfDays = yield select(state => state.AdvertisementsReducer.numberOfDays);
        const AdvertisementCountry = yield select(state => state.AdvertisementsReducer.AdvertisementCountry);
        const AdvertisementRegion = yield select(state => state.AdvertisementsReducer.AdvertisementRegion);
        const locality1 = yield select(state => state.AdvertisementsReducer.locality1);
        const locality2 = yield select(state => state.AdvertisementsReducer.locality2);
        const locality3 = yield select(state => state.AdvertisementsReducer.locality3);

        let type;
        let locations = [];

        if (AdvertisementRegion === '' && locality1 === '' && locality2 === '' && locality3 === '') {
            type = 'COUNTRY_CODE';
            locations = [
                {
                    zipCode: '',
                    locality: '',
                    regionOrState: '',
                    country: AdvertisementCountry
                }
            ];
        } else if (locality1 === '' && locality2 === '' && locality3 === '') {
            type = 'REGION_NAME';
            locations = [
                {
                    zipCode: '',
                    locality: '',
                    regionOrState: AdvertisementRegion,
                    country: AdvertisementCountry
                }
            ];
        } else {
            type = selectArea === 'City' ? 'LOCALITY_NAME' : 'POSTAL_CODE';

            if (locality1 !== '') {
                locations = [
                    ...locations,
                    {
                        zipCode: selectArea === 'City' ? '' : locality1,
                        locality: selectArea === 'City' ? locality1 : '',
                        regionOrState: AdvertisementRegion,
                        country: AdvertisementCountry
                    }
                ];
            }
            if (locality2 !== '') {
                locations = [
                    ...locations,
                    {
                        zipCode: selectArea === 'City' ? '' : locality2,
                        locality: selectArea === 'City' ? locality2 : '',
                        regionOrState: AdvertisementRegion,
                        country: AdvertisementCountry
                    }
                ];
            }
            if (locality3 !== '') {
                locations = [
                    ...locations,
                    {
                        zipCode: selectArea === 'City' ? '' : locality3,
                        locality: selectArea === 'City' ? locality3 : '',
                        regionOrState: AdvertisementRegion,
                        country: AdvertisementCountry
                    }
                ];
            }
        }

        const query1 = { userId: id, coverageType: type }
        const query2 = { userId: id }

        const data = {
            coverageType: type,
            locations: locations,
            numberOfDays: numberOfDays,
        };

        const responseNumber = yield call(api.post, '/calculatePotentialNumberOfViewsForAdvert', data.locations, { params: query1, headers: { "Authorization": `Bearer ${token}` } });
        const PotentialNumberOfViewsForAdvert = responseNumber.data;

        if (advertType === 'PROMOTIONAL_AD') {
            const response = yield call(api.post, '/calculateAdvertCost', data, { params: query2, headers: { "Authorization": `Bearer ${token}` } });
            const calculedPrice = response.data;

            yield put({ type: 'CALCULED_PRICE', payload: calculedPrice });
        }

        yield put({ type: 'CALCULED_NUMBER_OF_VIEWS_ADVERT', payload: PotentialNumberOfViewsForAdvert });
    } catch (err) {
        console.log(err, 'Calculate Cost Advert')

        let message;

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'ADVERTISEMENTS_ERROR_MESSAGE', payload: message });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'ADVERTISEMENTS_ERROR_MESSAGE', payload: message });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'ADVERTISEMENTS_ERROR_MESSAGE', payload: message });
        }

        yield delay(5000);
        yield put({ type: 'ADVERTISEMENTS_ERROR_MESSAGE', payload: '' });
    }
}

function* asyncSendAdvertisement() {
    const Errors = yield select(state => state.TextsReducer.Errors);
    const StandardPublications = yield select(state => state.TextsReducer.StandardPublications);

    try {
        const id = yield select(state => state.UserReducer.id);
        const photo = yield select(state => state.UserReducer.photo);
        const advertType = yield select(state => state.AdvertisementsReducer.advertType);
        const title = yield select(state => state.AdvertisementsReducer.title);
        const productName = yield select(state => state.AdvertisementsReducer.productName);
        const link = yield select(state => state.AdvertisementsReducer.link);
        const content = yield select(state => state.AdvertisementsReducer.content);
        const image = yield select(state => state.AdvertisementsReducer.image);
        const startDate = yield select(state => state.AdvertisementsReducer.startDate);
        const selectArea = yield select(state => state.AdvertisementsReducer.selectArea);
        const AdvertisementCountry = yield select(state => state.AdvertisementsReducer.AdvertisementCountry);
        const AdvertisementRegion = yield select(state => state.AdvertisementsReducer.AdvertisementRegion);
        const numberOfDays = yield select(state => state.AdvertisementsReducer.numberOfDays);
        const price = yield select(state => state.AdvertisementsReducer.price);
        const currency = yield select(state => state.AdvertisementsReducer.currency);
        const locality1 = yield select(state => state.AdvertisementsReducer.locality1);
        const locality2 = yield select(state => state.AdvertisementsReducer.locality2);
        const locality3 = yield select(state => state.AdvertisementsReducer.locality3);
        const publishAsSoonAsPossible = yield select(state => state.AdvertisementsReducer.publishAsSoonAsPossible);
        const potentialNumberOfViewsForAdvert = yield select(state => state.AdvertisementsReducer.potentialNumberOfViewsForAdvert);
        const meetUpStartDate = yield select(state => state.AdvertisementsReducer.meetUpStartDate);
        const meetUpEndDate = yield select(state => state.AdvertisementsReducer.meetUpEndDate);
        const meetUpAddress = yield select(state => state.AdvertisementsReducer.meetUpAddress);
        const token = yield select(state => state.UserReducer.token);

        let date = new Date();
        date.toJSON = function () { return moment(this).format(); }

        const fomatedDate = JSON.stringify(date);
        const sendDate = JSON.parse(fomatedDate);

        let type;
        let locations = [];

        if (AdvertisementRegion === '' && locality1 === '' && locality2 === '' && locality3 === '') {
            type = 'COUNTRY_CODE';
            locations = [
                {
                    zipCode: '',
                    locality: '',
                    regionOrState: '',
                    country: AdvertisementCountry
                }
            ];
        } else if (locality1 === '' && locality2 === '' && locality3 === '') {
            type = 'REGION_NAME';
            locations = [
                {
                    zipCode: '',
                    locality: '',
                    regionOrState: AdvertisementRegion,
                    country: AdvertisementCountry
                }
            ];
        } else {
            type = selectArea === 'City' ? 'LOCALITY_NAME' : 'POSTAL_CODE';

            if (locality1 !== '') {
                locations = [
                    ...locations,
                    {
                        zipCode: selectArea === 'City' ? '' : locality1,
                        locality: selectArea === 'City' ? locality1 : '',
                        regionOrState: AdvertisementRegion,
                        country: AdvertisementCountry
                    }
                ];
            }
            if (locality2 !== '') {
                locations = [
                    ...locations,
                    {
                        zipCode: selectArea === 'City' ? '' : locality2,
                        locality: selectArea === 'City' ? locality2 : '',
                        regionOrState: AdvertisementRegion,
                        country: AdvertisementCountry
                    }
                ];
            }
            if (locality3 !== '') {
                locations = [
                    ...locations,
                    {
                        zipCode: selectArea === 'City' ? '' : locality3,
                        locality: selectArea === 'City' ? locality3 : '',
                        regionOrState: AdvertisementRegion,
                        country: AdvertisementCountry
                    }
                ];
            }
        }

        const query = { userId: id }

        const data = {
            contentType: "STANDARD_PUBLICATION",
            productName: productName,
            link: link,
            title: title,
            textContent: content,
            image: image,
            timeZoneOffset: sendDate.substr(sendDate.length - 6, sendDate.length),
            startDate: startDate + ':00',
            totalCost: advertType === 'PROMOTIONAL_AD' ? price : 0,
            currency: advertType === 'PROMOTIONAL_AD' ? currency : 'NONE',
            numberOfDaysRequested: numberOfDays,
            potentialNumberOfViews: potentialNumberOfViewsForAdvert,
            coverageType: type,
            locations: advertType === 'CLASSIFIED_AD' ? [] : locations,
            type: advertType,
            meetUpData: {
                startDate: meetUpStartDate.length > 2 ? meetUpStartDate + ':00' : '',
                endDate: meetUpEndDate.length > 2 ? meetUpEndDate + ':00' : '',
                address: meetUpAddress
            },
            publishAsSoonAsPossible: advertType === 'CLASSIFIED_AD' ? publishAsSoonAsPossible : false,
        };

        console.log(data)

        if (photo.length < 2) {
            yield put({ type: 'ADVERTISEMENTS_ERROR_MESSAGE', payload: Errors.User.PhotoAbsent });
            yield delay(5000);
            yield put({ type: 'ADVERTISEMENTS_ERROR_MESSAGE', payload: '' });
        } else {
            yield call(api.post, '/createStandardPublication', data, { params: query, headers: { "Authorization": `Bearer ${token}` } });

            yield put({ type: 'SENDED_ADVERTISEMENT' });
            yield put({ type: 'GET_ADVERTISEMENTS', payload: false });
            yield put({ type: 'ADVERTISEMENTS_SUCCESS_MESSAGE', payload: StandardPublications.CreationSuccessful });
            yield delay(5000);
            yield put({ type: 'ADVERTISEMENTS_SUCCESS_MESSAGE', payload: '' });
        }

    } catch (err) {
        console.log(err, 'Send Advert')

        let message;

        if (err.message === 'Network Error') {
            message = Errors.NoInternet
            yield put({ type: 'ADVERTISEMENTS_ERROR_MESSAGE', payload: message });

        } else if (err.response === undefined) {
            message = Errors.ServiceUnavailable
            yield put({ type: 'ADVERTISEMENTS_ERROR_MESSAGE', payload: message });

        } else {
            message = Errors.UnknownError
            yield put({ type: 'ADVERTISEMENTS_ERROR_MESSAGE', payload: message });
        }

        yield delay(5000);
        yield put({ type: 'ADVERTISEMENTS_ERROR_MESSAGE', payload: '' });
    }
}

function* asyncGetAnnouncements() {
    try {
        const id = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);
        const reload = false;

        const query = { userId: id };

        const response = yield call(api.get, '/retrieveUserPremiumPublications', { params: query, headers: { "Authorization": `Bearer ${token}` } });
        const allAnnouncements = response.data

        yield put({ type: 'SET_ANNOUNCEMENTS', payload: { allAnnouncements, reload } });
    } catch (err) {
        let allAnnouncements = [];
        let reload = false;

        yield put({ type: 'SET_ANNOUNCEMENTS', payload: { allAnnouncements, reload } });

        console.log(err, 'Get Announcements');
    }
}

function* asyncGetAdvertisements() {
    try {
        const id = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);
        const reload = false;

        const query = { userId: id };

        const response = yield call(api.get, '/retrieveUserStandardPublications', { params: query, headers: { "Authorization": `Bearer ${token}` } });
        const allAdvertisements = response.data

        yield put({ type: 'SET_ADVERTISEMENTS', payload: { allAdvertisements, reload } });
    } catch (err) {
        let allAdvertisements = [];
        let reload = false;

        yield put({ type: 'SET_ADVERTISEMENTS', payload: { allAdvertisements, reload } });

        console.log(err, 'Get Advertisements');
    }
}

function* asyncUpdateUserLocation(action) {
    try {
        const info = action.payload;
        const id = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);
        const requestFilter = yield select(state => state.UserReducer.requestFilter);

        const query = {
            userId: id,
            latitude: parseFloat(String(info.coords.latitude).replace(",", ".")),
            longitude: parseFloat(String(info.coords.longitude).replace(",", ".")),
            filter: requestFilter
        }

        yield call(api.put, '/user/updateLocationData', null, { params: query, headers: { "Authorization": `Bearer ${token}` } });
    } catch (err) {
        console.log(err, 'Update User Location');
    }
}

function* setNewChatMessages(action) {
    let message = action.payload;
    const allMessages = yield select(state => state.ChatReducer.messages);
    const assistActivate = yield select(state => state.ChatReducer.assistActivate);

    if (message.assistanceId == assistActivate) {
        message.checked = true;
    } else {
        message.checked = false;
    }

    yield put({ type: 'SET_CHAT_MESSAGES', payload: [...allMessages, message] });
}

function* asyncRemoveAssistParticipants(action) {
    try {
        const info = action.payload.info;
        const participant = action.payload.participant;
        const assistID = action.payload.assistID;
        const navigation = action.payload.navigation;
        const id = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);

        const query = {
            userRequestingId: id,
            userToBeRemovedId: participant,
            assistanceId: assistID
        }

        yield call(api.put, '/removeFromAssistance', null, { params: query, headers: { "Authorization": `Bearer ${token}` } });

        yield put({ type: 'GET_ALL_ASSISTS', payload: { info, reload: true } });
        navigation.navigate("AssistenceTab");
    } catch (err) {
        console.log(err, 'Removes user from assistance');
    }
}

function* asyncPotentialNumberOfViewsForAnnouncements() {
    try {
        const id = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);

        const query = { userId: id }

        const response = yield call(api.post, '/calculatePotentialNumberOfViewsForAnnouncement', null, { params: query, headers: { "Authorization": `Bearer ${token}` } });

        const number = response.data

        yield put({ type: 'SET_POTENTIAL_NUMBER_OF_VIEWS_FOR_ANNOUNCEMENTS', payload: number });

    } catch (err) {
        console.log(err, 'Potential Number Of Views For Announcements')
    }
}

function* asyncCancelAnnouncement(action) {
    try {
        const id = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);
        const announcementId = action.payload

        const query = { userId: id, publicationId: announcementId }

        yield call(api.put, '/cancelPublication', null, { params: query, headers: { "Authorization": `Bearer ${token}` } });

        yield put({ type: 'ANNOUNCEMENT_CANCELLED', payload: true });
        yield put({ type: 'GET_ANNOUNCEMENTS', payload: true });

    } catch (err) {
        console.log(err, 'Cancel Announcement')
    }
}

function* asyncCancelAdvertisement(action) {
    try {
        const id = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);
        const advertId = action.payload

        const query = { userId: id, publicationId: advertId }

        yield call(api.put, '/cancelPublication', null, { params: query, headers: { "Authorization": `Bearer ${token}` } });

        yield put({ type: 'ADVERTISEMENT_CANCELLED', payload: true });
        yield put({ type: 'GET_ADVERTISEMENTS', payload: true });

    } catch (err) {
        console.log(err, 'Cancel Advertisement')
    }
}

function* asyncLogOut(forceLogOut) {
    if (forceLogOut) {
        yield put({ type: 'LOG_OUT' });
        return
    }

    try {
        const id = yield select(state => state.UserReducer.id);
        const token = yield select(state => state.UserReducer.token);

        const query = { userId: id }

        yield call(api.put, '/user/logOutMessagingToken', null, { params: query, headers: { "Authorization": `Bearer ${token}` } });

        yield put({ type: 'LOG_OUT' });

    } catch (err) {
        console.log(err, 'log out')
    }
}