import React, { useEffect } from 'react';
import { Spinner } from 'react-activity';
import { useSelector, useDispatch } from 'react-redux';

import { getAdvertisements } from '../../../../redux/actions/advertisementsActions';

import Header from '../../../components/header';
import ListEmptyComponent from '../../../components/listEmptyComponent';
import AdvertisementsItem from '../advertisementsItem';
import './styles.css';

export default function MyAdvertisements() {
    const StandardPublications = useSelector(state => state.TextsReducer.StandardPublications);
    const allAdvertisements = useSelector(state => state.AdvertisementsReducer.allAdvertisements);
    const reloadingAdvertisements = useSelector(state => state.AdvertisementsReducer.reloadingAdvertisements);
    const dispatch = useDispatch();

    useEffect(() => {
        let reload = true;
        _getAdvertisements(reload);
    },[]);

    function _getAdvertisements(reload) {
        dispatch(getAdvertisements(reload));
    }

    function emptyList() {
        if(allAdvertisements.length === 0) {
            return <ListEmptyComponent message={StandardPublications.None} />
        }
    }

    return (
        <div id="myAdvertisements-container">
            <Header back='/myActivities' screen={StandardPublications.MyStandardPublications} />
            {reloadingAdvertisements && allAdvertisements.length === 0 && <div className="spinner-container">
                <Spinner size={16} color='#000' />
            </div>}
            {emptyList()}
            <div className="list-container">
                {allAdvertisements && allAdvertisements.map(item => <AdvertisementsItem key={item.id.toString()} item={item} />)}
            </div>
        </div>
    )
}