const INITIAL_STATE = {
    screen: '',
    params: {},
    notificationScreen: '',
    notification: {},
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case 'SET_ROUTE_SCREEN':
            return { ...state, screen: action.payload.screen, params: action.payload.params }
        case 'SET_ROUTE_NOTIFICATION':
            return { ...state, notificationScreen: action.payload.screen, notification: action.payload.params }
        default:
            return state;
    }
}