import React from 'react';
import { BiChevronLeft } from "react-icons/bi";
import { useHistory } from "react-router-dom";

import './styles.css';

export default function Header({ back, screenState, screen }) {
    const history = useHistory();
    return (    
        <div id="header-container">
            <button id="header-div" type="button" onClick={()=> history.push({ pathname: back, state: screenState })}>
                <BiChevronLeft size={50} color="#FFF" />
            </button>

            <div id="title-header">
                <p>{screen}</p>
            </div>
        
            <div id="header-div" />
        </div>
    )
}