import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import Header from '../../../components/header';
import logo from '../../../../assets/assistLogo.png';
import './styles.css';

export default function TermsOfUse() {
    const Terms = useSelector(state => state.TextsReducer.Terms);
    const history = useHistory();

    return (
        <>
        <Header back='/login' screen={Terms.TermsText} />
        <div id="terms-container">
            <img src={logo} alt="logo" />
            
            <div className="presentation-container">
                <p className="terms-title">{Terms.TermsText}</p>

                <p>{Terms.TermsLanguage}</p>
            </div>

            <div className="terms-button-container">
                <button className="terms-button" type="button" onClick={ () => history.push('/readTerms/FR') }>FR</button>
                <button className="terms-button" type="button" onClick={ () => history.push('/readTerms/NL') }>NL</button>
                <button className="terms-button" type="button" onClick={ () => history.push('/readTerms/DE') }>DE</button>
                <button className="terms-button" type="button" onClick={ () => history.push('/readTerms/EN') }>EN</button>
            </div>
            
        </div>
        </>
    )
}