import 'react-activity/dist/react-activity.css';

import React, { useEffect } from 'react';
import { Spinner } from 'react-activity';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { modifyContactTitle, modifyContactContent, modifyContactName, modifyContactEmail, modifyContactNumber, setErroContactMessage, asyncSendMessageSite, setFieldError } from '../../../../redux/actions/settingsActions';

import Header from '../../../components/header';
import emailValidator from '../../../../services/emailValidator';
import logo from '../../../../assets/assistLogo.png';
import './styles.css';

export default function ContactUsSite() {
    const SubscriptionAndAccount = useSelector(state => state.TextsReducer.SubscriptionAndAccount);
    const Settings = useSelector(state => state.TextsReducer.Settings);
    const ContactUs = useSelector(state => state.TextsReducer.ContactUs);
    const Actions = useSelector(state => state.TextsReducer.Actions);
    const Errors = useSelector(state => state.TextsReducer.Errors);
    const contactTitle = useSelector(state => state.SettingsReducer.contactTitle);
    const contactContent = useSelector(state => state.SettingsReducer.contactContent);
    const contactName = useSelector(state => state.SettingsReducer.contactName);
    const contactEmail = useSelector(state => state.SettingsReducer.contactEmail);
    const contactNumber = useSelector(state => state.SettingsReducer.contactNumber);
    const contactLoad = useSelector(state => state.SettingsReducer.contactLoad);
    const settingErrorMessage = useSelector(state => state.SettingsReducer.settingErrorMessage);
    const settingSuccessMessage = useSelector(state => state.SettingsReducer.settingSuccessMessage);
    const fieldError = useSelector(state => state.SettingsReducer.fieldError);
    const dispatch = useDispatch();

    function renderBtnAccess() {
        if (contactLoad) {
            return (
                <div className="spinner-container">
                    <Spinner size={16} color='#000' />
                </div>
            );
        }

        return <button className="btn" type="submit">{Actions.Confirm}</button>;
    }

    function _sendMessage(event) {
        event.preventDefault();

        let message;

        if(!emailValidator(contactEmail)) {
            dispatch(setFieldError(true))
            message = Errors.User.EmailInvalid;
            dispatch(setErroContactMessage(message));
        } else if(contactTitle.length === '' || contactContent.length === '' || contactName.length === '' || contactNumber.length === '') {
            console.log(contactTitle, '1')
            console.log(contactContent, '2')
            console.log(contactName, '3')
            console.log(contactNumber, '4')
            message = Errors.User.FieldEmpty;
            dispatch(setErroContactMessage(message));
            dispatch(setFieldError(true));
        } else {
            dispatch(setErroContactMessage(''));
            dispatch(setFieldError(false));
            dispatch(asyncSendMessageSite());
        }
    }

    return (
        <div id="contactUs-container">
            <Header back='/login' screen={Settings.ContactUs} />
            <div id="form-contactUs-container">
                <img src={logo} alt="logo" />
                <form onSubmit={_sendMessage}>
                    <div className="field-container">
                        <label htmlFor="contactName">{SubscriptionAndAccount.Name}</label>
                        <input 
                            id="contactName"  
                            value={contactName}
                            style={{borderColor: fieldError && contactName === '' ? 'red' : '#DDD' }}
                            onChange={ event => dispatch(modifyContactName(event.target.value)) }
                        />
                    </div>

                    <div className="field-container">
                        <label htmlFor="contactEmail">{SubscriptionAndAccount.Email}</label>
                        <input 
                            id="contactEmail"  
                            value={contactEmail}
                            style={{borderColor: fieldError && contactEmail === '' ? 'red' : '#DDD' }}
                            onChange={ event => dispatch(modifyContactEmail(event.target.value)) }
                        />
                    </div>

                    <div className="field-container">
                        <label htmlFor="contactNumber">{SubscriptionAndAccount.Phone}</label>
                        <input 
                            id="contactNumber"
                            type="number"
                            value={contactNumber}
                            style={{borderColor: fieldError && contactNumber === '' ? 'red' : '#DDD' }}
                            onChange={ event => dispatch(modifyContactNumber(event.target.value)) }
                        />
                    </div>

                    <div className="field-container">
                        <label htmlFor="contactTitle">{ContactUs.Topic}</label>
                        <input 
                            id="contactTitle"  
                            value={contactTitle}
                            style={{borderColor: fieldError && contactTitle === '' ? 'red' : '#DDD' }}
                            onChange={ event => dispatch(modifyContactTitle(event.target.value)) }
                        />
                    </div>

                    <div className="field-container">
                        <label htmlFor="contactContent">{ContactUs.MessageToTeam}</label>
                        <textarea 
                            id="contactContent"
                            value={contactContent}
                            style={{borderColor: fieldError && contactContent === '' ? 'red' : '#DDD' }}
                            onChange={ event => dispatch(modifyContactContent(event.target.value)) }
                            maxLength={500}
                        />
                    </div>

                    <span className="errorMessage">{settingErrorMessage}</span>
                    <span className="successMessage">{settingSuccessMessage}</span>

                    { renderBtnAccess() }
                </form>
            </div>
        </div>
    )
}