import React, { useState } from 'react';
import { BiChevronRight } from "react-icons/bi";
import { FiExternalLink } from "react-icons/fi";
import ReactTooltip from 'react-tooltip';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import 'react-tooltip/dist/index.js'

import { announcementAttendMeetUp } from '../../../../redux/actions/assistActions';
import './styles.css';

export default function AdvertisementsItem({ item }) {
    const StandardPublications = useSelector(state => state.TextsReducer.StandardPublications);
    const MeetUps = useSelector(state => state.TextsReducer.MeetUps);
    const Actions = useSelector(state => state.TextsReducer.Actions);
    const itemMessageID = useSelector(state => state.AssistReducer.itemMessageID);
    const itemErrorMessage = useSelector(state => state.AssistReducer.itemErrorMessage);
    const itemSuccessMessage = useSelector(state => state.AssistReducer.itemSuccessMessage);
    const meetUpTemporaryStatus = useSelector(state => state.AssistReducer.meetUpTemporaryStatus);
    const dispatch = useDispatch();
    const history = useHistory();

    const [canOpenURL, setcanOpenURL] = useState({});

    function daysBetween(date) {
        let one = new Date();
        let two = new Date(date);

        let millisecondsPerDay = 1000 * 60 * 60 * 24;
        let millisBetween = two.getTime() - one.getTime();
        let days = millisBetween / millisecondsPerDay;

        return Math.floor(days);
    }

    function renderMessages(itemID) {
        if (itemMessageID === itemID && itemErrorMessage.length > 5) {
            return (
                <p className='error-meet-up-message'>{itemErrorMessage}</p>
            );
        } else if (itemMessageID === itemID && itemSuccessMessage.length > 5) {
            return (
                <p className='success-meet-up-message'>{itemSuccessMessage}</p>
            );
        } else {
            return;
        }
    }

    function _renderLocations() {
        if (item.creatorExtraData.coverageType === 'LOCALITY_NAME' || item.creatorExtraData.coverageType === 'POSTAL_CODE') {
            return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {item.creatorExtraData.locations.map((location, index) => {
                        if (item.creatorExtraData.coverageType === 'LOCALITY_NAME') {
                            if (index === item.creatorExtraData.locations.length - 1) {
                                return <p key={location.locality}>{location.locality}</p>
                            } else {
                                return <p key={location.locality}>{location.locality},&nbsp;</p>
                            }
                        } else {
                            if (index === item.creatorExtraData.locations.length - 1) {
                                return <p key={location.locality}>{location.zipCode}</p>
                            } else {
                                return <p key={location.locality}>{location.zipCode},&nbsp;</p>
                            }
                        }
                    })}
                </div>
            );
        }
    }

    function _openURL() {
        let itemID = item.id;
        setcanOpenURL({ ...canOpenURL, itemID: true });
    }

    function advertImage() {
        if (item.type === 'PROMOTIONAL_AD') {
            if (item.link && (item.link.slice(0, 3) === 'www' || item.link.slice(0, 3) === 'htt')) {
                let url = item.link.slice(0, 3) === 'www' ? 'https://' + item.link : item.link;
                return (
                    <a href={url} target="_blank" className="imageContainer">
                        <img src={item.image} className="advertsImage" />
                        <div className="buttonLinkContainer">
                            <p style={{ color: '#FFF', fontSize: 14, fontWeight: 'normal' }}>{Actions.KnowMore}</p>
                            <BiChevronRight size={18} color="#FFF" />
                        </div>
                    </a>
                );
            }

            return (
                <button type="button" className="imageContainer" onClick={() => _openURL()}>
                    <img src={item.image} className="advertsImage" />
                    <div className="buttonLinkContainer">
                        {canOpenURL.itemID ? <p style={{ color: '#FFF', fontSize: 14, fontWeight: 'normal' }}>{item.link}</p> : <p style={{ color: '#FFF', fontSize: 14, fontWeight: 'normal' }}>{Actions.KnowMore}</p>}
                        <BiChevronRight size={18} color="#FFF" />
                    </div>
                </button>
            );
        }

        return (
            <div className="imageContainer">
                <img src={item.image} className="advertsImage" />
            </div>
        )
    }

    function _meetUpButtonColor(meetUpData, statusButton) {
        if (meetUpData.userAttendanceStatus === statusButton) {
            if (meetUpTemporaryStatus.key === meetUpData.id && meetUpTemporaryStatus.status !== meetUpData.userAttendanceStatus) {
                return '#0091D0'
            } else if (meetUpTemporaryStatus.key === meetUpData.id && meetUpTemporaryStatus.status === meetUpData.userAttendanceStatus) {
                return '#97e0ff'
            }

            return '#97e0ff'
        } else {
            if (meetUpTemporaryStatus.key === meetUpData.id && meetUpTemporaryStatus.status === statusButton) {
                return '#97e0ff'
            }
            return '#0091D0'
        }
    }

    if (item.type === 'MEET_UP') {
        return (
            <div id="myAdvertisementsItem-container">
                <div className="header-adverts-item">
                    <button className="advertsTitleContainer" onClick={() => history.push({ pathname: '/fullPublication', state: item })}>
                        <p className='publication-name'>
                            {item.productName}
                            <span className='message-icon-container' data-tip={Actions.MoreDetails}><FiExternalLink style={{ marginLeft: 5 }} size={11} color='#af3a08' /></span>
                            <ReactTooltip className='tool-tip' />
                        </p>
                    </button>
                </div>

                <div className="advertsTextContainer">
                    <p>{item.textContent}</p>
                </div>

                {advertImage()}

                <div className="locations">
                    {_renderLocations()}
                    <div style={{ display: 'flex', marginTop: 3 }}>
                        <p>{item.creatorExtraData.locations[0].regionOrState}&nbsp;</p>
                        <p>{item.creatorExtraData.locations[0].country}</p>
                    </div>
                </div>

                <div className="footer">
                    <div className='footer-item'>
                        <p>{MeetUps.MeetUpLocation}: {item.meetUpData.meetUpAddress}</p>
                    </div>

                    <div className='footer-item'>
                        <p>{MeetUps.MeetUpStartDate}: {item.meetUpData.startDateFormatted}</p>
                    </div>

                    <div className='footer-item'>
                        <p>{MeetUps.MeetUpEndDate}: {item.meetUpData.endDateFormatted}</p>
                    </div>

                    <div className='meet-up-container'>
                        {renderMessages(item.meetUpData.id)}

                        <div className='meet-up-buttons-container'>
                            <button className='meet-up-button' style={{ backgroundColor: _meetUpButtonColor(item.meetUpData, 'ATTENDING') }} onClick={() => item.meetUpData.userAttendanceStatus !== 'ATTENDING' ? dispatch(announcementAttendMeetUp(item.id, item.meetUpData.id, 'ATTENDING')) : null} disabled={daysBetween(item.meetUpData.startDate) < 0}>
                                {MeetUps.Attend}
                            </button>

                            <button className='meet-up-button' style={{ backgroundColor: _meetUpButtonColor(item.meetUpData, 'MAYBE_ATTENDING') }} onClick={() => item.meetUpData.userAttendanceStatus !== 'MAYBE_ATTENDING' ? dispatch(announcementAttendMeetUp(item.id, item.meetUpData.id, 'MAYBE_ATTENDING')) : null} disabled={daysBetween(item.meetUpData.startDate) < 0}>
                                {MeetUps.MaybeAttend}
                            </button>
                        </div>

                        <div className='meet-up-attendees-container'>
                            <div>
                                <p>{MeetUps.Attendees}: {item.meetUpData.numberOfConfirmedAttendances}</p>
                            </div>

                            <div>
                                <p>{MeetUps.MaybeAttendees}: {item.meetUpData.numberOfPossibleAttendances}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id="myAdvertisementsItem-container">
            <div className="header-adverts-item">
                <button className="advertsTitleContainer" onClick={() => history.push({ pathname: '/fullPublication', state: item })}>
                    <p className='publication-name'>
                        {item.productName}
                        <span className='message-icon-container' data-tip={Actions.MoreDetails}><FiExternalLink style={{ marginLeft: 5 }} size={11} color='#af3a08' /></span>
                        <ReactTooltip className='tool-tip' />
                    </p>
                </button>
            </div>

            <div className="advertsTextContainer">
                <p>{item.textContent}</p>
            </div>

            {advertImage()}

            <div className="locations">
                {_renderLocations()}
                <div style={{ display: 'flex', marginTop: 3 }}>
                    <p>{item.creatorExtraData.locations[0].regionOrState}&nbsp;</p>
                    <p>{item.creatorExtraData.locations[0].country}</p>
                </div>
            </div>

            <div className="footer">
                <div className='footer-space' />

                <div className='footer-item'>
                    <p className='text-date'>{StandardPublications.StartDate}:&nbsp; <p className='date'>{item.creatorExtraData.startDateFormatted}</p></p>
                </div>

                {item.creatorExtraData.endDate !== null && <div className='footer-item'>
                    <p className='text-date'>{StandardPublications.EndDate}:&nbsp; <p className='date'>{item.creatorExtraData.endDateFormatted}</p></p>
                </div>}

                {item.creatorExtraData.earlierEndDate !== null && <div className='footer-item'>
                    <p className='text-date'>{StandardPublications.EarlierEndDate}:&nbsp; <p className='date'>{item.creatorExtraData.earlierEndDateFormatted}</p></p>
                </div>}
            </div>
        </div >
    )
}