const INITIAL_STATE = {
    announcementType: 'COMMUNICATION',
    title: '',
    content: '',
    image: '',
    loadingImage: false,
    surveyTitle: '',
    numberOfOptions: 2,
    numberOfOptionsArray: ['option', 'option'],
    optionText: [
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
    ],
    announcementCalendar: false,
    announcementMeetUpCalendar: false,
    startDate: '',
    endDate: '',
    meetUpAddress: '',
    meetUpStartDate: '',
    meetUpEndDate: '',
    calendarType: '',
    calendarMeetUpType: '',
    calendarOrder: '',
    calendarMeetUpOrder: '',
    announcementLoading: false,
    announcementErrorMessage: '',
    announcementSuccessMessage: '',
    allAnnouncements: [],
    reloadingAnnouncements: false,
    potentialNumberOfViewsForAnnouncements: 0,
    endAnnouncementsModal: false,
    announcementCanceled: false,
    deleteAnnouncemetID: 0
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'MODIFY_ANNOUNCEMENT_TYPE':
            return { ...state, announcementType: action.payload }
        case 'MODIFY_ANNOUNCEMENT_TITLE':
            return { ...state, title: action.payload }
        case 'MODIFY_ANNOUNCEMENT_CONTENT':
            return { ...state, content: action.payload }
        case 'ASYNC_SEND_ANNOUNCEMENT_IMAGE':
            return { ...state, loadingImage: true }
        case 'SET_UPLOADED_ANNOUNCEMENT_IMAGE':
            return { ...state, image: action.payload, loadingImage: false }
        case 'MODIFY_ANNOUNCEMENT_SURVEY_TITLE':
            return { ...state, surveyTitle: action.payload }
        case 'MODIFY_NUMBER_OF_OPTIONS_ANNOUNCEMENTS':
            return { ...state, numberOfOptions: action.payload.value, numberOfOptionsArray: action.payload.array }
        case 'MODIFY_ANNOUNCEMENT_OPTION_TEXT':
            return { ...state, optionText: action.payload }
        case 'OPEN_ANNOUNCEMENT_CALENDAR':
            return { ...state, announcementCalendar: true, calendarType: action.payload.type, calendarOrder: action.payload.order }
        case 'OPEN_ANNOUNCEMENT_MEET_UP_CALENDAR':
            return { ...state, announcementMeetUpCalendar: true, calendarMeetUpType: action.payload.type, calendarMeetUpOrder: action.payload.order }
        case 'CLOSE_ANNOUNCEMENT_CALENDAR':
            return { ...state, announcementCalendar: false, calendarType: '', calendarOrder: '' }
        case 'CLOSE_ANNOUNCEMENT_MEET_UP_CALENDAR':
            return { ...state, announcementMeetUpCalendar: false, calendarMeetUpType: '', calendarMeetUpOrder: '' }
        case 'SET_START_DATE':
            return { ...state, startDate: action.payload, announcementCalendar: false }
        case 'SET_END_DATE':
            return { ...state, endDate: action.payload, announcementCalendar: false }
        case 'SET_MEET_UP_START_DATE':
            return { ...state, meetUpStartDate: action.payload, announcementMeetUpCalendar: false }
        case 'SET_MEET_UP_END_DATE':
            return { ...state, meetUpEndDate: action.payload, announcementMeetUpCalendar: false }
        case 'SET_ANNOUNCEMENT_IOS_START_DATE':
            return { ...state, startDate: action.payload }
        case 'SET_ANNOUNCEMENT_IOS_END_DATE':
            return { ...state, endDate: action.payload }
        case 'SET_ANNOUNCEMENT_MEET_UP_IOS_START_DATE':
            return { ...state, meetUpStartDate: action.payload }
        case 'SET_ANNOUNCEMENT_MEET_UP_IOS_END_DATE':
            return { ...state, meetUpEndDate: action.payload }
        case 'ANNOUNCEMENTS_ERROR_MESSAGE':
            return { ...state, announcementErrorMessage: action.payload, announcementLoading: false, loadingImage: false }
        case 'ANNOUNCEMENTS_SUCCESS_MESSAGE':
            return { ...state, announcementSuccessMessage: action.payload }
        case 'ASYNC_SEND_ANNOUNCEMENT':
            return { ...state, announcementLoading: true }
        case 'SENDED_ANNOUNCEMENT':
            return {
                ...state,
                title: '',
                content: '',
                image: '',
                surveyTitle: '',
                numberOfOptions: 2,
                numberOfOptionsArray: ['option', 'option'],
                optionText: [
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                ],
                startDate: '',
                endDate: '',
                meetUpStartDate: '',
                meetUpEndDate: '',
                calendarType: '',
                calendarMeetUpType: '',
                announcementErrorMessage: '',
                announcementLoading: false
            }
        case 'CLEAR_ANNOUNCEMENT':
            return {
                ...state,
                announcementType: 'COMMUNICATION',
                title: '',
                content: '',
                image: '',
                surveyTitle: '',
                numberOfOptions: 2,
                numberOfOptionsArray: ['option', 'option'],
                optionText: [
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                ],
                startDate: '',
                endDate: '',
                meetUpStartDate: '',
                meetUpEndDate: '',
                calendarType: '',
                calendarMeetUpType: '',
                announcementErrorMessage: '',
                announcementLoading: false
            }
        case 'GET_ANNOUNCEMENTS':
            return { ...state, reloadingAnnouncements: action.payload }
        case 'SET_ANNOUNCEMENTS':
            return { ...state, allAnnouncements: action.payload.allAnnouncements, reloadingAnnouncements: action.payload.reload }
        case 'SET_POTENTIAL_NUMBER_OF_VIEWS_FOR_ANNOUNCEMENTS':
            return { ...state, potentialNumberOfViewsForAnnouncements: action.payload }
        case 'MODIFY_ANNOUNCEMENT_MEET_UP_ADDRESS':
            return { ...state, meetUpAddress: action.payload }
        case 'OPEN_END_ANNOUNCEMENTS_MODAL':
            return { ...state, endAnnouncementsModal: true, deleteAnnouncemetID: action.payload }
        case 'CLOSE_END_ANNOUNCEMENTS_MODAL':
            return { ...state, endAnnouncementsModal: false, announcementCanceled: false, deleteAnnouncemetID: 0 }
        case 'ANNOUNCEMENT_CANCELLED':
            return { ...state, announcementCanceled: action.payload }
        default:
            return state;
    }
}