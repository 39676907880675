import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import AppReducer from './reducers';
import rootSaga from './saga';

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['UserReducer', 'TextsReducer'], 
};

const middleware = [];

const sagaMiddleware = createSagaMiddleware();

middleware.push(sagaMiddleware);

const persistedReducer = persistReducer(persistConfig, AppReducer);

const store = createStore(
    persistedReducer, 
    applyMiddleware(...middleware)
);

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga)

export { store, persistor };