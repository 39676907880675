import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import './styles.css';

export default function AdsHeader() {
    const StandardPublications = useSelector(state => state.TextsReducer.StandardPublications);
    const history = useHistory();

    return (
        <div id="publication-header">
            <button className='publication-option' type="button" onClick={() => history.push('/newPublication')} style={{ backgroundColor: history.location.pathname === '/newPublication' ? '#92C9FF' : '#FFF', color: history.location.pathname === '/newPublication' ? '#FFF' : '#000' }}>
                {StandardPublications.Create}
            </button>

            <button className='publication-option' type="button" onClick={() => history.push('/aboutPublications')} style={{ backgroundColor: history.location.pathname === '/aboutPublications' ? '#92C9FF' : '#FFF', color: history.location.pathname === '/aboutPublications' ? '#FFF' : '#000' }}>
                {StandardPublications.About}
            </button>
        </div>
    )
}