import React from 'react';
import Modal from 'react-modal';
import { RiCloseFill } from "react-icons/ri";
import { useSelector, useDispatch } from 'react-redux';

import { cancelAdvertisement, closeEndAdvertsModal } from '../../redux/actions/advertisementsActions';
import { cancelAnnouncement, closeEndAnnouncementsModal } from '../../redux/actions/announcementsActions';

import './styles.css';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: 10,
        overflow: 'hidden'
    },
};

Modal.setAppElement('#root');

export default function EndAdsOrAnnouncModal({ screen }) {
    const StandardPublications = useSelector(state => state.TextsReducer.StandardPublications);
    const PremiumPublications = useSelector(state => state.TextsReducer.PremiumPublications);
    const Actions = useSelector(state => state.TextsReducer.Actions);
    const SubscriptionAndAccount = useSelector(state => state.TextsReducer.SubscriptionAndAccount);
    const endAdvertsModal = useSelector(state => state.AdvertisementsReducer.endAdvertsModal);
    const endAnnouncementsModal = useSelector(state => state.AnnouncementsReducer.endAnnouncementsModal);
    const advertisementCanceled = useSelector(state => state.AdvertisementsReducer.advertisementCanceled);
    const announcementCanceled = useSelector(state => state.AnnouncementsReducer.announcementCanceled);
    const deleteAdvertisementID = useSelector(state => state.AdvertisementsReducer.deleteAdvertisementID);
    const deleteAnnouncemetID = useSelector(state => state.AnnouncementsReducer.deleteAnnouncemetID);
    const dispatch = useDispatch();

    let itemID = screen === 'Advertisement' ? deleteAdvertisementID : deleteAnnouncemetID;
    let openModal = screen === 'Advertisement' ? endAdvertsModal : endAnnouncementsModal;

    function _end() {
        if (screen === 'Advertisement') {
            dispatch(cancelAdvertisement(itemID));
        } else {
            dispatch(cancelAnnouncement(itemID));
        }
    }

    function _modalContent() {
        if (advertisementCanceled || announcementCanceled) {
            return (
                <div className='end-publications-modal-content'>
                    <p className='modal-title'>{screen === 'Advertisement' ? StandardPublications.BroadcastingOver : PremiumPublications.BroadcastingOver}</p>
                    <button className='close-modal' onClick={() => screen === 'Advertisement' ? dispatch(closeEndAdvertsModal()) : dispatch(closeEndAnnouncementsModal())}>
                        <RiCloseFill size={20} color="#000" />
                    </button>
                    <div className='modal-button-container one-button'>
                        <button className='modal-button confirm' onClick={() => screen === 'Advertisement' ? dispatch(closeEndAdvertsModal()) : dispatch(closeEndAnnouncementsModal())}>
                            {Actions.Ok}
                        </button>
                    </div>
                </div>
            );
        }

        return (
            <div className='end-publications-modal-content'>
                <p className='modal-title'>{screen === 'Advertisement' ? StandardPublications.EndBroadcastingConfirm : PremiumPublications.EndBroadcastingConfirm}</p>
                <button className='close-modal' onClick={() => screen === 'Advertisement' ? dispatch(closeEndAdvertsModal()) : dispatch(closeEndAnnouncementsModal())}>
                    <RiCloseFill size={20} color="#444" />
                </button>
                <div className='modal-button-container'>
                    <button className='modal-button cancel' onClick={() => screen === 'Advertisement' ? dispatch(closeEndAdvertsModal()) : dispatch(closeEndAnnouncementsModal())}>
                        {SubscriptionAndAccount.No}
                    </button>

                    <button className='modal-button confirm' onClick={() => _end()}>
                        {SubscriptionAndAccount.Yes}
                    </button>
                </div>
            </div>
        );
    }

    return (
        <Modal
            isOpen={openModal}
            onRequestClose={() => screen === 'Advertisement' ? dispatch(closeEndAdvertsModal()) : dispatch(closeEndAnnouncementsModal())}
            style={customStyles}
        >
            <div id='end-publications-modal-container'>
                {_modalContent()}
            </div>
        </Modal>
    );
};