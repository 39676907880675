import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import './styles.css';

export default function LoginHeader() {
    const Settings = useSelector(state => state.TextsReducer.Settings);
    const history = useHistory();

    return (
        <div id="login-header">
            <button type="button" onClick={ () => history.push('/aboutUs') }>
                {Settings.About}
            </button>

            <button type="button" onClick={ () =>  history.push('/contactUs') }>
                {Settings.ContactUs}
            </button>
        </div>
    )
}