import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import './styles.css';

export default function LoginFooter() {
    const Terms = useSelector(state => state.TextsReducer.Terms);
    const history = useHistory();


    return (
        <div id="login-footer">
            <button type="button" onClick={ () => history.push('/termsOfUse') }>
                {Terms.TermsText}
            </button>
        </div>
    )
}