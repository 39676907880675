export const getAdvertisementsAllowedCountries = () => {
    return { type: 'GET_ADVERTISEMENTS_ALLOWED_COUNTRIES' }
}

export const modifyAdvertisementsTitle = text => {
    return {
        type: 'MODIFY_ADVERTISEMENT_TITLE',
        payload: text
    }
}

export const modifyAdvertisementsLink = text => {
    return {
        type: 'MODIFY_ADVERTISEMENT_LINK',
        payload: text
    }
}

export const modifyAdvertisementsDays = text => {
    return {
        type: 'MODIFY_ADVERTISEMENT_DAYS',
        payload: text
    }
}

export const modifyAdvertisementsProduct = text => {
    return {
        type: 'MODIFY_ADVERTISEMENT_PRODUCT',
        payload: text
    }
}

export const modifyAdvertisementsDesc = text => {
    return {
        type: 'MODIFY_ADVERTISEMENT_CONTENT',
        payload: text
    }
}

export const modifySelectArea = text => {
    return {
        type: 'MODIFY_SELECT_AREA',
        payload: text
    }
}

export const asyncSendAdvertisementImage = image => {
    return {
        type: 'ASYNC_SEND_ADVERTISEMENT_IMAGE',
        payload: image
    }
}

export const modifyAdvertisementCountry = text => {
    return {
        type: 'MODIFY_ADVERTISEMENT_COUNTRY',
        payload: text
    }
}

export const modifyAdvertisementRegion = text => {
    return {
        type: 'MODIFY_ADVERTISEMENT_REGION',
        payload: text
    }
}

export const modifyAdvertisementCity1 = text => {
    return {
        type: 'MODIFY_ADVERTISEMENT_CITY_1',
        payload: text
    }
}

export const modifyAdvertisementCity2 = text => {
    return {
        type: 'MODIFY_ADVERTISEMENT_CITY_2',
        payload: text
    }
}

export const modifyAdvertisementCity3 = text => {
    return {
        type: 'MODIFY_ADVERTISEMENT_CITY_3',
        payload: text
    }
}

export const modifyAdvertisementZipCode1 = text => {
    return {
        type: 'MODIFY_ADVERTISEMENT_ZIPCODE_1',
        payload: text
    }
}

export const modifyAdvertisementZipCode2 = text => {
    return {
        type: 'MODIFY_ADVERTISEMENT_ZIPCODE_2',
        payload: text
    }
}

export const modifyAdvertisementZipCode3 = text => {
    return {
        type: 'MODIFY_ADVERTISEMENT_ZIPCODE_3',
        payload: text
    }
}

export const openAdvertisementCalendar = type => {
    return {
        type: 'OPEN_ADVERTISEMENT_CALENDAR',
        payload: type
    }
}

export const closeAdvertisementCalendar = () => {
    return { type: 'CLOSE_ADVERTISEMENT_CALENDAR' }
}

export const setStartDate = date => {
    return {
        type: 'SET_ADVERTISEMENTS_START_DATE',
        payload: date
    }
}

export const setAdvertisementsIosDate = date => {
    return {
        type: 'SET_ADVERTISEMENTS_IOS_DATE',
        payload: date
    }
}

export const advertisementsError = text => {
    return {
        type: 'ADVERTISEMENTS_ERROR_MESSAGE',
        payload: text
    }
}

export const asyncCalculatePrice = () => {
    return { type: 'ASYNC_CALCULATE_PRICE' }
}

export const asyncSendAdvertisement = () => {
    return { type: 'ASYNC_SEND_ADVERTISEMENT' }
}

export const getAdvertisements = reload => {
    return {
        type: 'GET_ADVERTISEMENTS',
        payload: reload
    }
}

export const clearAdvertisements = () => {
    return { type: 'CLEAR_ADVERTISEMENT' }
}

export const setChangeLocality = value => {
    return {
        type: 'SET_CHANGE_LOCALITY',
        payload: value
    }
}

export const modifyAdvertType = type => {
    return {
        type: 'MODIFY_ADVERT_TYPE',
        payload: type
    }
}

export const changePublishAsSoonAsPossible = status => {
    return {
        type: 'CHANGE_PUBLISH_AS_SOON_AS_POSSIBLE',
        payload: status
    }
}

export const setAdvertMeetUpIosStartDate = date => {
    return {
        type: 'SET_ADVERTISEMENT_MEET_UP_IOS_START_DATE',
        payload: date
    }
}

export const setAdvertMeetUpIosEndDate = date => {
    return {
        type: 'SET_ADVERTISEMENT_MEET_UP_IOS_END_DATE',
        payload: date
    }
}

export const openAdvertMeetUpCalendar = (type, order) => {
    return {
        type: 'OPEN_ADVERTISEMENT_MEET_UP_CALENDAR',
        payload: { type, order }
    }
}

export const closeAdvertMeetUpCalendar = () => {
    return { type: 'CLOSE_ADVERTISEMENT_MEET_UP_CALENDAR' }
}

export const setMeetUpStartDate = date => {
    return {
        type: 'SET_ADVERTISEMENT_MEET_UP_START_DATE',
        payload: date
    }
}

export const setMeetUpEndDate = date => {
    return {
        type: 'SET_ADVERTISEMENT_MEET_UP_END_DATE',
        payload: date
    }
}

export const modifyMeetUpAddress = text => {
    return {
        type: 'MODIFY_ADVERT_MEET_UP_ADDRESS',
        payload: text
    }
}

export const retrieveRegionsOfCountry = () => {
    return { type: 'RETRIEVE_REGIONS_OF_COUNTRY' }
}

export const clearAdvertLocality = () => {
    return { type: 'CLEAR_ADVERT_LOCALITY' }
}

export const retrieveLocalitiesOfRegion = () => {
    return { type: 'RETRIEVE_LOCALITIES_OF_REGIONS' }
}

export const cancelAdvertisement = advertId => {
    return {
        type: 'ASYNC_CANCEL_ADVERTISEMENT',
        payload: advertId
    }
}

export const openEndAdvertsModal = itemID => {
    return {
        type: 'OPEN_END_ADVERTS_MODAL',
        payload: itemID
    }
}

export const closeEndAdvertsModal = () => {
    return { type: 'CLOSE_END_ADVERTS_MODAL' }
}
