import 'react-activity/dist/react-activity.css';

import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-activity';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import LoginHeader from '../loginHeader';
import LoginFooter from '../loginFooter';

import { modifyUsername, modifyPassword, authenticate, setErroLogin } from '../../../../redux/actions/authActions';
import { getTextLanguage } from '../../../../redux/actions/userActions';
import emailValidator from '../../../../services/emailValidator';
import logo from '../../../../assets/assistLogo.png';
import './styles.css';

export default function Login() {
    const WelcomeScreen = useSelector(state => state.TextsReducer.WelcomeScreen);
    const Errors = useSelector(state => state.TextsReducer.Errors);
    const Site = useSelector(state => state.TextsReducer.Site);
    const SubscriptionAndAccount = useSelector(state => state.TextsReducer.SubscriptionAndAccount);
    const username = useSelector(state => state.AuthReducer.username);
    const password = useSelector(state => state.AuthReducer.password);
    const loading_login = useSelector(state => state.AuthReducer.loading_login);
    const erroLogin = useSelector(state => state.AuthReducer.erroLogin);

    const dispatch = useDispatch();
    const history = useHistory();

    const [isActive, setIsActive] = useState({});

    useEffect(() => {
        let deviceLanguage = navigator.language || navigator.userLanguage;
        deviceLanguage = deviceLanguage.replace("-","_")

        dispatch(getTextLanguage(deviceLanguage));
    },[]);

    useEffect(() => {
        if (username !== '') {
            setIsActive({...isActive, username: true});
        } else {
            setIsActive({...isActive, username: false});
        }

        if (password !== '') {
            setIsActive({...isActive, password: true});
        } else {
            setIsActive({...isActive, password: false});
        }
    },[]);

    function _authenticate(event) {
        event.preventDefault();

        let messege;

        if(!emailValidator(username)) {
            messege = Errors.User.EmailInvalid;
            dispatch(setErroLogin(messege));
            setTimeout(() => {
                dispatch(setErroLogin(''));
            }, 3000);
        } else if(password.length < 3) {
            messege = Errors.User.PasswordFormat;
            dispatch(setErroLogin(messege));
            setTimeout(() => {
                dispatch(setErroLogin(''));
            }, 3000);
        } else {
            dispatch(authenticate(history, username, password))
        }
    }

    function renderBtnAccess() {
        if (loading_login) {
            return (
                <div className="spinner-container">
                    <Spinner size={16} color='#000' />
                </div>
            );
        }

        return <button className="btn" type="submit">{WelcomeScreen.Login}</button>;
    }

    function _modifyUsername(text) {
        dispatch(modifyUsername(text))
      
        if (text !== '') {
          setIsActive({...isActive, username: true});
        } else {
            setIsActive({...isActive, username: false});
        }
    }

    function _modifyPassword(text) {
        dispatch(modifyPassword(text))
      
        if (text !== '') {
          setIsActive({...isActive, password: true});
        } else {
            setIsActive({...isActive, password: false});
        }
    }

    return (
        <div id="login-container">
            <LoginHeader />
            <div id="form-login-container">
                <img src={logo} alt="logo" />
                {Site && <p>{Site.Description}</p>}
                <form onSubmit={_authenticate}>     
                    <div className="field-container">
                        <label className={ isActive.username ? "Active" : ""} htmlFor="username">{SubscriptionAndAccount.Email}</label>
                        <input 
                            id="username"  
                            value={username}
                            onChange={ event => _modifyUsername(event.target.value) }
                        />
                    </div>

                    <div className="field-container">
                        <label className={ isActive.password ? "Active" : ""} htmlFor="password">{SubscriptionAndAccount.Password}</label>
                        <input 
                            id="password"
                            type="password"
                            value={password}
                            onChange={ event => _modifyPassword(event.target.value) }
                        />
                    </div>

                    <span id="errorLogin" className="errorMessage">{erroLogin}</span>

                    { renderBtnAccess() }
                </form>
            </div>
            <LoginFooter />
        </div>
    )
}
