const INITIAL_STATE = {
    advertType: 'CLASSIFIED_AD',
    advertisementsCountries: [],
    regionsOfCountry: [],
    localitiesOfRegion: [],
    postalCodesOfRegion: [],
    productName: '',
    link: '',
    title: '',
    content: '',
    image: '',
    loadingImage: false,
    openLocality: false,
    selectArea: 'City',
    AdvertisementCountry: '',
    AdvertisementRegion: '',
    locality1: '',
    locality2: '',
    locality3: '',
    advertisementCalendar: false,
    startDate: '',
    numberOfDays: 1,
    calendarType: '',
    advertisementLoading: false,
    advertisementErrorMessage: '',
    advertisementSuccessMessage: '',
    allAdvertisements: [],
    reloadingAdvertisements: false,
    price: 0,
    currency: '',
    potentialNumberOfViewsForAdvert: 0,
    publishAsSoonAsPossible: true,
    advertMeetUpCalendar: false,
    calendarMeetUpOrder: '',
    meetUpStartDate: '',
    meetUpEndDate: '',
    calendarMeetUpType: '',
    calendarMeetUpOrder: '',
    checkedAdvert: false,
    meetUpAddress: '',
    endAdvertsModal: false,
    advertisementCanceled: false,
    deleteAdvertisementID: 0
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_ADVERTISEMENTS_ALLOWED_COUNTRIES':
            return { ...state, advertisementsCountries: action.payload }
        case 'MODIFY_ADVERTISEMENT_TITLE':
            return { ...state, title: action.payload }
        case 'MODIFY_ADVERTISEMENT_PRODUCT':
            return { ...state, productName: action.payload }
        case 'MODIFY_ADVERTISEMENT_LINK':
            return { ...state, link: action.payload }
        case 'MODIFY_ADVERTISEMENT_DAYS':
            return { ...state, numberOfDays: action.payload }
        case 'MODIFY_ADVERTISEMENT_CONTENT':
            return { ...state, content: action.payload }
        case 'ASYNC_SEND_ADVERTISEMENT_IMAGE':
            return { ...state, loadingImage: true }
        case 'SET_UPLOADED_ADVERTISEMENT_IMAGE':
            return { ...state, image: action.payload, loadingImage: false }
        case 'SET_CHANGE_LOCALITY':
            return { ...state, openLocality: action.payload }
        case 'MODIFY_SELECT_AREA':
            return { ...state, selectArea: action.payload, locality1: '', locality2: '', locality3: '' }
        case 'MODIFY_ADVERTISEMENT_COUNTRY':
            return { ...state, AdvertisementCountry: action.payload, checkedAdvert: false }
        case 'MODIFY_ADVERTISEMENT_REGION':
            return { ...state, AdvertisementRegion: action.payload, checkedAdvert: false }
        case 'MODIFY_ADVERTISEMENT_CITY_1':
            return { ...state, locality1: action.payload, checkedAdvert: false }
        case 'MODIFY_ADVERTISEMENT_CITY_2':
            return { ...state, locality2: action.payload, checkedAdvert: false }
        case 'MODIFY_ADVERTISEMENT_CITY_3':
            return { ...state, locality3: action.payload, checkedAdvert: false }
        case 'OPEN_ADVERTISEMENT_CALENDAR':
            return { ...state, advertisementCalendar: true, calendarType: action.payload }
        case 'CLOSE_ADVERTISEMENT_CALENDAR':
            return { ...state, advertisementCalendar: false }
        case 'SET_ADVERTISEMENTS_START_DATE':
            return { ...state, startDate: action.payload, advertisementCalendar: false }
        case 'SET_ADVERTISEMENTS_IOS_DATE':
            return { ...state, startDate: action.payload }
        case 'ADVERTISEMENTS_ERROR_MESSAGE':
            return { ...state, advertisementErrorMessage: action.payload, advertisementLoading: false, loadingImage: false }
        case 'ADVERTISEMENTS_SUCCESS_MESSAGE':
            return { ...state, advertisementSuccessMessage: action.payload }
        case 'ASYNC_CALCULATE_PRICE':
            return { ...state, advertisementLoading: true }
        case 'CALCULED_NUMBER_OF_VIEWS_ADVERT':
            return { ...state, potentialNumberOfViewsForAdvert: action.payload, checkedAdvert: true, advertisementLoading: false }
        case 'CALCULED_PRICE':
            return { ...state, price: action.payload.price, currency: action.payload.currency }
        case 'ASYNC_SEND_ADVERTISEMENT':
            return { ...state, advertisementLoading: true }
        case 'SENDED_ADVERTISEMENT':
            return {
                ...state,
                regionsOfCountry: [],
                localitiesOfRegion: [],
                postalCodesOfRegion: [],
                title: '',
                link: '',
                productName: '',
                content: '',
                image: '',
                loadingImage: false,
                openLocality: false,
                selectArea: 'City',
                numberOfFileds: 1,
                AdvertisementCountry: '',
                AdvertisementRegion: '',
                locality1: '',
                locality2: '',
                locality3: '',
                calendarType: '',
                advertisementErrorMessage: '',
                price: 0,
                currency: '',
                potentialNumberOfViewsForAdvert: 0,
                advertisementLoading: false,
                publishAsSoonAsPossible: true,
                advertMeetUpCalendar: false,
                calendarMeetUpOrder: '',
                meetUpStartDate: '',
                meetUpEndDate: '',
                meetUpAddress: '',
                calendarMeetUpType: '',
                calendarMeetUpOrder: '',
                checkedAdvert: false
            }
        case 'CLEAR_ADVERTISEMENT':
            return {
                ...state,
                advertType: 'CLASSIFIED_AD',
                regionsOfCountry: [],
                localitiesOfRegion: [],
                postalCodesOfRegion: [],
                title: '',
                link: '',
                productName: '',
                content: '',
                image: '',
                loadingImage: false,
                openLocality: false,
                selectArea: 'City',
                numberOfFileds: 1,
                AdvertisementCountry: '',
                AdvertisementRegion: '',
                locality1: '',
                locality2: '',
                locality3: '',
                calendarType: '',
                advertisementErrorMessage: '',
                price: 0,
                currency: '',
                potentialNumberOfViewsForAdvert: 0,
                advertisementLoading: false,
                publishAsSoonAsPossible: true,
                advertMeetUpCalendar: false,
                calendarMeetUpOrder: '',
                meetUpStartDate: '',
                meetUpEndDate: '',
                meetUpAddress: '',
                calendarMeetUpType: '',
                calendarMeetUpOrder: '',
                checkedAdvert: false
            }
        case 'GET_ADVERTISEMENTS':
            return { ...state, reloadingAdvertisements: action.payload }
        case 'SET_ADVERTISEMENTS':
            return { ...state, allAdvertisements: action.payload.allAdvertisements, reloadingAdvertisements: action.payload.reload }
        case 'MODIFY_ADVERT_TYPE':
            return { ...state, advertType: action.payload }
        case 'CHANGE_PUBLISH_AS_SOON_AS_POSSIBLE':
            return { ...state, publishAsSoonAsPossible: action.payload }
        case 'OPEN_ADVERTISEMENT_MEET_UP_CALENDAR':
            return { ...state, advertMeetUpCalendar: true, calendarMeetUpType: action.payload.type, calendarMeetUpOrder: action.payload.order }
        case 'CLOSE_ADVERTISEMENT_MEET_UP_CALENDAR':
            return { ...state, advertMeetUpCalendar: false, calendarMeetUpType: '', calendarMeetUpOrder: '' }
        case 'SET_ADVERTISEMENT_MEET_UP_START_DATE':
            return { ...state, meetUpStartDate: action.payload, advertMeetUpCalendar: false }
        case 'SET_ADVERTISEMENT_MEET_UP_END_DATE':
            return { ...state, meetUpEndDate: action.payload, advertMeetUpCalendar: false }
        case 'SET_ADVERTISEMENT_MEET_UP_IOS_START_DATE':
            return { ...state, meetUpStartDate: action.payload }
        case 'SET_ADVERTISEMENT_MEET_UP_IOS_END_DATE':
            return { ...state, meetUpEndDate: action.payload }
        case 'MODIFY_ADVERT_MEET_UP_ADDRESS':
            return { ...state, meetUpAddress: action.payload }
        case 'SET_REGIONS_OF_COUNTRY':
            return { ...state, regionsOfCountry: action.payload }
        case 'SET_LOCALITIES_OF_REGIONS':
            return { ...state, localitiesOfRegion: action.payload }
        case 'SET_POSTAL_CODES_OF_REGIONS':
            return { ...state, postalCodesOfRegion: action.payload }
        case 'CLEAR_ADVERT_LOCALITY':
            return {
                ...state,
                regionsOfCountry: [],
                localitiesOfRegion: [],
                postalCodesOfRegion: [],
                AdvertisementCountry: '',
                AdvertisementRegion: '',
                locality1: '',
                locality2: '',
                locality3: '',
            }
        case 'OPEN_END_ADVERTS_MODAL':
            return { ...state, endAdvertsModal: true, deleteAdvertisementID: action.payload }
        case 'CLOSE_END_ADVERTS_MODAL':
            return { ...state, endAdvertsModal: false, advertisementCanceled: false, deleteAdvertisementID: 0 }
        case 'ADVERTISEMENT_CANCELLED':
            return { ...state, advertisementCanceled: action.payload }
        default:
            return state;
    }
}