import React, { useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { Spinner } from 'react-activity';
import Select from 'react-select';
import dt from 'date-and-time';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../../../components/header';
import premiumVerifiedIcon from '../../../../assets/premiumVerified.png';

import { modifyAnnouncementsTitle, modifyAnnouncementsDesc, asyncSendAnnouncementImage, openAnnouncementCalendar, closeAnnouncementCalendar, setStartDate, setEndDate, announcementsError, asyncSendAnnouncement, getPotentialNumberOfViewsForAnnouncements, clearAnnouncements, modifyAnnouncementType, modifyMeetUpAddress, setMeetUpStartDate, setMeetUpEndDate, modifySurveyTitle, modifyNumberOfOptions, modifyOptionText } from '../../../../redux/actions/announcementsActions';
import { setFieldError } from '../../../../redux/actions/settingsActions';
import './styles.css';

export default function Announcements() {
    const Errors = useSelector(state => state.TextsReducer.Errors);
    const PremiumPublications = useSelector(state => state.TextsReducer.PremiumPublications);
    const PremiumPublicationTypes = useSelector(state => state.TextsReducer.PremiumPublicationTypes);
    const announcementType = useSelector(state => state.AnnouncementsReducer.announcementType);
    const MeetUps = useSelector(state => state.TextsReducer.MeetUps);
    const Surveys = useSelector(state => state.TextsReducer.Surveys);
    const Actions = useSelector(state => state.TextsReducer.Actions);
    const title = useSelector(state => state.AnnouncementsReducer.title);
    const content = useSelector(state => state.AnnouncementsReducer.content);
    const image = useSelector(state => state.AnnouncementsReducer.image);
    const loadingImage = useSelector(state => state.AnnouncementsReducer.loadingImage);
    const surveyTitle = useSelector(state => state.AnnouncementsReducer.surveyTitle);
    const numberOfOptions = useSelector(state => state.AnnouncementsReducer.numberOfOptions);
    const numberOfOptionsArray = useSelector(state => state.AnnouncementsReducer.numberOfOptionsArray);
    const optionText = useSelector(state => state.AnnouncementsReducer.optionText);
    const startDate = useSelector(state => state.AnnouncementsReducer.startDate);
    const meetUpStartDate = useSelector(state => state.AnnouncementsReducer.meetUpStartDate);
    const meetUpEndDate = useSelector(state => state.AnnouncementsReducer.meetUpEndDate);
    const endDate = useSelector(state => state.AnnouncementsReducer.endDate);
    const meetUpAddress = useSelector(state => state.AnnouncementsReducer.meetUpAddress);
    const announcementLoading = useSelector(state => state.AnnouncementsReducer.announcementLoading);
    const announcementErrorMessage = useSelector(state => state.AnnouncementsReducer.announcementErrorMessage);
    const announcementSuccessMessage = useSelector(state => state.AnnouncementsReducer.announcementSuccessMessage);
    const potentialNumberOfViewsForAnnouncements = useSelector(state => state.AnnouncementsReducer.potentialNumberOfViewsForAnnouncements);
    const announcerBroadcastingArea = useSelector(state => state.UserReducer.announcerBroadcastingArea);
    const fieldError = useSelector(state => state.SettingsReducer.fieldError);
    const firstName = useSelector(state => state.UserReducer.firstName);
    const lastName = useSelector(state => state.UserReducer.lastName);
    const photo = useSelector(state => state.UserReducer.photo);
    const announcerAdditionalDescription = useSelector(state => state.UserReducer.announcerAdditionalDescription);
    const dispatch = useDispatch();

    const date = new Date();

    const announcementsTypes = [
        { label: PremiumPublicationTypes.Communication, value: 'COMMUNICATION' },
        { label: PremiumPublicationTypes.Survey, value: 'SURVEY' },
        { label: PremiumPublicationTypes.MeetUp, value: 'MEET_UP' }
    ];

    const numbersOfOptions = [
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
        { label: '6', value: 6 },
        { label: '7', value: 7 },
        { label: '8', value: 8 },
        { label: '9', value: 9 },
        { label: '10', value: 10 }
    ];

    useEffect(() => {
        dispatch(getPotentialNumberOfViewsForAnnouncements());

        return () => {
            dispatch(setFieldError(false));
            dispatch(announcementsError(''));
            dispatch(clearAnnouncements());
        }
    }, []);

    function getBannerImage(files) {
        const _uploadedFiles = files.map(file => ({
            file,
        }));

        dispatch(asyncSendAnnouncementImage(_uploadedFiles[0]))
    }

    function _announcementButton() {
        if (announcementLoading) {
            return (
                <div className="spinner-container">
                    <Spinner size={16} color='#000' />
                </div>
            );
        }

        return <button className="btn" type="submit">{Actions.Confirm}</button>;
    }

    function _asyncSendAnnouncement(event) {
        event.preventDefault();

        let message = Errors.User.FieldEmpty;

        if (announcementType === 'COMMUNICATION') {
            if (title.length < 1 || content.length < 2 || image.length < 2 || startDate.length < 1 || endDate.length < 1) {
                dispatch(announcementsError(message));
                dispatch(setFieldError(true));
            } else {
                dispatch(setFieldError(false));
                dispatch(asyncSendAnnouncement());
            }
        } else if (announcementType === 'SURVEY') {
            if (title.length < 1 || content.length < 2 || surveyTitle.length < 2 || startDate.length < 1 || endDate.length < 1) {
                dispatch(announcementsError(message));
                dispatch(setFieldError(true));
            } else if (numberOfOptions === 2 && (optionText[0] < 1 || optionText[1] < 1)) {
                dispatch(announcementsError(message));
                dispatch(setFieldError(true));
            } else if (numberOfOptions === 3 && (optionText[0] < 1 || optionText[1] < 1 || optionText[2] < 1)) {
                dispatch(announcementsError(message));
                dispatch(setFieldError(true));
            } else if (numberOfOptions === 4 && (optionText[0] < 1 || optionText[1] < 1 || optionText[2] < 1 || optionText[3] < 1)) {
                dispatch(announcementsError(message));
                dispatch(setFieldError(true));
            } else if (numberOfOptions === 5 && (optionText[0] < 1 || optionText[1] < 1 || optionText[2] < 1 || optionText[3] < 1 || optionText[4] < 1)) {
                dispatch(announcementsError(message));
                dispatch(setFieldError(true));
            } else if (numberOfOptions === 6 && (optionText[0] < 1 || optionText[1] < 1 || optionText[2] < 1 || optionText[3] < 1 || optionText[4] < 1 || optionText[5] < 1)) {
                dispatch(announcementsError(message));
                dispatch(setFieldError(true));
            } else if (numberOfOptions === 7 && (optionText[0] < 1 || optionText[1] < 1 || optionText[2] < 1 || optionText[3] < 1 || optionText[4] < 1 || optionText[5] < 1 || optionText[6] < 1)) {
                dispatch(announcementsError(message));
                dispatch(setFieldError(true));
            } else if (numberOfOptions === 8 && (optionText[0] < 1 || optionText[1] < 1 || optionText[2] < 1 || optionText[3] < 1 || optionText[4] < 1 || optionText[5] < 1 || optionText[6] < 1 || optionText[7] < 1)) {
                dispatch(announcementsError(message));
                dispatch(setFieldError(true));
            } else if (numberOfOptions === 9 && (optionText[0] < 1 || optionText[1] < 1 || optionText[2] < 1 || optionText[3] < 1 || optionText[4] < 1 || optionText[5] < 1 || optionText[6] < 1 || optionText[7] < 1 || optionText[8] < 1)) {
                dispatch(announcementsError(message));
                dispatch(setFieldError(true));
            } else if (numberOfOptions === 10 && (optionText[0] < 1 || optionText[1] < 1 || optionText[2] < 1 || optionText[3] < 1 || optionText[4] < 1 || optionText[5] < 1 || optionText[6] < 1 || optionText[7] < 1 || optionText[8] < 1 || optionText[9] < 1)) {
                dispatch(announcementsError(message));
                dispatch(setFieldError(true));
            } else {
                dispatch(setFieldError(false));
                dispatch(asyncSendAnnouncement());
            }
        } else {
            if (title.length < 1 || content.length < 2 || meetUpAddress.length < 2 || image.length < 2 || startDate.length < 1 || endDate.length < 1 || meetUpStartDate.length < 1 || meetUpEndDate.length < 1) {
                dispatch(announcementsError(message));
                dispatch(setFieldError(true));
            } else {
                dispatch(setFieldError(false));
                dispatch(asyncSendAnnouncement());
            }
        }
    }

    function _typeDescription() {
        if (announcementType === 'COMMUNICATION') {
            return PremiumPublications.Description;
        } else if (announcementType === 'SURVEY') {
            return Surveys.Description;
        } else {
            return MeetUps.Description;
        }
    }

    function dragColor(isDragActive, isDragReject) {
        if (fieldError && image === '') {
            return '#FF0000'
        } else if (isDragReject) {
            return '#FF0000'
        } else if (isDragActive) {
            return '#008000'
        } else {
            return '#DDD'
        }
    }

    function preLoadingImage() {
        if (loadingImage) {
            return <Spinner size={16} color='#000' />;
        } else if (image === '') {
            return <span>{PremiumPublications.Image}</span>;
        } else {
            return <img src={image} />
        }
    }

    const customStylesPublicationTypes = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#999',
            minHeight: '42px',
            height: '42px',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '42px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '42px',
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: '#000'
        }),
    };

    return (
        <div id="announcements-container">
            <Header back='/' screen={PremiumPublications.Create} />
            <div id="form-announcements-container">
                <form onSubmit={_asyncSendAnnouncement}>
                    <div className='announc-header'>
                        <div>
                            <img className='image-header' src={photo} />
                            <img className='image-verified' src={premiumVerifiedIcon} />
                        </div>

                        <div className='announc-header-name'>
                            <span>{firstName.toUpperCase()} {lastName.toUpperCase()}</span>
                            {announcerAdditionalDescription && <span>{announcerAdditionalDescription}</span>}
                        </div>
                    </div>

                    <div className="potentialNumberContainer">
                        <span className="potentialNumberLabel">{PremiumPublications.BroadcastingArea}:</span>&nbsp;
                        <span className="potentialNumberLabel">{announcerBroadcastingArea}</span>
                    </div>

                    <div className="field-container first-select">
                        <Select
                            id="announcementsTypes"
                            styles={customStylesPublicationTypes}
                            placeholder={announcementType === 'COMMUNICATION' ? PremiumPublicationTypes.Communication : announcementType === 'MEET_UP' ? PremiumPublicationTypes.MeetUp : PremiumPublicationTypes.Survey}
                            onChange={key => dispatch(modifyAnnouncementType(key.value))}
                            isOptionSelected={key => key.value === announcementType}
                            options={announcementsTypes}
                            isSearchable={false}
                        />

                        <p>{_typeDescription()}</p>
                    </div>

                    <div className="field-container">
                        <label htmlFor="announcementsTitle">{announcementType === 'MEET_UP' ? MeetUps.MeetUpTitle : PremiumPublications.Title}</label>
                        <input
                            id="announcementsTitle"
                            value={title}
                            style={{ borderColor: fieldError && title === '' ? 'red' : '#DDD' }}
                            onChange={event => dispatch(modifyAnnouncementsTitle(event.target.value))}
                        />
                    </div>

                    <div className="field-container">
                        <label htmlFor="announcementsContent">{PremiumPublications.Text}</label>
                        <textarea
                            id="announcementsContent"
                            value={content}
                            maxLength={500}
                            style={{ borderColor: fieldError && content === '' ? 'red' : '#DDD' }}
                            onChange={event => dispatch(modifyAnnouncementsDesc(event.target.value))}
                        />
                    </div>

                    {announcementType === 'MEET_UP' && <div className="field-container">
                        <label htmlFor="advertisementsLocation">{MeetUps.MeetUpLocation}</label>
                        <input
                            id="advertisementsLocation"
                            style={{ borderColor: fieldError && meetUpAddress === '' ? 'red' : '#DDD' }}
                            value={meetUpAddress}
                            onChange={event => dispatch(modifyMeetUpAddress(event.target.value))}
                        />
                    </div>}

                    {announcementType === 'MEET_UP' && <div className='meet-up-dates-container'>
                        <div className='meet-up-dates-content'>
                            <div className="field-container">
                                <label htmlFor="advertisementsStartDate">{MeetUps.MeetUpStartDate}</label>
                                <input
                                    id="meetUpStartDate"
                                    type="datetime-local"
                                    value={meetUpStartDate}
                                    min={dt.format(date, 'YYYY-MM-DDTHH:mm')}
                                    style={{ borderColor: fieldError && meetUpStartDate === '' ? 'red' : '#DDD' }}
                                    onChange={event => dispatch(setMeetUpStartDate(event.target.value))}
                                />
                            </div>
                        </div>

                        <div className='meet-up-dates-content'>
                            <div className="field-container">
                                <label htmlFor="advertisementsStartDate">{MeetUps.MeetUpEndDate}</label>
                                <input
                                    id="meetUpEndDate"
                                    type="datetime-local"
                                    value={meetUpEndDate}
                                    min={meetUpStartDate === '' ? null : dt.format(new Date(meetUpStartDate), 'YYYY-MM-DDTHH:mm')}
                                    style={{ borderColor: fieldError && meetUpEndDate === '' ? 'red' : '#DDD' }}
                                    onChange={event => dispatch(setMeetUpEndDate(event.target.value))}
                                />
                            </div>
                        </div>
                    </div>}

                    {announcementType !== 'SURVEY' && <div className="field-container">
                        <Dropzone accept='image/*' onDropAccepted={getBannerImage}>
                            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                <div
                                    {...getRootProps()}
                                    style={{ borderColor: dragColor(isDragActive, isDragReject) }}
                                    className={image === '' ? "dropzone" : "dropzone activited"}>
                                    <input {...getInputProps()} />
                                    {preLoadingImage()}
                                </div>
                            )}
                        </Dropzone>
                    </div>}

                    {announcementType === 'SURVEY' && <>
                        <div className="field-container">
                            <label htmlFor="announcementsSurveyTitle">{Surveys.SurveyTitle}</label>
                            <input
                                id="announcementsSurveyTitle"
                                value={surveyTitle}
                                style={{ borderColor: fieldError && surveyTitle === '' ? 'red' : '#DDD' }}
                                onChange={event => dispatch(modifySurveyTitle(event.target.value))}
                            />
                        </div>

                        <div className="field-container">
                            <label htmlFor="surveyNumbersOfOptions">{Surveys.NumberOfOptions}</label>
                            <Select
                                id="surveyNumbersOfOptions"
                                styles={customStylesPublicationTypes}
                                placeholder={numbersOfOptions.filter(item => item.value === numberOfOptions)[0].label}
                                onChange={key => dispatch(modifyNumberOfOptions(key.value))}
                                isOptionSelected={key => key.value === numberOfOptions}
                                options={numbersOfOptions}
                                isSearchable={false}
                            />
                        </div>

                        {numberOfOptionsArray.map((item, index) => {
                            const order = index + 1;
                            return (
                                <div key={index} className="field-container">
                                    <label htmlFor="announcementsSurveyTitle">{Surveys.OptionText + ' ' + order}</label>
                                    <input
                                        id="announcementsSurveyTitle"
                                        value={optionText[index]}
                                        style={{ borderColor: fieldError && optionText[index] === '' ? 'red' : '#DDD' }}
                                        onChange={event => {
                                            const newOptionText = [...optionText];
                                            newOptionText[index] = event.target.value;
                                            dispatch(modifyOptionText(newOptionText));
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </>}

                    <div className='meet-up-dates-container'>
                        <div className='meet-up-dates-content'>
                            <div className="field-container">
                                <label htmlFor="announcementsStartDate">{PremiumPublications.StartDate}</label>
                                <input
                                    id="announcementsStartDate"
                                    type="datetime-local"
                                    value={startDate}
                                    min={dt.format(date, 'YYYY-MM-DDTHH:mm')}
                                    style={{ borderColor: fieldError && startDate === '' ? 'red' : '#DDD' }}
                                    onChange={event => dispatch(setStartDate(event.target.value))}
                                />
                            </div>
                        </div>

                        <div className='meet-up-dates-content'>
                            <div className="field-container">
                                <label htmlFor="announcementsEndDate">{PremiumPublications.EndDate}</label>
                                <input
                                    id="announcementsEndDate"
                                    type="datetime-local"
                                    value={endDate}
                                    min={startDate === '' ? null : dt.format(new Date(startDate), 'YYYY-MM-DDTHH:mm')}
                                    style={{ borderColor: fieldError && endDate === '' ? 'red' : '#DDD' }}
                                    onChange={event => dispatch(setEndDate(event.target.value))}
                                />
                            </div>
                        </div>
                    </div>

                    {potentialNumberOfViewsForAnnouncements !== 0 && <div className="potentialNumberContainer">
                        <span className="potentialNumberLabel">{PremiumPublications.PotentialNumberOfViews}:</span>&nbsp;
                        <span className="potentialNumberLabel">{potentialNumberOfViewsForAnnouncements}</span>
                    </div>}

                    <span className="errorMessage">{announcementErrorMessage}</span>
                    <span className="successMessage">{announcementSuccessMessage}</span>

                    {_announcementButton()}
                </form>
            </div>
        </div>
    )
}