const INITIAL_STATE = {
    WelcomeScreen: {
        HaveAccount: "",
        NoAccount: "",
        SignUp: "",
        Login: "",
        ForgotPassword: "",
        ResetPassword: ""
    },
    SubscriptionAndAccount: {
        Email: "",
        Password: "",
        FirstName: "",
        LastName: "",
        OrganizationName: "",
        BirthDate: "",
        Gender: "",
        Man: "",
        Woman: "",
        Address: "",
        ZipCode: "",
        City: "",
        Region: "",
        Country: "",
        Phone: "",
        Photo: "",
        IDCard: "",
        Verified: "",
        ObtainVerifiedStatus: "",
        Bio: "",
        Language: "",
        OrgStatus: "",
        Yes: "",
        No: "",
        PhotoUpload: "",
        EditProfile: "",
        UpdateAccount: "",
        UpdateSuccessful: "",
        CharsLeft: "",
        OrganizationBio: "",
        Sent: "",
        NotSent: "",
        Send: "",
        Modify: "",
        ManageAddress: "",
        SuggestAddressMessage: "",
        SuggestAddress: "",
    },
    Message: {
        RegPhoto: "",
        OrgPhoto: "",
        AddressEssential: "",
        AboutVerifiedStatus: "",
        ForgotPasswordMessage: ""
    },
    Subscription: {
        Welcome: "",
        SubscriptionTitle: "",
        UnsubscribeTitle: "",
        AFewRules: "",
        Rule1: "",
        Rule2: "",
        Rule3: "",
        Rule4: "",
        Rule5: "",
        StartUsingService: "",
        AccountNotConfirmed: "",
        AccountConfirmed: "",
        AccountVerified: "",
        PhotoNotValid: "",
        EmailSent: "",
        FarewellDetails: "",
        DeleteAccount: "",
        DeleteAccountConfirm: "",
        DeleteAccountConfirmText: ""
    },
    ChangePasswordOrEmail: {
        ChangePassword: "",
        CurrentPassword: "",
        NewPassword: "",
        ConfirmPassword: "",
        ConfirmNewPassword: "",
        NewPasswordSuccess: "",
        ChangeEmail: "",
        NewEmailSuccess: ""
    },
    Terms: {
        TermsText: "",
        PrivacyPolicy: "",
        AgreeToTerms: "",
        TermsLanguage: ""
    },
    Menu: {
        Assistance: "",
        MyActivities: "",
        MyRequests: "",
        MyGoodDeeds: "",
        Settings: ""
    },
    AreaContent: {
        AroundMeTitle: "",
        DearUser: "",
        AroundMeExplanation1: "",
        AroundMeExplanation2: "",
        AroundMeExplanation3: "",
        Filter: "",
        OfferAssistance: "",
        OfferSent: "",
        OfferAccepted: "",
        CancelOffer: "",
        NoRequest: "",
        Assistances: "",
        OfferAssistanceError: "",
        SecondsAgo: "",
        SecondAgo: "",
        MinutesAgo: "",
        MinuteAgo: "",
        HoursAgo: "",
        HourAgo: ""
    },
    Request: {
        CreateRequest: "",
        NewRequest: "",
        CancelRequest: "",
        RequestTitle: "",
        RequestDesc: "",
        DateAndTime: "",
        PostSuccessful: "",
        Message: "",
        DurationOfPublication: "",
        OneDayDuration: "",
        ThreeDaysDuration: "",
        OneWeekDuration: "",
        TwoWeeksDuration: "",
        DurationOfPublicationExplanation: "",
        FollowUpMessage: "",
        Image: ""
    },
    Assistance: {
        AssistanceRequestedSectionTitle: "",
        AssistanceOfferedSectionTitle: "",
        AcceptOffer: "",
        DeclineOffer: "",
        JoinChat: "",
        Chat: "",
        ChatPlaceholder: "",
        Participants: "",
        RemoveParticipant: "",
        Answer: "",
        Answers: "",
        NoAnswer: "",
        NoAssistance: "",
        ConcludeAssistance: "",
        OffersReceived: "",
        AwaitingResponse: "",
        AssistanceActive: "",
        NumberOfAssistanceProvided: "",
        Message: {
            TimeOut: "",
            TimeOutApproaching: "",
            StartTime: "",
            Date: "",
            Hour: ""
        }
    },
    Settings: {
        MyAccount: "",
        StandardPublications: "",
        PremiumPublications: "",
        InviteFriends: "",
        ContactUs: "",
        About: "",
        SignOut: ""
    },
    MyRequests: {
        NoRequests: ""
    },
    MyGoodDeeds: {
        NoGoodDeeds: ""
    },
    StandardPublications: {
        Description: "",
        Create: "",
        MyStandardPublications: "",
        Title: "",
        Reference: "",
        Text: "",
        Image: "",
        Link: "",
        StartDate: "",
        EndDate: "",
        EarlierEndDate: "",
        AmountDue: "",
        CalculatePrice: "",
        AdvertPrice: "",
        Payment: "",
        NumberOfDays: "",
        PotentialNumberOfViews: "",
        SelectCountry: "",
        FeatureAvailable: "",
        FeatureUnavailable: "",
        Active: "",
        Older: "",
        PendingValidation: "",
        ValidatedNotPaid: "",
        ValidatedPaid: "",
        Validated: "",
        NotValidated: "",
        Terminated: "",
        TerminatedByUser: "",
        AwaitingBroadcast: "",
        Broadcasting: "",
        Concluded: "",
        AdvertCosts: "",
        TargetedZipCode: "",
        TargetedCity: "",
        CreationSuccessful: "",
        SectionTitle: "",
        SectionInfo: "",
        None: "",
        About: "",
        Location: "",
        ValidateData: "",
        EndBroadcastingConfirm: "",
        BroadcastingOver: "",
        Message: {
            Explanation1: "",
            Explanation2: "",
            Explanation3: "",
            Explanation4: "",
            Explanation5: "",
            ChoseLinkOrText: "",
            TitleForReference: ""
        }
    },
    PremiumPublications: {
        Description: "",
        Create: "",
        MyPremiumPublications: "",
        Title: "",
        Text: "",
        Image: "",
        PotentialNumberOfViews: "",
        StartDate: "",
        EndDate: "",
        EarlierEndDate: "",
        Active: "",
        Older: "",
        None: "",
        CreationSuccessful: "",
        BroadcastingArea: "",
        Terminated: "",
        TerminatedByUser: "",
        AwaitingBroadcast: "",
        Broadcasting: "",
        Concluded: "",
        EndBroadcastingConfirm: "",
        BroadcastingOver: "",
        Message: {
            Explanation: ""
        }
    },
    MeetUps: {
        Description: "",
        Description2: "",
        MeetUpTitle: "",
        MeetUpStartDate: "",
        MeetUpEndDate: "",
        MeetUpLocation: "",
        Attend: "",
        MaybeAttend: "",
        Attendees: "",
        MaybeAttendees: "",
        NumberOfAttendees: "",
        NumberOfMaybeAttendees: "",
        CancelMeetUp: "",
        MeetUpCanceled: "",
        AddressManagementMessage: ""
    },
    Surveys: {
        Description: "",
        SurveyTitle: "",
        NumberOfOptions: "",
        OptionNumber: "",
        OptionText: "",
        Vote: "",
        AlreadyVoted: "",
        TotalNumberOfVotes: "",
        NumberOfVotesForOption: "",
        PercentageOfVotesForOption: "",
        Results: "",
        SeeResults: ""
    },
    ClassifiedAds: {
        Description: "",
        Description2: "",
        ClassifiedAdTitle: "",
        PublishAsSoonAsPossible: "",
        PublishAsSoonAsPossibleExplanation: "",
        AddressManagementMessage: ""
    },
    StandardPublicationTypes: {
        ClassifiedAd: "",
        PromotionalAd: "",
        MeetUp: ""
    },
    PremiumPublicationTypes: {
        Communication: "",
        Survey: "",
        MeetUp: ""
    },
    Activities: {
        AllActivities: "",
        Requests: "",
        Offers: "",
        OtherActivities: "",
        NoActivity: "",
        Types: {
            Request: "",
            Offer: ""
        }
    },
    InviteFriends: {
        InviteSuccessful: "",
        SendEmailInvitation: "",
        EnterEmails: ""
    },
    ContactUs: {
        MessageToTeam: "",
        Topic: "",
        LanguageLimitations: "",
        MessageSuccessful: ""
    },
    Complaints: {
        ComplaintTitle: "",
        ComplaintText: "",
        Report: "",
        ReportUser: "",
        ReportRequest: "",
        ReportPublication: "",
        ReportConfirmText: ""
    },
    AboutUs: {
        Presentation: ""
    },
    Errors: {
        UnknownError: "",
        ServiceUnavailable: "",
        NoInternet: "",
        DisabledUser: "",
        AccessUserGallery: "",
        LocationDataUnreachable: "",
        ImageTooBig: "",
        User: {
            FieldEmpty: "",
            OptionalField: "",
            TextTooLong: "",
            PhotoMissing: "",
            FirstNameEmpty: "",
            EmailTaken: "",
            EmailInvalid: "",
            PasswordNoMatch: "",
            PasswordFormat: "",
            IncorrectLogin: "",
            PhotoAbsent: "",
            InvalidData: ""
        }
    },
    Actions: {
        Confirm: "",
        Cancel: "",
        Previous: "",
        Next: "",
        Save: "",
        KnowMore: "",
        Ok: "",
        Acknowledge: "",
        SeeMore: "",
        SeeLess: "",
        MoreDetails: "",
        StopBroadcasting: "",
        SeeFullText: ""
    },
    PublicationRecommendations: {}
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_TEXTS':
            return {
                ...state,
                WelcomeScreen: action.payload.WelcomeScreen,
                SubscriptionAndAccount: action.payload.SubscriptionAndAccount,
                Message: action.payload.SubscriptionAndAccount.Message,
                Subscription: action.payload.SubscriptionAndAccount.Subscription,
                ChangePasswordOrEmail: action.payload.SubscriptionAndAccount.ChangePasswordOrEmail,
                Terms: action.payload.SubscriptionAndAccount.Terms,
                Menu: action.payload.Menu,
                AreaContent: action.payload.AreaContent,
                Request: action.payload.Request,
                Assistance: action.payload.Assistance,
                Settings: action.payload.Settings,
                MyRequests: action.payload.MyRequests,
                MyGoodDeeds: action.payload.MyGoodDeeds,
                StandardPublications: action.payload.StandardPublications,
                PremiumPublications: action.payload.PremiumPublications,
                MeetUps: action.payload.MeetUps,
                Surveys: action.payload.Surveys,
                ClassifiedAds: action.payload.ClassifiedAds,
                StandardPublicationTypes: action.payload.StandardPublicationTypes,
                PremiumPublicationTypes: action.payload.PremiumPublicationTypes,
                Activities: action.payload.Activities,
                InviteFriends: action.payload.InviteFriends,
                ContactUs: action.payload.ContactUs,
                Complaints: action.payload.Complaints,
                AboutUs: action.payload.AboutUs,
                Errors: action.payload.Errors,
                Notifications: action.payload.Notifications,
                Actions: action.payload.Actions,
                PublicationRecommendations: action.payload.PublicationRecommendations
            }
        default:
            return state;
    }
}