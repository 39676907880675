import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Header from '../../../components/header';
import logo from '../../../../assets/assistLogo.png';
import androidStore from '../../../../assets/androidStore.png';
import iosStore from '../../../../assets/iosStore.png';
import './styles.css';

export default function About() {
    const Site = useSelector(state => state.TextsReducer.Site);
    const AboutUs = useSelector(state => state.TextsReducer.AboutUs);
    const Settings = useSelector(state => state.TextsReducer.Settings);

    return (
        <>
            <Header back='/login' screen={Settings.About} />
            <div id="about-container">
                <img className="logo" src={logo} alt="logo" />

                <div className="presentation-container">
                    <p>{AboutUs.Presentation}</p>

                    <div className="mobile-stores">
                        <a href="https://play.google.com/store/apps/details?id=com.assist.assistapp" target="_blank">
                            <img src={androidStore} alt="logo" />
                            {Site.AppInstallation.InstallOnAndroid}
                        </a>

                        <a href="https://apps.apple.com/us/app/assist-mutual-aid/id1560864734" target="_blank">
                            <img src={iosStore} alt="logo" />
                            {Site.AppInstallation.InstallOnIOS}
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}