import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import { isAuthenticated } from './services/routesAuth';
import Login from './screens/pages/welcome/login';
import Requests from './screens/pages/main/requests';
import Home from './screens/pages/welcome/home';
import MyActivities from './screens/pages/activities';
import MyAdvertisements from './screens/pages/advertisements/myAdvertisements';
import AllAdvertisements from './screens/pages/advertisements/allAdvertisements';
import MeetUpParticipants from './screens/components/meetUpParticipants';
import MyAnnouncements from './screens/pages/announcements/myAnnouncements';
import AllAnnouncements from './screens/pages/announcements/allAnnouncements';
import TermsOfUse from './screens/pages/welcome/termsOfUse';

import Announcements from './screens/pages/settings/announcements';
import NewAdvertisements from './screens/pages/settings/advertisements/newAdvertisements';
import AboutAdvertisements from './screens/pages/settings/advertisements/aboutAdvertisements';
import AdvertCosts from './screens/pages/settings/advertisements/advertsCosts';
import About from './screens/pages/settings/about';
import ReadTerms from './screens/pages/settings/readTerms';
import ContactUsSite from './screens/pages/settings/contactUsSite';

function PrivateRoute({ children, ...rest }) {
    return (
        <Route {...rest} render={({ location }) =>
            isAuthenticated() ? (
                children
            ) : (
                <Redirect to={{ pathname: '/login', state: { from: location } }} />
            )
        } />
    );
}

function Routes() {
    return (
        <Router basename="/">
            <Switch>
                <Route path="/login">
                    <Login />
                </Route>
                <PrivateRoute exact path='/myActivities'>
                    <MyActivities />
                </PrivateRoute>
                <PrivateRoute exact path='/myPublications'>
                    <MyAdvertisements />
                </PrivateRoute>
                <PrivateRoute exact path='/fullPublication'>
                    <AllAdvertisements />
                </PrivateRoute>
                <PrivateRoute exact path='/meetUpParticipants'>
                    <MeetUpParticipants />
                </PrivateRoute>
                <PrivateRoute exact path='/myPremiumPublications'>
                    <MyAnnouncements />
                </PrivateRoute>
                <PrivateRoute exact path='/fullPremiumPublication'>
                    <AllAnnouncements />
                </PrivateRoute>
                <PrivateRoute exact path='/'>
                    <MyActivities />
                </PrivateRoute>
                <PrivateRoute path='/newPublication'>
                    <NewAdvertisements />
                </PrivateRoute>
                <PrivateRoute path='/newPremiumPublication'>
                    <Announcements />
                </PrivateRoute>
                <PrivateRoute path='/aboutPublications'>
                    <AboutAdvertisements />
                </PrivateRoute>
                <PrivateRoute path='/advertCosts'>
                    <AdvertCosts />
                </PrivateRoute>
                <Route path='/aboutUs'>
                    <About />
                </Route>
                <Route path='/readTerms/:id'>
                    <ReadTerms />
                </Route>
                <Route path='/contactUs'>
                    <ContactUsSite />
                </Route>
                <Route path='/termsOfUse'>
                    <TermsOfUse />
                </Route>
            </Switch>
        </Router>
    );
}

export default Routes;