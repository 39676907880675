import React from 'react';
import ProgressBar from "@ramonak/react-progress-bar";
import { BsCheckLg } from "react-icons/bs";
import { FaAngleDoubleRight } from "react-icons/fa";
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom";

import { openEndAnnouncementsModal } from '../../../../redux/actions/announcementsActions';

import Header from '../../../components/header';
import EndAdsOrAnnouncModal from '../../../modals/endAdsOrAnnouncModal';

import premiumVerifiedIcon from '../../../../assets/premiumVerified.png';
import './styles.css';

export default function AllAnnouncement() {
    const PremiumPublications = useSelector(state => state.TextsReducer.PremiumPublications);
    const MeetUps = useSelector(state => state.TextsReducer.MeetUps);
    const Surveys = useSelector(state => state.TextsReducer.Surveys);
    const Actions = useSelector(state => state.TextsReducer.Actions);
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const item = location.state;

    let status;

    if (item.creatorExtraData.status === 'AWAITING_BROADCAST') {
        status = PremiumPublications.AwaitingBroadcast;
    } else if (item.creatorExtraData.status === 'BROADCASTING') {
        status = PremiumPublications.Broadcasting;
    } else if (item.creatorExtraData.status === 'CONCLUDED') {
        status = PremiumPublications.Concluded;
    } else if (item.creatorExtraData.status === 'TERMINATED_BY_USER' || item.creatorExtraData.status === 'SHORTENED_FOR_OVERLAP') {
        status = PremiumPublications.TerminatedByUser;
    } else if (item.creatorExtraData.status === 'TERMINATED') {
        status = PremiumPublications.Terminated;
    } else {
        status = '';
    }

    console.log('status', item.creatorExtraData.status)

    function _renderLocations() {
        if (item.creatorExtraData.coverageType === 'LOCALITY_NAME' || item.creatorExtraData.coverageType === 'POSTAL_CODE') {
            return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {item.creatorExtraData.locations.map((location, index) => {
                        if (item.creatorExtraData.coverageType === 'LOCALITY_NAME') {
                            if (index === item.creatorExtraData.locations.length - 1) {
                                return <p key={location.locality}>{location.locality}</p>
                            } else {
                                return <p key={location.locality}>{location.locality},&nbsp;</p>
                            }
                        } else {
                            if (index === item.creatorExtraData.locations.length - 1) {
                                return <p key={location.locality}>{location.zipCode}</p>
                            } else {
                                return <p key={location.locality}>{location.zipCode},&nbsp;</p>
                            }
                        }
                    })}
                </div>
            );
        }
    }

    function _renderContent() {
        if (item.type === 'MEET_UP') {
            return (
                <>
                    <div id="myAnnouncementsItem-content">
                        {item.creatorExtraData.status !== '' && <p className='status-text' style={{ color: item.creatorExtraData.status === 'TERMINATED' ? 'red' : '#000' }}>{status}</p>}
                        <EndAdsOrAnnouncModal itemID={item.id} screen='Anouncements' />
                        <div className='announc-header'>
                            <div>
                                <img className='image-header' src={item.announcerPhoto} />
                                <img className='image-verified' src={premiumVerifiedIcon} />
                            </div>

                            <div className='announc-header-name'>
                                <span>{item.announcerFirstName?.toUpperCase()} {item.announcerLastName?.toUpperCase()}</span>
                                {item.announcerAdditionalDescription && <span>{item.announcerAdditionalDescription}</span>}
                            </div>
                        </div>

                        <div className="header-announc-item">
                            <div className="announcTitleContainer">
                                <p className='publication-name'>{item.title}</p>
                            </div>
                        </div>

                        <div className="announcTextContainer">
                            <p>{item.text}</p>
                        </div>

                        <div className="imageContainer">
                            <img src={item.image} className="announcImage" />
                        </div>

                        <div className="locations">
                            {_renderLocations()}
                            <div style={{ display: 'flex', marginTop: 3 }}>
                                <p>{item.creatorExtraData.locations[0].regionOrState}&nbsp;</p>
                                <p>{item.creatorExtraData.locations[0].country}</p>
                            </div>
                        </div>

                        <div className="footer">
                            <div className='footer-item'>
                                <p>{MeetUps.MeetUpLocation}: {item.meetUpData.meetUpAddress}</p>
                            </div>

                            <div className='footer-item'>
                                <p>{MeetUps.MeetUpStartDate}: {item.meetUpData.startDateFormatted}</p>
                            </div>

                            <div className='footer-item'>
                                <p>{MeetUps.MeetUpEndDate}: {item.meetUpData.endDateFormatted}</p>
                            </div>

                            <div className='footer-item publication-date'>
                                <p>{PremiumPublications.StartDate}: {item.creatorExtraData.startDateFormatted}</p>
                            </div>

                            {item.creatorExtraData.endDate !== null && <div className='footer-item'>
                                <p>{PremiumPublications.EndDate}: {item.creatorExtraData.endDateFormatted}</p>
                            </div>}

                            {item.creatorExtraData.earlierEndDate !== null && <div className='footer-item'>
                                <p>{PremiumPublications.EarlierEndDate}: {item.creatorExtraData.earlierEndDateFormatted}</p>
                            </div>}

                            <div className='footer-item'>
                                <p>{PremiumPublications.PotentialNumberOfViews}: {item.creatorExtraData.potentialNumberOfViews}</p>
                            </div>

                            <div className='meet-up-container'>
                                <div className='meet-up-attendees-container'>
                                    <div>
                                        <p>{MeetUps.Attendees}: {item.meetUpData.numberOfConfirmedAttendances}</p>
                                    </div>

                                    <div>
                                        <p>{MeetUps.MaybeAttendees}: {item.meetUpData.numberOfPossibleAttendances}</p>
                                    </div>
                                </div>

                                <button className='button-attendees' onClick={() => history.push({ pathname: '/meetUpParticipants', state: { item: item, screen: 'Announcements' } })}>
                                    <p style={{ fontWeight: 'bold', marginRight: 5, marginBottom: 2 }}>{MeetUps.Attendees}</p>
                                    <FaAngleDoubleRight size={22} color="#000" />
                                </button>
                            </div>
                        </div>
                    </div>

                    {item.creatorExtraData.status === 'BROADCASTING' && <button className='button-cancel-publication' onClick={() => dispatch(openEndAnnouncementsModal(item.id))}>
                        {item.type === 'MEET_UP' ? MeetUps.CancelMeetUp : Actions.StopBroadcasting}
                    </button>}
                </>
            )
        } else if (item.type === 'SURVEY') {
            let totalNumbersOfVotes = 0;

            item.surveyData.options.forEach(item => {
                totalNumbersOfVotes = totalNumbersOfVotes + item.numberOfVotes;
            });

            return (
                <>
                    <div id="myAnnouncementsItem-content">
                        {item.creatorExtraData.status !== '' && <p className='status-text' style={{ color: item.creatorExtraData.status === 'TERMINATED' ? 'red' : '#000' }}>{status}</p>}
                        <EndAdsOrAnnouncModal itemID={item.id} screen='Anouncements' />
                        <div className='announc-header'>
                            <div>
                                <img className='image-header' src={item.announcerPhoto} />
                                <img className='image-verified' src={premiumVerifiedIcon} />
                            </div>

                            <div className='announc-header-name'>
                                <span>{item.announcerFirstName?.toUpperCase()} {item.announcerLastName?.toUpperCase()}</span>
                                {item.announcerAdditionalDescription && <span>{item.announcerAdditionalDescription}</span>}
                            </div>
                        </div>

                        <div className="header-announc-item">
                            <div className="announcTitleContainer">
                                <p className='publication-name'>{item.title}</p>
                            </div>
                        </div>

                        <div className="announcTextContainer">
                            <p>{item.text}</p>
                        </div>

                        <p className='survey-question-title'>{item.surveyData.question}</p>

                        <div className='survey-data-container'>
                            {item.surveyData.options.map(option => {
                                return (
                                    <div key={option.id} className='survey-data-option'>
                                        <div className='survey-data-check'>
                                            {item.surveyData.userSelectedOption && item.surveyData.userSelectedOption.id === option.id ? <BsCheckLg size={16} color="#0074a6" /> : null}
                                        </div>

                                        <div className='survey-bar-container'>
                                            <span className='option-text'>{option.text.trim()}</span>
                                            <ProgressBar
                                                customLabel={Surveys.NumberOfVotesForOption + ': ' + option.numberOfVotes}
                                                completed={totalNumbersOfVotes === 0 ? 0 : option.numberOfVotes / totalNumbersOfVotes * 100}
                                                labelAlignment='center'
                                                bgColor='#0091D0'
                                                className="wrapper"
                                                barContainerClassName="container"
                                                labelClassName="label"
                                            />
                                            <span className='percentage-text'>{totalNumbersOfVotes === 0 ? 0 : option.numberOfVotes / totalNumbersOfVotes * 100}%</span>
                                        </div>
                                    </div>
                                );
                            })}
                            <p style={{ marginTop: 10, fontStyle: 'italic', fontSize: 12 }}>{Surveys.TotalNumberOfVotes}: {totalNumbersOfVotes}</p>
                        </div>

                        <div className="locations">
                            {_renderLocations()}
                            <div style={{ display: 'flex', marginTop: 3 }}>
                                <p>{item.creatorExtraData.locations[0].regionOrState}&nbsp;</p>
                                <p>{item.creatorExtraData.locations[0].country}</p>
                            </div>
                        </div>

                        <div className="footer">
                            <div className='footer-item'>
                                <p>{PremiumPublications.StartDate}: {item.creatorExtraData.startDateFormatted}</p>
                            </div>

                            {item.creatorExtraData.endDate !== null && <div className='footer-item'>
                                <p>{PremiumPublications.EndDate}: {item.creatorExtraData.endDateFormatted}</p>
                            </div>}

                            {item.creatorExtraData.earlierEndDate !== null && <div className='footer-item'>
                                <p>{PremiumPublications.EarlierEndDate}: {item.creatorExtraData.earlierEndDateFormatted}</p>
                            </div>}

                            <div className='footer-item'>
                                <p>{PremiumPublications.PotentialNumberOfViews}: {item.creatorExtraData.potentialNumberOfViews}</p>
                            </div>
                        </div>
                    </div>

                    {item.creatorExtraData.status === 'BROADCASTING' && <button className='button-cancel-publication' onClick={() => dispatch(openEndAnnouncementsModal(item.id))}>
                        {item.type === 'MEET_UP' ? MeetUps.CancelMeetUp : Actions.StopBroadcasting}
                    </button>}
                </>
            )
        }

        return (
            <>
                <div id="myAnnouncementsItem-content">
                    {item.creatorExtraData.status !== '' && <p className='status-text' style={{ color: item.creatorExtraData.status === 'TERMINATED' ? 'red' : '#000' }}>{status}</p>}
                    <EndAdsOrAnnouncModal itemID={item.id} screen='Anouncements' />
                    <div className='announc-header'>
                        <div>
                            <img className='image-header' src={item.announcerPhoto} />
                            <img className='image-verified' src={premiumVerifiedIcon} />
                        </div>

                        <div className='announc-header-name'>
                            <span>{item.announcerFirstName?.toUpperCase()} {item.announcerLastName?.toUpperCase()}</span>
                            {item.announcerAdditionalDescription && <span>{item.announcerAdditionalDescription}</span>}
                        </div>
                    </div>

                    <div className="header-announc-item">
                        <div className="announcTitleContainer">
                            <p className='publication-name'>{item.title}</p>
                        </div>
                    </div>

                    <div className="announcTextContainer">
                        <p>{item.text}</p>
                    </div>

                    <div className="imageContainer">
                        <img src={item.image} className="announcImage" />
                    </div>

                    <div className="locations">
                        {_renderLocations()}
                        <div style={{ display: 'flex', marginTop: 3 }}>
                            <p>{item.creatorExtraData.locations[0].regionOrState}&nbsp;</p>
                            <p>{item.creatorExtraData.locations[0].country}</p>
                        </div>
                    </div>

                    <div className="footer">
                        <div className='footer-item'>
                            <p>{PremiumPublications.StartDate}: {item.creatorExtraData.startDateFormatted}</p>
                        </div>

                        {item.creatorExtraData.endDate !== null && <div className='footer-item'>
                            <p>{PremiumPublications.EndDate}: {item.creatorExtraData.endDateFormatted}</p>
                        </div>}

                        {item.creatorExtraData.earlierEndDate !== null && <div className='footer-item'>
                            <p>{PremiumPublications.EarlierEndDate}: {item.creatorExtraData.earlierEndDateFormatted}</p>
                        </div>}

                        <div className='footer-item'>
                            <p>{PremiumPublications.PotentialNumberOfViews}: {item.creatorExtraData.potentialNumberOfViews}</p>
                        </div>
                    </div>
                </div>

                {item.creatorExtraData.status === 'BROADCASTING' && <button className='button-cancel-publication' onClick={() => dispatch(openEndAnnouncementsModal(item.id))}>
                    {item.type === 'MEET_UP' ? MeetUps.CancelMeetUp : Actions.StopBroadcasting}
                </button>}
            </>
        );
    }

    return (
        <div id="allAnnouncements-container">
            <Header back='/myPremiumPublications' screen={PremiumPublications.MyPremiumPublications} />
            {_renderContent()}
        </div>
    );
}