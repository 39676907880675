const INITIAL_STATE = {
    reloadingGetOldRequests: false,
    reloadingGetGoodDeeds: false,
    oldRequests: [],
    goodDeeds: []
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case 'GET_OLD_REQUESTS':
            return { ...state, reloadingGetOldRequests: action.payload }
        case 'SET_OLD_REQUESTS':
            return { ...state, oldRequests: action.payload.oldRequests, reloadingGetOldRequests: action.payload.reload }
        case 'GET_GOOD_DEEDS':
            return { ...state, reloadingGetGoodDeeds: action.payload }
        case 'SET_GOOD_DEEDS':
            return { ...state, goodDeeds: action.payload.goodDeeds, reloadingGetGoodDeeds: action.payload.reload }
        default:
            return state;
    }
}